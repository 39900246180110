.control-viewer {
    display: flex;
    gap: 15px;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
}

.diff-container {
    width: 100%;
    margin-top: var(--gap-20-px);
}

.diff-container h3 {
    color: #2c4874;
    font-size: var(--gap-20-px);
    margin: var(--gap-20-px);
    padding-bottom: var(--gap-10-px);
}

.diff-container>.diff-group~.diff-group {
    margin-bottom: var(--gap-20-px);
    font-size: var(--global-body-20-font-size);
}

.diff-container td[class$='-title-block'] pre {
    color: #2c4874;
    font-weight: 600;
}

.diff-container td[class*='-content'] {
    width: 45% !important;
}

.diffViewer {
    margin-top: var(--gap-20-px);
}

.diff-section {
    display: flex;
    align-items: center;
    font-size: var(--global-body-14-font-size);
    ;
    gap: 10px;
    border-bottom: 1px solid #ececec;
    min-height: var(--gap-30-px);
}

.diff-section p {
    width: 35%;
    font-weight: 600;
    line-height: var(--line-height);
}

.diff-group {
    border: 1px solid #f1f1f1;
    padding: 2rem;
    border-radius: 10px;
    background: #fff;
    margin-bottom: var(--gap-20-px)
}

.diff-group>.diff-group {
    border: none;
    padding: 0 var(--gap-20-px);
}

.diff-group>.diff-group>.diff-group {
    border: 1px solid #f1f1f1;
    margin: var(--gap-20-px) -var(--gap-20-px);
    padding-top: var(--gap-20-px);
    padding-bottom: var(--gap-20-px);
    background: #fafafa;
}

.diff-group h2 {
    color: #2c4874;
    font-size: var(--global-body-20-font-size);
    padding-bottom: var(--gap-10-px);
    margin-bottom: var(--gap-10-px);
}

.diff-group>.diff-group>h2 {
    border-top: 1px solid #e2e2e2;
    padding-top: var(--gap-20-px);
    margin-left: -var(--gap-10-px);
    margin-right: -var(--gap-10-px);
}

.diff-group>.diff-group>.diff-group>h2 {
    border-top: none;
    padding-top: inherit;
    margin-left: 0;
    margin-right: 0;
}

.diff-group>.diff-section:nth-of-type(1) {
    align-items: flex-end;
    padding-bottom: var(--gap-5-px);
    min-height: var(--gap-20-px);
}