.filter-button-group {
    @include flex(row, flex-end, center, nowrap);

    .button {
        border-radius: 0;
        border: 1px solid var(--header-background-color);
    }

    @media (max-width: $mobile-width) {
        @include flex(column, flex-end, center, nowrap);
    }
}