.footer {
  @include flex(row, flex-start, center, wrap);
  width: 100%;
  height: fit-content;
  height: -moz-fit-content;
  background-color: #FFFFFF;
  @include boxSizing(border-box);
  border-top: 1px solid map-get($colour-palette, open-footer-border);

  #footerLogo {
    padding: var(--gap-10-px) var(--gap-40-px) var(--gap-10-px) var(--gap-30-px);
    cursor: pointer;
    @include boxSizing(border-box);

    img {
      height: 65px;
    }

    @media (max-width: $tablet-width) {
      padding-left: var(--gap-20-px);
      padding-right: 0;

      img {
        height: 60px;
      }
    }

    @media (max-width: $mobile-width) {
      img {
        height: 55px;
      }
    }
  }

  #footerLinks {
    @include flex(row, flex-start, center, wrap);
    flex: 1;
    height: auto;
    min-width: 550px;
    @include boxSizing(border-box);

    .footerLink {
      padding: var(--gap-10-px) var(--gap-40-px) var(--gap-10-px) 0;
      font-family: var(--font-family);
      color: map-get($colour-palette, open-text-footer);
    }

    @media (max-width: $tablet-width) {
      min-width: 100%;
      padding-left: var(--gap-20-px);

      :nth-child(1) {
        padding-left: 0;
      }

      .footerLink {
        padding-right: var(--gap-20-px);
      }
    }

    @media (max-width: $mobile-width) {
      min-width: 100%;
      padding-left: var(--gap-20-px);
    }
  }

  #copyrightTextContainer {
    @include flex(row, flex-end, center, nowrap);
    padding: var(--gap-10-px) var(--gap-30-px) var(--gap-10-px) var(--gap-20-px);
    @include boxSizing(border-box);

    @media (max-width: $tablet-width) {
      padding-right: var(--gap-20-px);
    }

    @media (max-width: $mobile-width) {
      padding-left: var(--gap-20-px);
    }

    .caption {
      font-family: var(--font-family);
    }
  }
}

.footer.new-footer {
  gap: var(--gap-20-px);

  @media (max-width: $mobile-width) {
    gap: 0rem;
  }

  & .links {
    text-decoration: underline;
    font-size: var(--global-body-13-font-size);
    font-weight: var(--font-weight);

    &:hover {
      background-color: rgba(map-get($colour-palette, n-open-yellow), 0.85);
    }
  }

  & #footerLogo img {
    height: 45px;
  }

  & #copyrightTextContainer .caption {
    font-size: var(--global-body-13-font-size);
    ;
  }

  #footerLogo {
    padding-top: var(--gap-10-px);
    padding-bottom: var(--gap-10-px);
  }

  .links {
    display: flex;
    align-items: center;
  }

  .linkedin::after {
    content: url('../../styles/icons/blue/linkedin.svg');
    margin-left: var(--gap-5-px);
    margin-top: var(--gap-5-px);
  }

  #footerLinks {
    gap: var(--gap-10-px);
  }

  #footerLinks .footerLink {
    padding: 0.1rem var(--gap-5-px);
    border-radius: 3px;
  }

  @media (max-width: $tablet-width) {
    #footerLinks {
      min-width: auto;
      padding-left: 0;
      row-gap: 0;
      column-gap: var(--gap-20-px);
    }
  }

  @media (max-width: $mobile-width) {
    #footerLinks {
      min-width: 100%;
      padding-left: var(--gap-20-px);
    }
  }
}