#lpMainContent {
    @include flex(row, center, stretch, nowrap);
    width: 100%;
    height: 100%;
    min-height: 90vh;
    overflow: hidden;

    #lpLeftContainer {
        width: 40%;
        min-width: 150px;
        min-height: 550px;
        padding: var(--gap-40-px);
        color: map-get($colour-palette, open-white);
        background-color: var(--header-background-color);
        background-image: var(--header-background-image);

        @media (max-width: $tablet-width) {
            min-height: 100%;
            padding: var(--gap-20-px);
        }

        @media (max-width: $mobile-width) {
            display: none;
        }

        p {
            margin: var(--gap-20-px) var(--gap-20-px) 0 0;
            width: 90%;
            white-space: pre-wrap;

            .bold {
                font-family: NotoSans-Bold;
            }
        }

        #logo {
            @include flex(row, flex-start, center, nowrap);
            cursor: pointer;

            img {
                height: 50px;
            }

            @media (max-width: $tablet-width) {
                img {
                    height: 55px;
                }
            }

            @media (max-width: $mobile-width) {
                img {
                    height: 50px;
                }
            }
        }

        #supplierCardImage {
            @include flex(row, center, center, nowrap);

            img {
                padding: var(--gap-40-px);
                width: 100%;
            }
        }
    }

    #lpRightContainer {
        @include flex(column, flex-start, center, nowrap);
        width: 60%;
        min-height: 550px;
        padding: var(--gap-40-px);
        background: map-get($colour-palette, open-white);

        @media (max-width: $tablet-width) {
            @include flex(column, center, center, nowrap);
            min-height: 100%;
        }

        @media (max-width: $mobile-width) {
            @include flex(column, center, center, nowrap);
            min-height: 400px;
            width: 100%;
        }

        #auth-nav {
            @include flex(row, flex-end, baseline, nowrap, var(--gap-10-px));
            width: 100%;
            padding-bottom: var(--gap-100-px);
            order: 1;

            @media (max-width: $tablet-width) {
                padding-bottom: var(--gap-80-px);
            }

            @media (max-width: $mobile-width) {
                @include flex(row, center, baseline, nowrap);
                padding-bottom: 0rem;
                order: 4;
            }

            #create-an-account-btn {
                @include flex(column, flex-start, flex-start, nowrap);
                width: auto;
                color: map-get($colour-palette, open-browser-link-blue);
                background: none;
            }
        }

        #login-form-contanier {
            order: 3;
            width: 350px;

            @media (max-width: $tablet-width) {
                width: 100%;
                max-width: 350px;
            }

            #auth-content {
                @include flex(column, flex-start, flex-start, nowrap);
                width: 100%;
                order: 2;
                margin-bottom: var(--gap-40-px);
                gap: var(--gap-10-px);

                & h1 {
                    font-size: var(--global-body-36-font-size);
                }

                & label {
                    font-size: var(--global-body-20-font-size);
                }
            }

            #form-login {
                @include flex(column, center, center, nowrap);

                .form-login-fields {
                    @media (max-width: $tablet-width) {
                        width: 100%;
                    }
                }

                #passwordLabel {
                    @include flex(row, space-between, baseline, nowrap);
                    width: 100%;

                    @media (max-width: $tablet-width) {
                        @include flex(column, space-between, baseline, nowrap);
                    }

                    @media (max-width: $mobile-width) {
                        @include flex(column, space-between, baseline, nowrap);
                    }
                }

                #login-action-btn {
                    margin-top: var(--gap-30-px);
                    width: 100%;
                }


                #password-input,
                #email-input {
                    @media (max-width: $tablet-width) {
                        max-width: 100%;
                    }

                    @media (max-width: $mobile-width) {
                        max-width: 100%;
                    }
                }

                @media (max-width: $tablet-width) {
                    margin-bottom: var(--gap-30-px);
                }

                @media (max-width: $mobile-width) {
                    margin-bottom: var(--gap-30-px);
                }
            }
        }

        #login-form-maintenence-box {
            @include flex(row, center, center, nowrap);
            border: 2px solid var(--header-background-color);
            border-radius: 5px;
            margin-top: var(--gap-40-px);
            max-width: 350px;
            order: 5;

            p {
                padding: var(--gap-10-px);
                color: var(--header-background-color);
                text-align: center;
            }
        }

        #auth-logo {
            @include flex(row, center, center, nowrap);
            width: 100%;
            cursor: pointer;
            display: none;
            order: 1;
            margin-bottom: var(--gap-40-px);

            @media (max-width: $tablet-width) {
                display: none;
            }

            @media (max-width: $mobile-width) {
                display: flex;

                img {
                    height: 50px;
                    background-color: map-get($colour-palette, open-blue);
                }
            }
        }


    }
}

#lpMainContent.new-login {
    min-height: 91vh;

    & #auth-content {
        & h1 {
            color: map-get($colour-palette, n-open-blue);
            font-size: var(--global-body-40-font-size);

        }

        & label {
            font-size: var(--global-body-23-font-size);
        }
    }

    & .labelled-input {

        & .form-input {
            border-left: 4px solid map-get($colour-palette, n-open-blue);
        }


    }

    #login-form-contanier #form-login #login-action-btn {
        font-size: var(--global-body-15-font-size);
        font-weight: 500;
    }

    #lpRightContainer #login-form-maintenence-box p {
        padding: var(--gap-10-px);
        line-height: 2;
        color: map-get($colour-palette, open-text-primary);
    }

    #forgot-password-btn {
        color: map-get($colour-palette, n-open-links);
    }

    #auth-nav {
        gap: var(--gap-10-px);

        & p {
            font-size: var(--global-body-14-font-size);
        }

        & .links {
            font-size: var(--global-body-14-font-size);
            color: map-get($colour-palette, n-open-links);
        }
    }
}