.mqContentSectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 400px;
  flex: 1;
  background-color: map-get($colour-palette, open-white);
  border-radius: 10px;
  border: 1px solid map-get($colour-palette, open-content-container-border);
  padding: var(--gap-20-px);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#mqAccordion {
  margin-top: var(--gap-20-px);
  width: 100%;
  max-width: 900px;

  .accordion-section {
    margin-bottom: var(--gap-30-px);
    border: 1px solid var(--header-background-color);
    border-radius: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: $tablet-width) {
      margin-bottom: var(--gap-20-px);
    }

    &-header {
      border-radius: 5px;
      padding: 0 var(--gap-20-px);

      img {
        padding: 0;
      }

      @media (max-width: $tablet-width) {
        padding: 0 var(--gap-10-px);
      }

      &.contentOpen {
        border-radius: 5px 5px 0 0;
      }

      &-data {
        pointer-events: none;
        word-break: break-word;

        p {
          padding: var(--gap-5-px) 0;
        }

        .headerTitleContent {
          @include flex(row, center, center, nowrap);

          .share-icon {
            height: 20px;
            width: 20px;
            margin-right: var(--gap-10-px);
          }
        }

        .headerTitle {
          margin-right: var(--gap-20-px);

          @media (max-width: $tablet-width) {
            margin-right: var(--gap-10-px);
          }
        }

        .mqAccordionHeaderStatusPills {
          @include flex(row, center, center, nowrap);
          height: 100%;

          #invalidQuestionnaire-statuslabel {
            margin-right: var(--gap-10-px);
          }
        }
      }
    }

    &-content {
      &.open {
        border-top: 1px solid var(--header-background-color);
        border-radius: 0 0 5px 5px;
        overflow-y: auto;
      }

      &-data {
        @include flex(column, flex-start, space-between, nowrap);
        @include boxSizing(border-box);

        .mqAccordionSectionContentRow {
          // padding: var(--gap-10-px) 1.6rem;

          .mqAccordionSectionContentData {
            @include flex(row, space-between, flex-start, wrap);
            padding: var(--gap-20-px) var(--gap-20-px) 0rem var(--gap-20-px);

            .mqDataSection {
              @include flex(column, flex-start, flex-start, wrap);

              .mqWeightage {
                @include flex(row, flex-start, flex-start, wrap);

                .assigned {
                  color: map-get($colour-palette, open-text-warning);
                }
              }
            }

            .label {
              margin-bottom: var(--gap-10-px);
            }

            @media (max-width: $tablet-width) {
              padding: var(--gap-10-px);
            }

            @media (max-width: $mobile-width) {
              padding: var(--gap-5-px);

              p {
                padding: var(--gap-5-px);
              }

              .label {
                margin-right: 0;
                width: 100%;
              }
            }
          }
        }

        .bdAccordianSectionButton {
          width: 50%;
          @include flex(row, space-evenly, center, nowrap);
        }

        .button {
          margin: var(--gap-10-px) var(--gap-10-px) var(--gap-10-px) 0;
          width: fit-content;
          width: -moz-fit-content;
          min-width: 100px;
        }
      }
    }
  }
}

#mqButton {
  margin: var(--gap-10-px) var(--gap-10-px) var(--gap-10-px) 0;
  width: fit-content;
  width: -moz-fit-content;
  min-width: 100px;
}

.mqSummary {
  @include flex(row, space-between, center, wrap);
  min-height: 61px;
  width: 100%;
  border-bottom: 1px solid map-get($colour-palette, open-divider);

  .mqButtonContainer {
    @include flex(row, center, center, nowrap);
  }

  .mqButtonContainer>*:not(:last-child) {
    margin-right: var(--gap-20-px);
  }

  .title-xLarge {
    color: var(--header-background-color);
  }

  .button {
    width: auto;
  }
}

.mqContentNone {
  @include flex(column, center, center, wrap);
  height: 100%;
  width: 100%;
  flex-grow: 2;
}

.mqBannerContainer {
  margin: var(--gap-10-px);
}

.mqBannerRow {
  @include flex(row, space-between, center, wrap);
  padding: var(--gap-10-px);
  border-bottom: 1px solid map-get($colour-palette, open-divider);

  &:last-child {
    border-bottom: 0px;
  }
}

.mqAccordionHeader {
  @include flex(column, center, flex-start, wrap);
  width: 100%;
  padding: var(--gap-10-px) 0;

  .mqAccordionHeaderTitle {
    @include flex(row, space-between, flex-start, wrap);
    width: 100%
  }

  .mqLots {
    @include flex(row, flex-start, flex-start, wrap);
  }

  .mqAccordionHeaderTitle {
    @include flex(row, space-between, flex-start, wrap);
    width: 100%
  }
}

.mqAccordionFooter {
  @include flex(row, space-between, flex-start, wrap);
  margin-bottom: var(--gap-10-px);
  padding: 0 var(--gap-15-px);

  .mqlotButtons {
    @include flex(row, center, flex-start, wrap);
  }

  .mqWeightageButtons {
    @include flex(row, center, flex-start, wrap);
  }
}

.MuiAccordion-container#mqAccordion {
  max-width: 100%;

  & .material-icons {
    width: 2rem;
  }
}

.MuiAccordion-region .assigned {
  color: #B00020;
}

.Mui-extra {
  & .mqAccordionFooter {
    margin: 0;

    & .mqlotButtons {
      gap: var(--gap-20-px);

      & #mqButton {
        margin: 0;
      }
    }

    & .mqWeightageButtons {
      gap: var(--gap-20-px);

      & #mqButton {
        margin: 0;
      }
    }
  }
}