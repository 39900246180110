.contractDetailsItem {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    margin-top: var(--gap-20-px);
    word-break: break-word;

    .leftColumn {
        width: 20%;
        word-break: break-word;
    }

    .rightColumn {
        width: 80%;
    }
}

.contractStatusPills {
    @include flex(row, space-between, center, nowrap);

    #contractCancelled-statuslabel {
        margin-right: var(--gap-10-px);
    }

    .statusText {
        @include flex(column, space-around, center, nowrap);
        height: 30px;
        min-width: 100px;
        border-radius: 50px;
        margin-right: var(--gap-20-px);

        &.platinum {
            background-color: map-get($colour-palette, open-platinum);
        }

        &.silver {
            background-color: map-get($colour-palette, open-silver);
        }

        &.gold {
            background-color: map-get($colour-palette, open-gold);
        }
    }
}

#mainContent {
    height: auto;
}

#contractDetailsContainer {
    padding: var(--gap-20-px);
}

#awardedContractOverviewContainer {
    .itemHeaderContainer {
        line-height: normal;
        margin-left: -var(--gap-20-px);

        .headerTitle {
            font-weight: bold;
            color: #2c4874;
        }

        .referenceNumber {
            font-family: var(--font-family);
            font-size: var(--global-body-14-font-size);
            line-height: var(--line-height);
            // color: #20b3aa !important;
        }
    }
}