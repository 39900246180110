#manageLotsContentSectionWrapper {
    width: 100%;

    #manageLotsContentSection {
        @include flex(column, flex-start, center, nowrap);
        @include boxSizing(border-box);
        width: 100%;
        background-color: map-get($colour-palette, open-white);
        border-radius: 10px;
        border: 1px solid map-get($colour-palette, open-content-container-border);
        padding: var(--gap-20-px);

        #manageLotsContentHeader {
            @include flex(row, space-between, center, wrap);
            width: 100%;
            min-height: 50px;
            padding: var(--gap-15-px) 0;
            border-bottom: 1px solid map-get($colour-palette, open-divider);

            .buttonContainer {
                #addNewLotBtn {
                    margin-right: var(--gap-10-px);
                }
            }
        }

        #manageLotsContentBody {
            width: 100%;

            #lotItemMainContent {
                @include flex(column, flex-start, flex-start, wrap);
                width: 100%;
                height: 100%;

                .sectionItemMainContentRow {
                    @include flex(row, space-between, center, wrap);
                    width: 100%;
                    padding: var(--gap-15-px) 0;
                    border-bottom: 1px solid map-get($colour-palette, open-divider);

                    .sectionItemReorderBtnsCol {
                        height: 100%;
                        margin-right: var(--gap-20-px);

                        .orderBtn {
                            @include flex(column, center, center, nowrap);
                            width: 25px;
                            height: 25px;
                            border-radius: 5px;
                            background-color: var(--header-background-color);
                            color: map-get($colour-palette, open-white);
                            margin: var(--gap-5-px) 0;
                            cursor: pointer;
                            border: none;

                            img {
                                width: 15px;
                                height: 15px;
                                pointer-events: none;
                            }

                            &.disabled {
                                background-color: map-get($colour-palette, open-grey);
                            }
                        }
                    }

                    .sectionItemTextCol {
                        flex: 1;
                        min-width: 300px;
                        height: 100%;
                        word-break: break-word;

                        .sectionItemHeading {
                            color: map-get($colour-palette, open-green);
                            width: 75%;
                        }

                        .sectionItemDescription {
                            margin: var(--gap-5-px) 0 var(--gap-10-px) 0;
                        }
                    }

                    .sectionItemBtnCol {
                        @include flex(row, flex-start, center, wrap);
                        height: 100%;
                        margin-left: var(--gap-20-px);

                        button[id^="editSectionItemBtn-"] {
                            margin: var(--gap-10-px) var(--gap-15-px);
                        }
                    }
                }
            }
        }

        #manageLotsContentFooter {
            width: 100%;
            height: 80px;
        }
    }
}