.table-container {
    min-width: 500px;
    height: auto;
    // overflow: auto;

    .labelled-input .form-input {
        height: 30px;
        width: auto;
    }
}

.table {
    table-layout: fixed;
    border-spacing: 0;
    text-align: left;
    font-size: var(--global-body-12-font-size);
    box-sizing: border-box;
    border-collapse: separate;

    td {
        z-index: -1;
    }

    td,
    th {
        min-width: 150px;
        width: 350px;
        vertical-align: middle;
        border-bottom: 1px solid map-get($colour-palette, open-divider);
    }

    th {
        // position: sticky;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: map-get($colour-palette, open-white);
        color: map-get($colour-palette, open-text-primary);
    }

    tfoot tr th {
        bottom: 0;
        z-index: 0;
        background: map-get($colour-palette, open-white);
        border: none;
    }

    &-header {
        &-row {
            &-cell {
                padding: var(--gap-20-px) var(--gap-10-px);
                border-color: slategrey;
                border-style: solid;
                border-width: 1px 0;
                border-left: 1px solid map-get($colour-palette, open-divider);

                &:first-of-type {
                    border-top-left-radius: 10px;
                    border-left: none;
                    background-color: var(--header-background-color);
                    color: map-get($colour-palette, open-white);
                }

                &:last-of-type {
                    border-top-right-radius: 10px;
                    border-right-width: 0px;
                    background-color: var(--header-background-color);
                    color: map-get($colour-palette, open-white);
                }
            }
        }
    }

    &-body {
        &-row {
            &-cell {
                border-width: 0;
                border-left: 1px solid map-get($colour-palette, open-divider);

                &:first-of-type {
                    border-left: none;
                }

                &:last-child {
                    border-right-width: 1px;
                }

                .labelled-input .form-input {
                    height: 30px;
                    width: auto;
                }
            }
        }
    }

    &-footer {
        &-row {
            &-cell {
                padding: var(--gap-20-px) var(--gap-20-px);
                border-width: unset;

                &:first-of-type {
                    border-left-width: 1px;
                }

                &:last-child {
                    border-right-width: 1px;
                }
            }
        }
    }
}