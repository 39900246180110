#overViewData {
    #viewPublishNoticesSection {
        .itemContainer {
            .vpnItemButtonGroup {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
}

#submit {
    margin-left: var(--gap-50-px);
}