.bdContentSectionWrapper {
    width: 100%;

    #projectActions {
        height: auto;

        .projectActionContainer {
            @include flex(row, space-between, center, nowrap);
            width: 100%;

            @media (max-width: $mobile-width) {
                @include flex(column, space-between, center, nowrap);
            }

            .primaryToolbarBtn {
                margin-left: var(--gap-10-px);
                width: 200px;
            }

            .cancelButtonContainer {
                @include flex(column, flex-start, flex-start, nowrap);
                width: 100%;

                @media (max-width: $mobile-width) {
                    @include flex(row, space-between, space-between, nowrap);
                    padding-bottom: var(--gap-10-px);
                }

                .statuslabel-content {
                    margin-right: var(--gap-10-px);
                }

                .import-title {
                    color: var(--header-background-color);
                    margin-bottom: 5px;
                    font-size: var(--global-body-18-font-size);
                }
            }

            .moreActionContainer {
                @include flex(row, flex-end, center, nowrap);
                width: 100%;

                button {
                    margin-left: var(--gap-10-px);
                }

                @media (max-width: $mobile-width) {
                    @include flex(row, space-between, space-between, nowrap);
                    padding-top: var(--gap-10-px);

                    button {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .bdContentSection {
        @include flex(column, flex-start, center, nowrap);
        width: 100%;
        flex: 1;
        background-color: map-get($colour-palette, open-white);
        border-radius: 10px;
        border: 1px solid map-get($colour-palette, open-content-container-border);
        padding: var(--gap-10-px);
        @include boxSizing(border-box);

        .bdContentHeader {
            @include flex(row, flex-start, center, wrap);
            width: 100%;
            min-height: 50px;

            .searchBarContainer {
                width: 45%;
                padding: var(--gap-10-px);
                @include boxSizing(border-box);

                @media (max-width: $mobile-width) {
                    width: 100%;
                }
            }

            .sortFilterContainer {
                @include flex(row, flex-end, center, nowrap, var(--gap-20-px));
                width: 55%;
                padding: var(--gap-10-px);
                @include boxSizing(border-box);

                .button {
                    @media (max-width: $mobile-width) {
                        min-width: 70px;
                    }
                }

                #opportunitiesSortDropdown,
                #contractsregisterSortDropdown,
                #suppliersSortDropdown,
                #projectsSortDropdown,
                #requestsSortDropdown,
                #solutionsSortDropdown,
                #workflowsSortDropdown,
                #questionnairesSortDropdown,
                #questionnairesselectSortDropdown,
                #contractsSortDropdown,
                #spendsSortDropdown,
                #activitylogsSortDropdown,
                #spendsBtn-upload,
                #rebatesSortDropdown,
                #rebatesBtn-upload,
                #eventsSortDropdown,
                #stagesSortDropdown,
                #sectionsSortDropdown,
                #correspondenceSortDropdown,
                #correspondenceExportButton {
                    margin-right: var(--gap-10-px);

                    @media (max-width: $tablet-width) {
                        margin-right: var(--gap-5-px);
                    }
                }

                #opportunitiesFilterButton,
                #contractsregisterFilterButton,
                #projectsFilterButton,
                #suppliersFilterButton,
                #requestsFilterButton,
                #solutionsFilterButton,
                #workflowsFilterButton,
                #questionnairesFilterButton,
                #solutionsFilterButton,
                #contractsFilterButton,
                #activitylogsFilterButton,
                #contractsregisterExportButton,
                #addNewButton,
                #eventsFilterButton,
                #stagesFilterButton {
                    margin-left: var(--gap-10-px);

                    @media (max-width: $tablet-width) {
                        margin-left: var(--gap-5-px);
                    }
                }

                #contractsregisterExportButton {
                    margin-right: var(--gap-15-px);
                }

                @media (max-width: $mobile-width) {
                    @include flex(row, space-between, center, nowrap);
                    width: 100%;
                }
            }
        }

        #bdContentHeader.sticky {
            position: fixed;
            z-index: 1;
            top: 100px;
            background: white;
            transition: top 0.5s ease-in-out;
            border-bottom: 2px solid #eee;
            max-width: 1198px;

            @media (max-width: $tablet-width) {
                top: 80px;
            }
        }

        .bdContentBody {
            @include flex(column, flex-start, center, nowrap);
            width: 100%;
            flex: 1;
            padding: var(--gap-10-px) 0;

            #bdAccordion.MuiAccordion-container {
                width: 96%;
                max-width: 100%;
            }

            #bdAccordion {
                width: 100%;
                max-width: 900px;

                .accordion-section {
                    margin-bottom: var(--gap-10-px);
                    border: 1px solid var(--header-background-color);
                    border-radius: 5px;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media (max-width: $tablet-width) {
                        margin-bottom: var(--gap-10-px);
                    }

                    &-header {
                        border-radius: 5px;
                        padding: 0 var(--gap-10-px);

                        img {
                            padding: 0;
                        }

                        @media (max-width: $tablet-width) {
                            padding: 0 var(--gap-10-px);
                        }

                        &.contentOpen {
                            border-radius: 5px 5px 0 0;
                        }

                        &-data {
                            pointer-events: none;
                            word-break: break-word;

                            .bdAccordionSectionTitleColumn {
                                @include flex(column, space-between, flex-start, nowrap);
                            }

                            .bdAccordionSectionTitleRow {
                                @include flex(row, space-between, flex-start, nowrap);
                                width: 100%;
                            }

                            .neworUnread_accordion_header {
                                @include flex(row, center, center, nowrap);
                            }

                            .title {
                                font-weight: bold;
                                color: map-get($colour-palette, open-blue);
                            }

                            p {
                                padding: var(--gap-10-px) 0;
                            }

                            .headerTitleContent {
                                @include flex(row, center, center, nowrap);

                                .share-icon {
                                    height: 20px;
                                    width: 20px;
                                    margin-right: var(--gap-10-px);
                                }
                            }

                            .headerTitle {
                                margin-right: var(--gap-10-px);

                                @media (max-width: $tablet-width) {
                                    margin-right: var(--gap-10-px);
                                }
                            }

                            .bdAccordionSectionHeaderAddon {
                                @include flex(row, center, center, nowrap);

                                .status-label:not(:last-child) {
                                    margin-right: var(--gap-10-px);
                                }

                                .status-label {
                                    min-width: 130px;
                                    max-width: max-content;
                                    background: map-get($colour-palette, open-white);
                                    color: map-get($colour-palette, open-white);
                                    border: 1px solid var(--header-background-color);
                                    border-radius: 50px;
                                    padding-left: var(--gap-10-px);
                                    padding-right: var(--gap-10-px);

                                    &.red {
                                        color: map-get($colour-palette, open-red);
                                        border: 1px solid map-get($colour-palette, open-red);
                                    }

                                    &.amber {
                                        color: map-get($colour-palette, open-amber);
                                        border: 1px solid map-get($colour-palette, open-amber);
                                    }

                                    &.green {
                                        color: map-get($colour-palette, open-green);
                                        border: 1px solid map-get($colour-palette, open-green);
                                    }

                                    &.primary {
                                        background: map-get($colour-palette, open-blue-90);
                                        border: 1px solid map-get($colour-palette, open-blue-90);
                                    }
                                }

                                &.cancelled {
                                    background-color: map-get($colour-palette, open-red);
                                    border-radius: var(--gap-50-px);
                                    margin: var(--gap-10-px);
                                    width: 135px;
                                    height: 25px;
                                }

                                &.active {
                                    background-color: map-get($colour-palette, open-active-pill);
                                    border-radius: var(--gap-50-px);
                                    margin: var(--gap-10-px);
                                    width: 135px;
                                    height: 25px;
                                }

                                &.awarded {
                                    background-color: map-get($colour-palette, open-awarded-pill);
                                    border-radius: var(--gap-50-px);
                                    margin: var(--gap-10-px);
                                    width: 135px;
                                    height: 25px;
                                }

                                &.projects {
                                    background-color: map-get($colour-palette, open-green);
                                    border-radius: var(--gap-50-px);
                                    margin: var(--gap-10-px);
                                    width: 135px;
                                    height: 25px;
                                }

                                .label {
                                    margin-right: var(--gap-10-px);

                                    &.projects {
                                        color: map-get($colour-palette, open-white);
                                        padding: 0;
                                        margin: 0 auto;
                                    }
                                }

                                .headerTag {
                                    color: map-get($colour-palette, open-grey);
                                    background: map-get($colour-palette, open-footer-border);
                                    min-width: 120px;
                                    border-radius: 50px;
                                    font-size: var(--global-body-16-font-size);
                                    text-align: center;
                                    margin: var(--gap-10-px) 0;

                                    &.active {
                                        color: map-get($colour-palette, open-white);
                                        background: map-get($colour-palette, open-green);
                                        padding: 0 10px;
                                    }

                                    @media (max-width: $mobile-width) {
                                        width: 100px;
                                        font-size: var(--global-body-14-font-size);
                                    }
                                }
                            }
                        }
                    }

                    &-content {
                        &.open {
                            border-top: 1px solid var(--header-background-color);
                            border-radius: 0 0 5px 5px;
                            overflow-y: auto;
                        }

                        &-data {
                            @include flex(column, flex-start, center, nowrap);
                            @include boxSizing(border-box);

                            .bdAccordionSectionContentColumn {
                                @include flex(column, space-between, flex-start, wrap);
                            }

                            .bdAccordionSectionContentRow {
                                @include flex(row, space-between, flex-start, wrap);
                                width: 100%;

                                .bdAccordionSectionContentData {
                                    @include flex(row, flex-start, flex-start, wrap);
                                    padding: var(--gap-10-px) var(--gap-10-px);
                                    word-break: break-word;
                                    flex-wrap: nowrap;

                                    &:not(:first-child) {
                                        padding-top: 0;
                                    }

                                    .label {
                                        margin-right: var(--gap-10-px);
                                        font-weight: 600;
                                        display: flex;
                                        flex: none;
                                    }

                                    @media (max-width: $tablet-width) {
                                        padding: var(--gap-10-px);
                                    }

                                    @media (max-width: $mobile-width) {
                                        padding: var(--gap-5-px);

                                        p {
                                            padding: var(--gap-5-px);
                                        }

                                        .label {
                                            margin-right: 0;
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            .bdAccordianSectionButton {
                                width: 50%;
                                @include flex(row, space-evenly, center, nowrap, var(--gap-20-px));

                                .selected-event {
                                    width: 150px;
                                    height: 30px;
                                }
                            }

                            .button {
                                margin: var(--gap-10-px) 0 var(--gap-15-px) 0;
                                width: fit-content;
                                width: -moz-fit-content;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .infinite-scroll-component__outerdiv {
                width: 100%;

                .infinite-scroll-component {
                    width: 100%;
                    @include flex(column, center, center, nowrap);

                    #endMessage {
                        margin-top: var(--gap-10-px);
                        text-align: center;
                    }
                }
            }
        }

        #boFilterPanel,
        #bsFilterPanel,
        #bprFilterPanel,
        #bcrFilterPanel,
        #bsolFilterPanel,
        #bwFilterPanel,
        #bqFilterPanel,
        #bcFilterPanel {
            @include flex(column, flex-start, flex-start nowrap);
            top: 100px;
            bottom: 20px;

            @media (max-width: $mobile-width) {
                top: 0;
                bottom: 0;
            }
        }

        .bdContentFooter {
            width: 100%;
            height: 80px;
        }
    }
}

.newOrUnreadData {
    @include flex(row, center, center, nowrap);

    &.tag {
        background-color: map-get($colour-palette, open-unread);
        border-radius: var(--gap-50-px);
        width: 20px;
        color: white;
        margin-right: 10px;
    }
}

.width70 {
    width: 70%;
}

.browse-content {
    width: 100%;
}

.bdContentSection.new-bdContentSection {
    padding: 0;
    background: none;
    border: none;

    #bdContentHeader.sticky {
        top: 110px;
        background: map-get($colour-palette, n-open-searchbar);
        max-width: none;
        border-radius: 0;
        margin: auto;
        width: auto;
        left: 0;
        right: 0;
    }

    & .browse-content {
        background-color: #fefefe;
        border-radius: 0 10px 10px 10px;
        border: 1px solid #f1f1f1;
    }

    & .bdContentHeader {
        margin: var(--gap-20-px);
        padding: var(--gap-15-px);

        width: auto;
        background: map-get($colour-palette, n-open-searchbar);
        border-radius: 10px;
        gap: var(--gap-20-px);
        flex-wrap: nowrap;


        @media (max-width: $mobile-width) {
            flex-wrap: wrap;

        }

        & .searchBarContainer {
            padding: 0;
            width: 55%;

            @media (max-width: $mobile-width) {
                width: 100%;

            }
        }

        & .sortFilterContainer {
            padding: 0;
            width: 43%;

            & select#projectsSortDropdown,
            & button#projectsFilterButton,
            & button#addNewButton {
                margin: 0;
            }

            @media (max-width: $tablet-width) {
                width: 58%;
            }

            @media (max-width: $mobile-width) {
                width: 100%;
                gap: var(--gap-20-px)
            }

        }

        & button {
            width: fit-content;
            padding-left: var(--gap-20-px);
            padding-right: var(--gap-20-px);
            font-weight: 600;
        }
    }

    & .bdContentBody {
        padding-top: 0;
    }

    & .tabs {
        margin-top: 0;
        margin-bottom: -1px;
        z-index: 1;

        & .tab-header {
            border: none;
            border-spacing: var(--gap-15-px) 0rem;
            margin-left: -1rem;
            margin-right: - 1rem;

            & li {
                font-size: var(--global-body-16-font-size);
                padding: var(--gap-15-px) 0;
                border-right: 1px solid #f1f1f1;
                border-left: 1px solid #f1f1f1;
                border-top: 1px solid #f1f1f1;

                @media (max-width: $tablet-width) {
                    font-size: var(--global-body-14-font-size);
                }

                @media (max-width: $mobile-width) {
                    font-size: var(--global-body-12-font-size);
                }
            }

            & li.active {
                background: #fefefe;
                font-weight: 700;
                color: var(--header-background-color);
                box-shadow: 2px -7px 9px -11px rgba(0, 0, 0, 0.2);

            }
        }
    }

    & .newOrUnreadData.tag {
        height: 20px;
    }

    & .statuslabel-content {
        margin-left: auto;
        height: 30px;
    }

    & .bdAccordionSectionHeaderAddon {
        @include flex(row, center, center, nowrap);

        & label {
            font-family: var(--font-family);
            font-size: var(--global-body-14-font-size);
            display: block;
            margin: auto;
            text-align: center;
        }

        &.cancelled {
            border-radius: var(--gap-50-px);
            margin: var(--gap-10-px);
            width: 135px;
            height: 25px;
            border: 1px solid map-get($colour-palette, open-red);
            background-color: rgba(map-get($colour-palette, open-red), 0.2);

            & label {
                color: map-get($colour-palette, open-red);
                font-weight: 600;
            }
        }

        &.active {
            border-radius: var(--gap-50-px);
            margin: var(--gap-10-px);
            width: 135px;
            height: 25px;
            border: 1px solid map-get($colour-palette, open-active-pill);
            background-color: rgba(map-get($colour-palette, open-active-pill), 0.2);

            & label {
                color: map-get($colour-palette, open-active-pill);
                font-weight: 600;
            }
        }

        &.awarded {
            border-radius: var(--gap-50-px);
            margin: var(--gap-10-px);
            width: 135px;
            height: 25px;
            border: 1px solid map-get($colour-palette, open-awarded-pill);
            background-color: rgba(map-get($colour-palette, open-awarded-pill), 0.2);

            & label {
                color: map-get($colour-palette, open-awarded-pill);
                font-weight: 600;
            }
        }
    }
}

.browse-pills {
    @include flex(column, space-around, center, nowrap);
    height: 25px;
    min-width: 100px;
    border-radius: 50px;
    color: map-get($colour-palette, open-white);
    font-weight: 500;
    padding: 0 var(--gap-10-px);
    font-weight: 600;

    &.cancelled {
        background-color: rgba(map-get($colour-palette, n-status-red), 0.2);
        border: 1px solid map-get($colour-palette, n-status-red);
        color: map-get($colour-palette, n-status-red);

    }

    &.completed {
        background-color: rgba(map-get($colour-palette, n-open-event-status-completed), 0.2);
        border: 1px solid map-get($colour-palette, n-open-event-status-completed);
        color: map-get($colour-palette, n-open-event-status-completed);
    }

}

.buttons-refine-supplier {
    @include flex(row, center, center, nowrap, var(--gap-20-px));
}

.event-selected {
    display: flex;
    color: var(--header-background-color);
    font-weight: 600;
    align-items: center;

    &::before {
        content: url(../../styles/icons/blue/confirm.svg);
        margin-right: 5px;
    }
}

.bdContentSection img.buyer-logo {
    max-width: 150px;
    max-height: 100px;
}
.bdContentSection img.buyer-logo--big {
    max-width: 260px;
}

[data-theme="new-style"] {
    #bdContentHeader.sticky {
        position: fixed;
        z-index: 1;
        top: 181px;
        background: white;
        transition: top 0.5s ease-in-out;
        border-bottom: 2px solid #eee;
        max-width: 1220px;

        @media (max-width: $tablet-width) {
            top: 80px;
        }
    }
}
