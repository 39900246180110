.panel-container {
    width: 100%;
    height: auto;
    margin-top: 30px;
    border-radius: var(--gap-10-px);
    background-color: map-get($colour-palette, open-white);
    position: relative;

    .panel {
        border-radius: 10px;
        background-color: map-get($colour-palette, open-white);
        min-width: 250px;

        &-content {
            height: 100%;
            padding: var(--gap-20-px);
        }

        &-header {
            @include flex(row, center, center, nowrap);
            width: 100%;
            height: 25px;
            border-bottom: 1px solid map-get($colour-palette, open-divider);
            border-radius: 10px 10px 0 0;
            position: relative;
            padding-bottom: var(--gap-10-px);

            &-title {
                width: 100%;
            }
        }

        &-body {
            flex: 1;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            margin: var(--gap-20-px) 0;
            padding: 0 0.1rem;
            @include boxSizing(border-box);

            &-title {
                font-size: var(--global-body-16-font-size);
            }

            &-label {
                font-size: var(--global-body-14-font-size);
            }
        }

        &-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: var(--gap-20-px) var(--gap-30-px) var(--gap-20-px) var(--gap-30-px);
            @include boxSizing(border-box);
            @include flex(row, space-between, center, wrap);

            #main-action-btn {
                width: 100%;
                height: 40px;
            }

            @media (max-width: $mobile-width) {
                padding: var(--gap-10-px) var(--gap-20-px) var(--gap-10-px) var(--gap-20-px);

                #main-action-btn {
                    width: 120px;
                    height: 35px;
                }
            }
        }
    }

    &.small {
        max-width: 400px;
        max-height: 400px;
    }

    &.medium {
        max-width: 790px;
        max-height: 400px;
    }

    &.large {
        width: 100%;
        max-height: 400px;
    }
}