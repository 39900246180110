.usersAndDepartmentsNoResult {
  @include flex(column, space-around, center, nowrap);
  height: 100%;

  margin-top: 4rem;

  p {
    padding-left: 0.6rem;
  }
}

.usersAndDepartmentsResult {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;

  .usersAndDepartmentsOrigin {
    @include flex(row, flex-start, flex-start, nowrap);

    p {
      color: map-get($colour-palette, open-text-grey);
    }
  }
}

.sectionContentTags {
  @include flex(column, center, flex-start, wrap, var(--gap-10-px));
}