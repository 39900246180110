.editSteeringGroup {
    justify-content: flex-end;
    width: auto;
}

.steeringGroupsContainer {
    width: 100%;
    @include flex(row, flex-start, center, nowrap, var(--gap-20-px));
    flex-direction: column;

    .steeringGroupsItem {
        @include flex(row, flex-start, space-between, nowrap);
        width: 100%;


        .steeringGroupItem {
            @include flex(row, flex-start, space-between, wrap, var(--gap-10-px));
            flex-direction: column;
        }
    }
}