.screen-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(34, 34, 34, 0.4);
  z-index: 2;
  top: 0;
  left: 0;

  &.stack-top {
    z-index: 98;
  }

  @media (max-width: $tablet-width) {
    z-index: 2;
  }

}

header.navbar {
  // Just above everything in the screen-overlay
  z-index: 100;
}

.screen-overlay.session-timeout {
  z-index: 106;

  @media (max-width: $tablet-width) {
    z-index: 106;
  }
}