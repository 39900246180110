.tag-content {
    @include flex(row, space-between, center, nowrap);

    background: var(--tag-background);
    border: var(--tag-border);
    border-radius: 50px;
    padding: var(--gap-5-px) var(--gap-10-px);
    color: var(--tag-font-color);

    @include boxSizing(border-box);

    .tag-del-btn-wrapper {
        // margin-right: 2rem;
        border: none;
        background: none;
        @include flex(row, center, center, nowrap);
    }

    .tag-delete-btn {
        cursor: pointer;
    }


    @media (max-width: $mobile-width) {}

    &.small {


        .tag-del-btn-wrapper {
            margin-right: 0rem;
            border: none;
            background: none;
        }

        @media (max-width: $mobile-width) {
            max-height: 100%;
        }
    }

    &.large {

        padding: var(--gap-5-px) var(--gap-10-px);

        @media (max-width: $mobile-width) {}

        &.with-margins {
            margin: 0 20px 30px 0px;
        }
    }

    &.xlarge {

        padding: var(--gap-10-px) var(--gap-20-px);

        @media (max-width: $mobile-width) {}
    }
}

.tag-content.new-tag-content {
    & .tag-txt.body {
        font-size: var(--global-body-12-font-size);
        font-weight: 480;
    }
}