.customButtonStyle {
    margin-right: var(--gap-10-px);
}

#addSupplierToOpportunityButton {
    width: fit-content;
}

#cleverQuotesContentHeader {
    @include flex(row, flex-end, center, wrap);
}