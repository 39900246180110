.evaluation-summary-tree {
    width: 100%;
    border: 1px solid map-get($colour-palette, n-card-border);
    margin-top: var(--gap-20-px);
    padding-bottom: var(--gap-20-px);
    padding: 0;

    & .tree-viewer-item a {
        padding-right: var(--gap-20-px);
        padding-left: var(--gap-20-px);
    }

    & div[aria-level='0']>a {
        color: #2c4874;
        font-size: var(--global-body-18-font-size);
        font-weight: bold;
        flex-wrap: wrap;
        border-bottom: 1px solid map-get($colour-palette, n-card-border);

        & .tree-view-menu {
            margin-left: inherit;
            margin-right: auto;
        }
    }

    & div[aria-expanded='false']>a {
        border-bottom: none;
    }

    & div[aria-level='1']>a {
        padding-left: var(--gap-20-px);
        font-weight: 500;
        flex-wrap: wrap;

        & .tree-view-menu {
            margin-left: inherit;
            margin-right: auto;
        }
    }

    & div[aria-level='2']>a {
        padding-left: var(--gap-40-px);
        color: #2c4874;
        font-weight: 600;
        row-gap: var(--gap-10-px);

        & .button {
            width: auto;
            padding-right: var(--gap-20-px);
            padding-left: var(--gap-20-px);
        }

        @media only screen and (max-width : 700px) {
            padding-left: var(--gap-20-px);
        }
    }

    & div[aria-level='2'] .tree-view-menu span {
        color: #0b0c0c;
        font-weight: normal;
    }

    & div[aria-level='3'] {
        &>div {
            padding-left: var(--gap-60-px);
            background: #f2f6f9;
            padding-right: var(--gap-20-px);
            border-bottom: 1px solid map-get($colour-palette, n-card-border);
            flex-wrap: wrap;
            row-gap: var(--gap-20-px);

            @media only screen and (max-width : 700px) {
                padding-left: var(--gap-20-px);
            }
        }

        & .tree-view-child {
            @media only screen and (max-width : 700px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        & .tree-view-menu {
            @media only screen and (max-width : 700px) {
                flex-wrap: wrap;
                align-items: flex-start;
            }
        }

    }


    & .tree-viewer-item>a .tree-view-menu {
        margin-left: auto;
        margin-right: inherit;

        @media only screen and (max-width : 700px) {
            display: block;

            &>div {
                margin-bottom: var(--gap-10-px);
            }
        }
    }

    & .redButton {
        background: #ce0000;
        color: #fff;
    }

    & .user-icon {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }

    & .submission-label span {
        display: block;
        margin-top: var(--gap-5-px);
        font-weight: normal;
        font-size: var(--global-body-13-font-size);
    }

    &.statuslabel-txt-container a {
        text-transform: capitalize;
    }
}