#solutionOverview {
  @include flex(row, space-around, stretch, wrap);
  width: 100%;
  height: auto;

  .soModal {
    p {
      margin-bottom: var(--gap-15-px);
    }

    .soDefaultWorkflow {
      margin-top: var(--gap-10-px);

      @include flex(column, flex-start, flex-start, wrap);
      margin: var(--gap-15-px) 0;

      .soWorkflowItem {
        @include flex(row, space-between, center, wrap);
        width: 100%;

        p {
          margin: 0;
        }
      }
    }
  }

  .modal-container .modal-content .labelled-textarea .form-textarea {
    width: 100%;
  }

  // #faqModal,
  // #registerRequestModal,
  // #escalate {
  //   max-height: 525px;
  // }

  .progress-bar {
    margin-bottom: var(--gap-30-px);
  }

  .solutionOverviewContainer {
    @include flex(row, space-between, stretch, wrap);
    width: 100%;

    .dashboardCard {

      &.small,
      &.medium {
        height: 410px;
      }
    }

    #solutionDetailsEvent,
    #solutionDetails {
      height: auto;
      min-height: 590px;

      .dashboardCardHeader {
        padding: var(--gap-20-px);
        @include boxSizing(border-box);

        .dashboardHeaderText {
          word-break: break-word;
          margin-left: -var(--gap-20-px);
        }

        .dashboardHeaderBtn {
          @include flex(row, space-between, center, nowrap);

          @media (max-width: $mobile-width) {
            @include flex(column, space-between, center, nowrap);

            .button:first-child {
              margin-right: 0rem;
              margin-bottom: var(--gap-20-px);
            }
          }
        }
      }

      #error {
        padding-left: var(--gap-20-px);
      }
    }

    .dashboardCardMain {
      .accordion-container {
        padding: var(--gap-20-px) 0;
      }
    }

    #shareSolution .dashboardHeaderBtn button {
      width: 100px;
    }

    #sharedWith {
      margin-top: 15px;
    }

    .dashboardCardMain #noWorkflow,
    #noLot,
    #notShared {
      @include flex(column, center, center, nowrap);
      height: 100%;

      #workFlowText,
      #lotText,
      #notSharedText {
        margin: var(--gap-20-px);
        margin-bottom: 0;
      }
    }

    .dashboardCardMain,
    .sectionLabel {
      margin-bottom: var(--gap-10-px);
    }

    #shareSolution.dashboardCard {
      .dashboardCardMain {
        overflow: visible;
      }
    }

    #steeringGroupEvent .sectionContent {
      padding: var(--gap-10-px) 0 var(--gap-20-px);
    }

    #interestRemoved .sectionContent {
      padding: var(--gap-10-px) 0 var(--gap-20-px);
    }

    #steeringGroupEvent .button {
      @include flex(row, flex-end, center, nowrap);
    }

    .sectionContent {
      padding: var(--gap-20-px) var(--gap-20-px);
      border: none;
      box-sizing: border-box;
      word-break: break-word;

      @media (max-width: $mobile-width) {
        padding-bottom: var(--gap-10-px);
      }

      &Tags {
        @include flex(row, flex-start, flex-start, wrap);
        flex: 1;

        @media (max-width: $mobile-width) {
          padding: 1.5rem 0;
        }

        .tag-content {
          margin: 0 var(--gap-20-px) var(--gap-20-px) 0;

          @media (max-width: $mobile-width) {
            margin: 0 0 var(--gap-10-px) 0;
          }
        }
      }

      .sectionRow {
        @include flex(row, flex-start, flex-start, nowrap);
        margin-top: var(--gap-20-px);

        @media (max-width: $mobile-width) {
          flex-wrap: wrap;

          p {
            width: 100%;
            margin-left: 0;

            &:first-of-type {
              margin-bottom: var(--gap-10-px);
            }
          }
        }

        .sectionLabel {
          width: 100%;

          @media (min-width: $laptop-width) {
            max-width: 250px;
          }

          @media (min-width: $mobile-width) {
            max-width: 230px;
          }
        }

        .sectionValue {
          width: 100%;
          word-break: break-word;
          color: map-get($colour-palette, open-text-primary);
        }
      }
    }

    .seeMoreLess {
      color: grey;
      cursor: pointer;
      font-style: italic;
      font-size: var(--global-body-13-font-size);
    }
  }

  #awardedSuppliers {
    width: 100%;

    .supplierItem {
      @include flex(row, space-between, center, nowrap);

      .supplierDetails {
        @include flex(column, flex-start, flex-start, nowrap);
        width: 100%;
      }
    }

    .supplierItem:not(:last-child) {
      margin-bottom: var(--gap-10-px)
    }
  }
}