.item {
    @include flex(row, flex-start, center, nowrap);
    width: auto;
    height: 100%;
    position: relative;
    text-align: center;

    &:hover {
        border-bottom: 2px solid #fff;
        margin-top: 0.125rem;
        height: 2.4em;

        & .item-active-strip {
            display: none;
        }
    }

    &-link {
        color: map-get($colour-palette, open-white);
        text-decoration: none;
    }

    &-active-strip {
        background-color: map-get($colour-palette, open-white);
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &-title {
        color: map-get($colour-palette, open-white);
        text-decoration: none;
        cursor: pointer;
        margin: 0;
        padding-right: var(--gap-5-px);
    }

    .dropdown-item-arrow {
        height: 10px;
        margin-left: var(--gap-5-px);
    }

    .dropdown {
        position: relative;

        &-options {
            @include flex(column, flex-start, flex-start, nowrap);
            background-color: map-get($colour-palette, open-white);
            position: absolute;
            top: 40px;
            white-space: nowrap;
            width: 220px;
            transition: height 0.2s ease-out;
            border-radius: 5px;

            &-item {
                @include flex(row, flex-start, center, nowrap);
                @include boxSizing(border-box);
                width: 100%;

                &:hover {
                    background-color: map-get($colour-palette, open-body-grey);
                }

                &:first-of-type {
                    border-radius: 5px 5px 0 0;
                }

                &:last-of-type {
                    border-radius: 0 0 5px 5px;
                }

                a {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    text-decoration: none;
                    color: map-get($colour-palette, open-text-primary);
                    height: 50px;
                    padding: 0 var(--gap-10-px);
                    cursor: pointer;
                }
            }

            &.hidden {
                display: none;
            }
        }
    }
}