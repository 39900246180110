#notificationsContainer {
    @include flex(column, flex-start, flex-start, nowrap);
    // width: 100%;
    // height: 100%;
    padding-top: var(--gap-10-px);
    border: 1px solid map-get($colour-palette, open-content-container-border);
    border-radius: 5px;
    background-color: map-get($colour-palette, open-white);

    #notificationEventRows {
        width: 100%;
        flex: 1;
        overflow-y: auto;

        .notificationEventRowContentCol {
            width: 100%;
        }
    }

    #notificationsContainerFooter {
        width: 100%;
        margin: var(--gap-20-px) 0;
        text-align: center;
        cursor: pointer;

        .button {
            width: 100%;
        }
    }

    #notificationError {
        padding: var(--gap-15-px);
    }

    .share-icon {
        height: 20px;
        width: 20px;
        margin-right: var(--gap-10-px);
    }
}