.card {
    @include flex(column, flex-start, flex-start, nowrap);
    background-color: map-get($colour-palette, open-white);
    @include boxSizing(border-box);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;

    &-header {
        @include flex(row, flex-start, center, nowrap);
        background-color: rgba(0, 0, 0, 0.05);
        color: #6c757d;
        width: 100%;
        height: 12.5%;
        padding: 0 var(--gap-10-px);
        margin: 0;
        @include boxSizing(border-box);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &-content {
        flex: 1;
        width: 100%;
        height: 77.5%;

        &-image-container {
            @include flex(row, center, center, nowrap);
            height: 40%;
            border-bottom: 1px solid #ccc;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-text-container {
            height: 60%;

            &-title {
                @include flex(row, flex-start, center, nowrap);
                height: 20%;
                width: 100%;

                p {
                    margin: 0;
                    font-family: NotoSans-Medium;
                    padding: var(--gap-10-px);
                }
            }

            &-text {
                @include flex(row, flex-start, flex-start, nowrap);
                height: 80%;
                width: 100%;
                padding: 0 var(--gap-10-px) var(--gap-10-px) var(--gap-10-px);
                @include boxSizing(border-box);
                overflow-y: auto;
            }
        }
    }

    &-footer {
        @include flex(row, flex-start, center, nowrap);
        background-color: rgba(0, 0, 0, 0.05);
        color: #6c757d;
        width: 100%;
        height: 10%;
        padding: 0 var(--gap-10-px);
        margin: 0;
        @include boxSizing(border-box);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &.large {
        width: 350px;
        min-height: 500px;
        height: 500px;

        header {
            font-size: var(--global-body-18-font-size);
        }

        footer {
            font-size: var(--global-body-14-font-size);
        }
    }

    &.medium {
        width: 300px;
        min-height: 450px;
        height: 450px;

        header {
            font-size: var(--global-body-16-font-size);
        }

        footer {
            font-size: var(--global-body-12-font-size);
        }
    }

    &.small {
        width: 250px;
        min-height: 400px;
        height: 400px;

        header {
            font-size: var(--global-body-14-font-size);
        }

        footer {
            font-size: var(--gap-10-px);
        }
    }
}