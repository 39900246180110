.contractOverviewContainer {
  @include flex(row, space-around, stretch, wrap);
  width: 100%;

  .leftcontractOverviewContainer {
    @include flex(column, space-between, stretch, wrap);
    width: 33%;
  }

  .dashboardCard {
    .dashboardCardMain {
      .logItem {
        padding-bottom: var(--gap-10-px);
        border-bottom: 1px solid map-get($colour-palette, open-divider);

        .headerTitle {
          color: #2c4874;
          margin-right: var(--gap-20-px);
          margin-bottom: 1px;
        }
      }
    }
  }

  #worknotesButton {
    width: 65%;
    margin-bottom: var(--gap-20-px);
  }

  div.small,
  div.medium {
    height: 400px;
  }

  .dashboardHeaderText {
    word-break: break-word;
  }

  #contractEscalations {
    .escalationItem {
      @include flex(column, space-between, nowrap);
      margin-top: var(--gap-20-px);
    }

    #escalationBtnContainer {
      @include flex(row, space-between, center, nowrap);
      margin-top: var(--gap-5-px);
    }
  }

  #workflowEvent {
    height: auto;
  }

  #publishedNotices {
    .noticeItem {
      margin-top: var(--gap-20-px);

      #noticeBtnContainer {
        @include flex(row, space-between, center, nowrap);
        margin-top: var(--gap-5-px);

        button:first-of-type {
          width: 102px;
        }
      }
    }
  }

  #projectActionContainer {
    @include flex(row, flex-end, center, nowrap);
    width: 100%;

    @media (max-width: $mobile-width) {
      @include flex(column, space-between, center, nowrap);
    }

    .cancelButtonContainer {
      @include flex(row, flex-start, center, nowrap);

      @media (max-width: $mobile-width) {
        @include flex(row, space-between, space-between, nowrap);
        padding-bottom: var(--gap-10-px);
      }

      .statuslabel-content {
        margin-right: var(--gap-10-px);
      }
    }

    .cancelButtonContainer>*:not(:last-child) {
      margin-right: var(--gap-10-px);
    }

    .moreActionContainer {
      @include flex(row, flex-end, center, nowrap);
      width: 100%;

      button {
        margin-left: var(--gap-30-px);
      }

      @media (max-width: $mobile-width) {
        @include flex(row, space-between, space-between, nowrap);
        padding-top: var(--gap-10-px);

        button {
          margin-left: 0;
        }
      }
    }
  }

  #projectActionContainer>*:not(:last-child) {
    margin-right: var(--gap-10-px);
  }
}

#pastCurrentUpcomingProgressBar {
  margin-bottom: var(--gap-30-px);
}

.contractActionContainer {
  @include flex(row, space-around, stretch, nowrap);
  width: 100%;

  .actionStats {
    //@include flex(column, space-around, center, nowrap);
    width: 100%;

    .actionStatsItem {
      margin: var(--gap-30-px) 0;
      text-align: center;

      p:last-of-type {
        font-size: var(--global-body-40-font-size);
        color: map-get($colour-palette, open-green);
      }
    }
  }

  .actionButtons {
    @include flex(column, space-around, center, nowrap);
    width: 100%;

    .button {
      height: 40px;
      width: 320px;
      left: 0px;
      top: 0px;
      border-radius: 5px;
      margin: 0rem;
      margin-top: var(--gap-15-px);
    }
  }
}

#extentForm {
  .modal-container .modal.medium {
    width: 100%;
    max-width: 525px;
    height: 100%;
    max-height: 650px;

    .sectionRow {
      @include flex(row, flex-start, flex-start, nowrap);
      margin-top: var(--gap-20-px);

      &:nth-child(3) {
        padding-bottom: var(--gap-20-px);
        border-bottom: 1px solid map-get($colour-palette, open-divider);
      }

      @media (max-width: $mobile-width) {
        flex-wrap: wrap;

        p {
          width: 100%;
          margin-left: 0;

          &:first-of-type {
            margin-bottom: var(--gap-10-px);
          }
        }
      }

      .sectionLabel {
        width: 100%;

        @media (min-width: $laptop-width) {
          max-width: 300px;
        }

        @media (min-width: $mobile-width) {
          max-width: 280px;
        }
      }

      .sectionValue {
        word-break: break-word;
        color: map-get($colour-palette, open-text-primary);
      }
    }
  }
}

#escalationModal {
  .escalationReason {
    margin-top: var(--gap-10-px);
  }
}

#addToSteeringGroupBtn {
  width: auto;
}

#actions {
  #contractActionContent {
    #worknoteContent>*:not(:last-child) {
      margin-bottom: var(--gap-10-px);
    }

    #correspondenceContent>*:not(:last-child) {
      margin-bottom: var(--gap-10-px);
    }

    #worknoteContent {
      margin-top: var(--gap-20-px);
      margin-bottom: var(--gap-20-px);
    }

    #correspondenceContent {
      margin-top: var(--gap-20-px);

      &>*:not(:last-child) {
        margin-bottom: var(--gap-10-px);
      }

      &.unread {
        border-radius: 5px;
        padding: var(--gap-10-px);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 5px 0px 0px map-get($colour-palette, open-text-error);
      }
    }

    .upFormInputContainer {
      width: 100%;
    }
  }
}

#contractOverview {
  @include flex(row, space-around, stretch, wrap);
  width: 100%;
}

#contractOverview.new-contractOverview {

  #contractDetailsContainer {
    @include flex(row, center, flex-start, wrap, var(--gap-10-px));
    flex-direction: column;

    & .contractDetailsItem {
      @include flex(row, flex-start, flex-start, nowrap, var(--gap-20-px));
      margin: 0;
      width: 100%;

      @media (max-width: $mobile-width) {
        flex-wrap: wrap;

        p {
          width: 100%;

        }
      }
    }
  }

  .sectionRow {
    @include flex(row, flex-start, flex-start, nowrap, var(--gap-20-px));
    margin: 0;
    width: 100%;

    @media (max-width: $mobile-width) {
      flex-wrap: wrap;

      p {
        width: 100%;

      }
    }


  }

  & .event-section-container.new-event-section-container {

    & .event-section-card {
      border-radius: 0;
      padding: var(--gap-20-px);
      border: 1px solid map-get($colour-palette, n-card-border);
      border-radius: 5px;
      box-shadow: none;
      background: #fff;
      margin: 0;
      display: block;


      &.started {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-started);
      }

      &.pending {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-not-started);
      }

      &.awaitingPublish {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-awaiting-publish);
      }

      &.completed {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-completed);
      }

      &.closed {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-closed);
      }

      &.published {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-published);
      }

      & .statuslabel-content {
        padding: var(--gap-5-px) var(--gap-10-px);

        &.amber {
          color: map-get($colour-palette, n-status-red);
          border: 1px solid map-get($colour-palette, n-status-red);

        }

        &.started {
          border: 1px solid map-get($colour-palette, n-open-event-status-started);
          background-color: rgba(map-get($colour-palette, n-open-event-status-started), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-started);
            font-weight: 600;
          }
        }

        &.pending {
          border: 1px solid map-get($colour-palette, n-open-event-status-not-started);
          background-color: rgba(map-get($colour-palette, n-open-event-status-not-started), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-not-started);
            font-weight: 600;
          }
        }

        &.awaitingPublish {
          border: 1px solid map-get($colour-palette, n-open-event-status-awaiting-publish);
          background-color: rgba(map-get($colour-palette, n-open-event-status-awaiting-publish), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-awaiting-publish);
            font-weight: 600;
          }
        }

        &.completed {
          border: 1px solid map-get($colour-palette, n-open-event-status-completed);
          background-color: rgba(map-get($colour-palette, n-open-event-status-completed), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-completed);
            font-weight: 600;
          }
        }

        &.closed {
          border: 1px solid map-get($colour-palette, n-open-event-status-closed);
          background-color: rgba(map-get($colour-palette, n-open-event-status-closed), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-closed);
            font-weight: 600;
          }
        }

        &.published {
          border: 1px solid map-get($colour-palette, n-open-event-status-published);
          background-color: rgba(map-get($colour-palette, n-open-event-status-published), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-published);
            font-weight: 600;
          }
        }
      }
    }

    & .event-section-content {
      margin: 0;

      & .success,
      & .warning {
        color: map-get($colour-palette, open-text-primary);
      }

    }

    .event-section-card .event-section-header .event-section-header-button {
      background: none;
    }

    & .button.primary.skinless.red.danger {
      background-color: map-get($colour-palette, n-status-red);
      color: map-get($colour-palette, open-white);

      &:hover,
      &:focus-visible {
        outline: var(--primary-button-outline-hover);
        background: map-get($colour-palette, n-status-red);
      }

      &:active {
        box-shadow: var(--buttons-box-shadow);
      }

    }

    & .button.primary.skinless.white.complete-event {
      background-color: var(--primary-button-background);
      color: map-get($colour-palette, open-white);
      border: none;

      &:hover,
      &:focus-visible {
        outline: var(--primary-button-outline-hover);
        background: var(--primary-button-background-hover);
      }

      &:active {
        box-shadow: var(--buttons-box-shadow);
      }

    }

    .event-section-button {
      margin-top: var(--gap-20-px)
    }
  }

  & .dashboardCard {
    background: map-get($colour-palette, n-open-light-grey);

    & .dashboardCardMain {
      @include flex(column, normal, normal, nowrap, var(--gap-20-px));
    }
  }

  & .statuslabel-txt.body {
    font-weight: 600;
  }


  & .tag-txt.body {
    font-size: var(--global-body-12-font-size);
  }

  #contractDetailsContainer {
    & .title {

      border-bottom: 1px solid map-get($colour-palette , n-open-border-bottom);
    }

    &>div,
    & .upFormInputContainer {
      & .formLabel .title {

        border-bottom: 1px solid map-get($colour-palette , n-open-border-bottom);
      }
    }

  }

  & .title {
    font-weight: 530;
  }


  & .contractOverviewContainer .sectionContent .sectionRow .sectionLabel {
    border-bottom: 1px solid map-get($colour-palette, n-open-border-bottom);
  }

  & .steeringGroupItem {
    width: 100%;

    & .title,
    & .projectOverviewContainer .sectionContent .sectionRow .sectionLabel {
      border-bottom: 1px solid map-get($colour-palette, n-open-border-bottom);
    }
  }


  .new-button-container {
    @include flex(column, center, flex-end, wrap, var(--gap-10-px));
    margin-bottom: var(--gap-20-px);

    & .button-group {
      @include flex(row, flex-start, center, nowrap, var(--gap-10-px));
    }

    & .button {
      height: 30px;
      width: fit-content;

      &.complete-event {
        width: 8.55rem;
      }
    }
  }
}