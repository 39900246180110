.coLotItem {
    @include flex(column, center, center, nowrap);
    width: 100%;

    .caption {
        align-self: flex-start;
        font-size: var(--global-body-16-font-size);
        font-weight: bold;
        color: var(--header-background-color);
    }

    margin: var(--gap-5-px) 0 var(--gap-q0-px) 0;
}

.coSupplierItem {
    @include flex(column, space-between, space-between, nowrap);
    width: 100%;
    margin-top: var(--gap-5-px);

    .coSupplierButtonContainer {
        @include flex(row, space-between, space-between, wrap);
        margin-top: var(--gap-10-px);

        .button {
            height: 30px;
            width: 100px;
            border-radius: 5px;
            font-size: var(--global-body-14-font-size);
        }

        .button:first-of-type {
            width: 110px;
            max-width: 110px;
        }
    }
}