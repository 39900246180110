.labelled-select {
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;

    .form-label {
        margin-bottom: 1rem;
        font-weight: var(--input-label-font-weight);
        color: var(--input-label-color);
    }

    .form-select {
        width: 100%;
        max-width: 350px;
        height: 40px;
        border-radius: 5px;
        border: var(--select-border);
        padding: 0 5rem 0 1rem;
        background-image: url("../../styles/icons/grey/dropdown.svg");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .form-select option:disabled {
        color: gray;
    }
}