.MuiButtonBase-root.menu-button {
    width: fit-content;
    font-family: var(--font-family);
    text-transform: none;
    padding-left: var(--gap-10-px);
    padding-right: var(--gap-10-px);
    letter-spacing: normal;
    font-weight: 530;

    &::after {
        margin-left: 10px;
        margin-top: -2px;
        content: url(../../styles/icons/blue/arrow-down.svg);
    }
}

.menu-button-links {
    & li {
        font-family: var(--font-family);
        font-weight: var(--font-weight);

        &::before {
            margin-top: 5px;
            margin-right: 10px;
        }

        &.icon-publish::before,
        &.icon-start-event::before {
            content: url(../../styles/icons/blue/confirm.svg);
        }

        &.icon-manage-questionnaire::before {
            content: url(../../styles/icons/blue/manage.svg);
        }

        &.icon-edit-opportunity::before {
            content: url(../../styles/icons/blue/edit.svg);
        }

        &.icon-abort-publication::before {
            content: url(../../styles/icons/red/abort.svg);
        }

        &.icon-abort-publication {
            color: map-get($colour-palette, n-status-red);
        }

        &.icon-escalate {
            color: map-get($colour-palette, n-open-blue);
        }

        &.icon-escalate::before {
            content: url(../../styles/icons/blue/escalate.svg);
        }

        &.icon-manage-suppliers::before {
            content: url(../../styles/icons/blue/supplier.svg);
        }

        &.icon-evaluation-summary::before,
        &.icon-award-summary::before {
            content: url(../../styles/icons/blue/evaluation.svg);
        }

    }
}