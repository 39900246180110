@media not print {
    .show-on-print {
        visibility: hidden !important;
    }

    .render-on-print {
        display: none !important;
    }
}

@media print {
    .hide-on-print {
        visibility: hidden !important;
    }

    .remove-on-print {
        display: none !important;
    }

    button {
        display: none !important;
    }

    .mainContent {
        margin: 0 !important;
        padding: 0 !important;
    }

    // To prevent text being chopped in half when printing,
    //Everything that's display flex and is not critical should be block when printing
    div.labelled-textarea,
    div.answerInput,
    div.answerInputWrapper,
    div.sectionItemMainContentRow,
    div.sectionItemMainContent,
    main.contentSectionMain,
    div.contentSectionRow,
    main.mainContent,
    div#root,
    .sectionItemMainContentRow,
    .headerTextRowWarpper,
    .labelled-radio-button,
    .labelled-radio,
    .form-radio-button-container,
    .labelled-checkBox {
        display: block !important;
    }

    .files-list {
        margin-bottom: var(--gap-10-px);
    }

    .answerInputWrapper .answerInput .checkBox-label {
        margin-bottom: var(--gap-5-px) !important;
    }

    .labelled-checkBox .form-checkBox-container input[type=checkbox]+label::after {
        display: none !important;
    }

    .labelled-checkBox .form-checkBox-container input[type=checkbox]+label::before {
        border: 1px solid #2c4874 !important;
        width: var(--gap-20-px) !important;
        height: var(--gap-20-px) !important;
        color: #2c4874;
        line-height: var(--gap-20-px);
        font-size: var(--global-body-20-font-size);
        text-align: center;
        margin-top: -0.35rem;
    }

    .labelled-checkBox .form-checkBox-container input[type=checkbox]:checked+label::before {
        content: "✔";
    }


    .labelled-radio-button .labelled-radio .form-radio-button-container input[type="radio"]+label::after {
        display: none !important;
    }

    .labelled-radio-button .labelled-radio .form-radio-button-container input[type="radio"]+label::before {
        border: 1px solid #2c4874 !important;
        width: var(--gap-20-px) !important;
        height: var(--gap-20-px) !important;
        border-radius: var(--gap-20-px);
        color: #2c4874;
        line-height: var(--gap-20-px);
        font-size: var(--global-body-20-font-size);
        text-align: center;
    }

    .labelled-radio-button .labelled-radio .form-radio-button-container input[type="radio"]:checked+label::before {
        content: "⬤";
        font-size: var(--global-body-18-font-size);
    }

    .files-list {
        font-family: var(--font-family);
        margin-bottom: var(--gap-10-px);
        padding-top: var(--gap-5-px);
        padding-left: var(--gap-10-px);
        font-size: var(--global-body-14-font-size);

        & li {
            list-style-type: none;
            padding: var(--gap-5-px);
        }
    }

    .labelled-radio-button .labelled-radio .form-radio-button-container input[type="radio"]+.form-label {
        line-height: 2.4rem;
        margin: 0.3rem 0 0.7rem 1rem;
    }

    .title-information {
        font-weight: bold;
        font-family: var(--font-family);
        font-size: var(--global-body-20-font-size);
        line-height: normal;
    }

    //Less space between sections
    .answerInputWrapper {
        margin-bottom: var(--gap-5-px);
        padding-bottom: var(--gap-5-px);
    }

    #contentSections .contentSectionRow .contentSection {
        padding: 0 var(--gap-20-px) !important;
    }

    .contentSection .contentSectionMain,
    .labelled-textarea .form-textarea {
        min-height: initial !important;
    }

    .labelled-textarea {
        margin-bottom: var(--gap-5-px);
    }

    .contentSectionHeader {
        padding-bottom: var(--gap-10-px) !important;
    }

    .contentSectionMain {
        padding-top: var(--gap-10-px) !important;
    }

    .sectionItemMainContentRow:last-child {
        margin-bottom: -var(--gap-10-px) !important;
        padding-bottom: 0 !important;
    }
}