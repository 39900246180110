#lotOverviewContainer {
  width: 100%;
  margin-top: var(--gap-30-px);

  #awardedSuppliers {
    width: 100%;

    .supplierItem {
      @include flex(row, space-between, center, nowrap);

      .supplierDetails {
        @include flex(column, flex-start, flex-start, nowrap);
        width: 100%;
      }
    }

    .supplierItem:not(:last-child) {
      margin-bottom: var(--gap-10-px);
    }
  }
}

#lotSection {
  #lotFormContent {
    #title-input {
      width: 50%;

      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }

    .radio-button-group {
      @media (max-width: $mobile-width) {
        @include flex(column, flex-start, start, nowrap);
      }
    }

    .labelled-textarea {
      width: 100%;

      #description-textarea {
        width: 100%;
      }
    }
  }
}

#lotSectionContent {
  border-top: 1px solid #ebebeb;
  padding: var(--gap-10-px) 0;
  width: 100%;

  .textIconContainer {
    .sectionContentTags {
      @include flex(row, flex-start, start, wrap);

      .tag-content.large {
        margin: 0 var(--gap-10-px) var(--gap-10-px) 0;
      }
    }
  }
}

.sectionContent {
  padding: var(--gap-20-px) var(--gap-20-px);
  border: none;
  width: 100%;
  word-break: break-word;

  @media (max-width: $mobile-width) {
    padding-bottom: var(--gap-10-px);
  }

  .sectionRow {
    @include flex(row, flex-start, flex-start, nowrap);
    margin-top: var(--gap-20-px);

    @media (max-width: $mobile-width) {
      flex-wrap: wrap;

      p {
        width: 100%;
        margin-left: 0;

        &:first-of-type {
          margin-bottom: var(--gap-10-px);
        }
      }
    }

    .sectionLabel {
      width: 100%;

      @media (min-width: $laptop-width) {
        max-width: 250px;
      }

      @media (min-width: $mobile-width) {
        max-width: 230px;
      }
    }

    .sectionValue {
      width: 100%;
      word-break: break-word;
      color: map-get($colour-palette, open-text-primary);
    }
  }
}

.lfModal {
  p {
    margin-bottom: var(--gap-10-px);
  }

  .lf-margin-top {
    margin-top: var(--gap-20-px);
  }
}