.guidanceBannerContainer {
  width: 100%;
  border: solid 1px white;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 1%;
  height: 100%;

  .bold {
    font-weight: bold;
  }

  .guidanceBannerCard {
    @include flex(row, center, stretch, nowrap);
    border-bottom: 2px solid var(--header-background-color);
    border-radius: 11px 0 0 11px;
    height: 100%;

    &.warning {
      border-bottom: 2px solid map-get($colour-palette, open-amber);
    }

    &.small {
      border: 1px solid var(--header-background-color);
      border-radius: 13px;
    }

    &.red {
      border-bottom: 2px solid map-get($colour-palette, open-red);
    }

    &.green {
      border-bottom: 2px solid map-get($colour-palette, open-text-success);
    }

    .guidanceBannerIcon {
      @include flex(row, center, center, nowrap);
      background-color: var(--header-background-color);
      padding: 0 32px;
      flex-shrink: 0;
      border-radius: 10px 0 0 10px;

      &.warning {
        background-color: map-get($colour-palette, open-amber);
      }

      &.small {
        padding: 16px;
        margin-left: -1px;
      }

      &.red {
        background-color: map-get($colour-palette, open-red);
      }

      &.green {
        background-color: map-get($colour-palette, open-text-success);
      }

      .infoIcon {
        display: block;
      }
    }

    .guidanceBannerContent {
      @include flex(row, space-between, center, wrap);
      flex-grow: 3;
      margin: var(--gap-10-px);
      min-height: 100px;

      .showText {
        @include flex(column, space-evenly, flex-start, wrap);
        color: var(--header-background-color);

        h4 {
          margin-bottom: 5px;
        }
      }

      .showLabel {
        @include flex(row, center, center, nowrap);

        @media (max-width: $tablet-width) {
          margin: var(--gap-10-px);
        }
      }

      .showButton {
        @include flex(row, center, center, wrap, var(--gap-20-px));
        margin-top: var(--gap-10-px);

        @media (max-width: $tablet-width) {
          @include flex(row, space-between, center, nowrap);
        }

        @media (max-width: $mobile-width) {
          @include flex(column, space-between, center, nowrap);
        }

        #second-action-btn,
        #primary-action-btn {
          margin-bottom: var(--gap-10-px);
          white-space: nowrap;
        }
      }
    }
  }

  #showProfile {
    img {
      width: 100%;
      height: auto;
    }
  }
}