.rbc-calendar {
    width: 100%;
    min-height: 800px;
    height: 100%;
    @include boxSizing(border-box);
    padding: var(--gap-40-px);
    background-color: map-get($colour-palette, open-white);
    font-family: var(--font-family);
    font-size: var(--global-body-16-font-size);
    font-style: normal;
    color: map-get($colour-palette, open-text-primary);
    border-radius: 10px;

    @media (max-width: $tablet-width) {
        padding: var(--gap-30-px);
        font-size: var(--global-body-14-font-size);
        min-height: 700px;
    }

    @media (max-width: $mobile-width) {
        padding: var(--gap-5-px);
        min-height: 600px;
    }

    .rbc-date-cell {
        padding-top: var(--gap-10-px);
        padding-right: var(--gap-10-px);
    }

    .rbc-timeslot-group {
        border: 1px solid map-get($colour-palette, open-footer-border);
    }

    .rbc-day-bg {
        border-left: 1px solid map-get($colour-palette, open-footer-border);
        border-bottom: 1px solid map-get($colour-palette, open-footer-border);
        border-right: 1px solid map-get($colour-palette, open-footer-border);
    }

    .rbc-month-view {
        margin-top: var(--gap-10-px);
    }

    .rbc-month-row {
        z-index: 1;
    }

    .rbc-header {
        border: 1px solid map-get($colour-palette, open-footer-border);
        padding: var(--gap-10-px);
        font-family: var(--font-family);

        span {
            font-size: var(--global-body-18-font-size);
        }
    }

    .rbc-row.rbc-time-header-cell .rbc-header {
        padding-bottom: var(--gap-30-px);
    }

    .rbc-row-segment {
        margin: var(--gap-5-px) 0;
    }

    .rbc-toolbar {
        &-btn-group {
            @media (max-width: $tablet-width) {
                width: 100%;
            }

            @media (max-width: $mobile-width) {
                width: 100%;
            }
        }

        &-label {
            font-family: var(--font-family);
            font-size: var(--global-body-20-font-size);
            padding: var(--gap-10-px);

            @media (max-width: $tablet-width) {
                width: 100%;
            }

            @media (max-width: $mobile-width) {
                width: 100%;
            }
        }

        button {
            width: 75px;
            padding: var(--gap-5-px);
            color: map-get($colour-palette, open-text-primary);
            border: 1.5px solid map-get($colour-palette, open-green);
            font-family: var(--font-family);
            font-size: var(--global-body-18-font-size);
        }

        button.rbc-active {
            background-color: map-get($colour-palette, open-green);
            color: map-get($colour-palette, open-white);
            border: 1.5px solid map-get($colour-palette, open-green);
        }
    }

    .rbc-event {
        background-color: map-get($colour-palette, open-green);
        border-left: 2px solid map-get($colour-palette, open-white);
    }

    .rbc-today {
        background-color: map-get($colour-palette, open-green-light);
    }

    .rbc-event-content {
        font-size: var(--global-body-16-font-size);
        font-family: var(--font-family);

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-14-font-size);
        }
    }

    .rbc-show-more {
        font-size: var(--global-body-16-font-size);
        font-family: var(--font-family);
        background-color: transparent;

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-14-font-size);
        }
    }

    .rbc-day-slot .rbc-event-label {
        font-family: var(--font-family);
    }

    .rbc-day-slot .rbc-background-event {
        color: map-get($colour-palette, open-white);
        background-color: map-get($colour-palette, open-green);
        border-radius: 3px;
    }
}

[data-theme="new-style"] {
    .rbc-calendar .rbc-event-content {
        font-family: var(--font-family);
        font-size: var(--global-body-14-font-size);
    }
}