#answerSummaryContainer {
    @include flex(row, space-around, stretch, wrap);
    width: 100%;

    #answerSummaryBanner {
        width: 100%;
        padding-bottom: var(--gap-20-px);
    }

    .answerSummaryContentContainer {
        width: 100%;

        & .MuiAccordion-container {
            width: 100%;
        }

        .showText {
            div:first-child {
                h4 {
                    margin-bottom: 28px;
                }
            }

            div:nth-child(2) {
                p {
                    margin-bottom: 28px;
                }
            }
        }

        .answerSummaryContent {
            width: 100%;

            .answerSummaryContentSection {
                @include flex(column, center, center, wrap);
                background-color: white;
                width: 100%;
                border-radius: 10px;
                border-radius: 10px;

                .answerSummaryLotTitle {
                    width: 98%;
                    padding: var(--gap-10-px);
                }

                .answerSummaryContentBody {
                    @include flex(row, space-between, center, nowrap);
                    background-color: white;
                    border-bottom: 1px solid lightgray;
                    width: 98%;
                    padding: var(--gap-10-px);

                    .answerSummaryQuestionTitle {
                        width: 70%;
                        padding: var(--gap-20-px);
                        font-weight: 700;
                    }

                    .answerSummaryBtnGroup {
                        @include flex(row, flex-end, center, nowrap);

                        padding: var(--gap-10-px);


                        @media (max-width: $mobile-width) {
                            width: auto;
                            @include flex(column, flex-end, center, wrap);
                        }

                        .btnSize {
                            height: 30px;
                            width: 35%;

                            @media (max-width: $mobile-width) {
                                width: auto;
                                margin-top: var(--gap-10-px);
                            }
                        }

                        #questionStatus-statuslabel {
                            margin-right: var(--gap-10-px);
                        }
                    }
                }
            }

            .answerSummaryActionsContainer {
                @include flex(row, space-between, center, wrap);
                background-color: white;
                margin-bottom: var(--gap-20-px);
                padding: var(--gap-20-px);
                border-radius: 10px;

                @media (max-width: $mobile-width) {
                    @include flex(column, flex-start, center, wrap);

                    button {
                        margin-top: var(--gap-10-px);
                        height: 30px;
                        max-width: 120px;
                        font-size: var(--global-body-16-font-size);
                    }
                }

                .answerSummaryBtnContainer {
                    @include flex(row, flex-end, center, nowrap, var(--gap-20-px));


                    @media (max-width: $mobile-width) {
                        @include flex(row, center, center, wrap);

                        .guidanceInfo {
                            text-align: center;
                        }

                        button {
                            height: 30px;
                            max-width: 120px;
                            font-size: var(--global-body-16-font-size);
                        }

                        * {
                            margin-top: var(--gap-10-px);
                            margin-left: 0;
                        }
                    }

                    .answerSummaryAdditionalBtnContainer {
                        @include flex(row, flex-end, center, nowrap, var(--gap-20-px));
                    }
                }
            }
        }
    }

    #answerSummaryNoLots {
        display: flex;
        flex-direction: column;
        background-color: white;
        justify-content: center;
        align-items: center;
        height: 500px;
        width: 100%;

        @media (max-width: $mobile-width) {
            height: 300px;
        }
    }

    #lotButton {
        padding: var(--gap-10-px);
    }

    #accordionContentBody {
        display: flex;
        justify-content: space-between;
        padding: var(--gap-20-px);
        align-items: center;
        border-bottom: 1px solid lightgray;

        .answerSummaryBtnGroup {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            gap: var(--gap-20-px);

            @media (max-width: $mobile-width) {
                @include flex(column, flex-end, center, wrap);
                width: auto;
            }

            .btnSize {
                height: 30px;
                width: 120px;
                margin-left: var(--gap-10-px);

                @media (max-width: $mobile-width) {
                    width: auto;
                    margin-top: var(--gap-10-px);
                    margin-left: 0rem;
                }
            }
        }

        .answerSummaryQuestionTitle {
            .title {
                @media (max-width: $mobile-width) {
                    word-break: break-word;
                }
            }
        }
    }

    #lotAccordion {
        .accordion-section {
            margin-bottom: var(--gap-20-px);
        }

        .accordion-section-header-data {
            border-bottom: 1px solid lightgray;
            padding: var(--gap-20-px) var(--gap-10-px) var(--gap-20-px) var(--gap-10-px);
        }

        .answerSummaryQuestionTitle {
            .title {
                @include flex(row, center, center, nowrap);
            }
        }
    }

    #share-icon {
        height: 20px;
        width: 20px;
        margin-right: var(--gap-10-px);
    }
}