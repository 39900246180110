#corespondenceSectionWrapper {
    width: 100%;

    #correspondenceSection {
        @include flex(column, flex-start, flex-start, nowrap);
        @include boxSizing(border-box);
        width: 100%;
        background-color: map-get($colour-palette, open-white);
        border-radius: 10px;
        border: 1px solid map-get($colour-palette, open-content-container-border);
        padding: var(--gap-20-px);
        word-break: break-word;

        #correspondenceHeader {
            @include flex(column, flex-start, flex-start, wrap);
            width: 100%;
            min-height: 50px;
            //  padding-bottom: var(--gap-15-px);
            border-bottom: 1px solid map-get($colour-palette, open-divider);

            #correspondenceSubjectBar {
                @include flex(row, space-between, center, wrap);
                width: 100%;
                // padding-bottom: var(--gap-15-px);
                // border-bottom: 1px solid map-get($colour-palette, open-divider);

                .title-xLarge {
                    color: var(--header-background-color);
                }
            }

            // #correspondenceSubjectContent,
            #correspondenceSubjectDetails {
                margin-bottom: var(--gap-15-px);
            }

            .vcButtonContainer {
                @include flex(row, flex-end, flex-end, nowrap);

                button {
                    margin-left: var(--gap-10-px);
                }
            }
        }

        #correspondenceSubjectContent {
            margin-top: var(--gap-15-px);

            #correspondenceContentButton {
                @include flex(row, center, center, wrap);
                margin-top: var(--gap-15-px);
                width: 100%;
            }
        }

        #threadContanier {
            width: 100%;
            padding: var(--gap-15-px) 0;

            .threadContentItem {
                margin-bottom: var(--gap-30-px);

                .threadMainContent {
                    border-radius: 5px;
                    padding: var(--gap-10-px);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                    // min-height: 100px;
                    &.marginBottomFromOther {
                        margin-bottom: var(--gap-20-px);
                    }

                    .threadHeader {
                        @include flex(row, space-between, center, nowrap);
                        // margin-bottom: var(--gap-10-px);

                        p {
                            font-weight: bold;
                        }

                        .threadHeaderItems {
                            @include flex(row, center, nowrap);

                            button {
                                margin-left: var(--gap-10-px);
                            }
                        }
                    }

                    .threadContent {
                        margin-bottom: var(--gap-10-px);
                    }

                    .threadButtonContanier {
                        margin-bottom: var(--gap-10-px);

                        .topicButtonContanier {
                            @include flex(row, flex-start, center, nowrap);

                            button:not(:last-child) {
                                margin-right: var(--gap-10-px);
                            }
                        }
                    }
                }

                .threadMessageContent {
                    // margin-left: var(--gap-40-px);
                    border-radius: 0 0 5px 5px;
                    overflow-y: auto;
                    // padding: var(--gap-10-px);
                    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

                    @media (max-width: $mobile-width) {
                        margin-left: 0rem;
                    }

                    .threadMessages {
                        margin: var(--gap-20-px);

                        .messageItem {
                            position: relative;
                            min-height: 100px;
                            margin-top: var(--gap-10-px);
                            border-radius: 5px;
                            padding: var(--gap-10-px);
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            word-break: break-word;

                            &.recipient {
                                background-color: map-get($colour-palette, open-light-grey);
                                margin-right: var(--gap-100-px);

                                .uploadContainer {
                                    background-color: map-get($colour-palette, open-light-grey);
                                }
                            }

                            &.sender {
                                margin-left: var(--gap-100-px);
                            }

                            .messageHeader {
                                @include flex(row, space-between, center, nowrap);
                                // margin-bottom: var(--gap-10-px);

                                p.title {
                                    font-weight: bold;
                                }
                            }

                            .messageContent {
                                margin-top: var(--gap-20-px);
                                word-wrap: break-word;
                            }

                            .messageDocuments {

                                // border-top: 1px solid map-get($colour-palette, open-divider);
                                .upFormInputContainer {
                                    .flex-break {
                                        height: 0;
                                        margin: 0;
                                    }
                                }
                            }

                            &:last-child {
                                margin-bottom: var(--gap-20-px);
                            }
                        }
                    }
                }
            }

            .threadResponse {
                margin-top: var(--gap-10-px);

                .threadResponseWarning {
                    @include flex(row, center, center, nowrap);
                    width: 100%;
                    margin-bottom: var(--gap-20-px);
                }

                .form-textarea {
                    width: 100%;
                }

                .upbuttonContainer {
                    @include flex(row, space-between, flex-end, nowrap);

                    .upFormInputContainer {
                        .flex-break {
                            height: 0;
                            margin: 0;
                        }
                    }

                    @media (max-width: $mobile-width) {
                        @include flex(column, center, center, nowrap);

                        #sendMessagebtn {
                            margin-top: var(--gap-10-px);
                        }
                    }
                }
            }

            #noThreads {
                @include flex(row, center, center, wrap);
            }
        }
    }
}

span.referenceValue {
    font-size: var(--global-body-16-font-size);
    font-weight: bold;
    margin-right: 6px;
}
span.referenceValue--large {
    font-size: var(--global-body-20-font-size);
}

.inlineTag {
    @include flex(row, center, center, wrap);
}

.newOrUnreadTimestampMargin {
    margin-left: var(--gap-30-px);
}

.headerThreads {
    width: 100%;
}

.borderClass {
    border: 1px solid #2c4874;
}

#createNewCorrespondence {
    max-width: 800px;

    textarea {
        width: 100%;
    }
}

#reply-all-button {
    margin-right: 5px;
}

.messageContent .caption {
    white-space: pre-line;
}

.labelled-radio-button {
    margin-bottom: 15px;
}