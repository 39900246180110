#selectLots {

  #endMessage {
    width: 100%;
    @include flex(row, center, center, nowrap);
  }

  .answerSummaryItems {
    margin-top: var(--gap-20-px);
  }

  .answerSummaryRowItems {
    border: 0.1rem solid #2c4874;
    border-radius: 5px;
    margin: var(--gap-10-px) 0;
    word-break: break-word;
  }

  .statuslabel-txt-container {
    text-transform: capitalize;
  }

  .answerSummaryLotSection {
    padding-top: var(--gap-20-px);

    .answerSummarySearchFilterPanel {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width:$mobile-width) {
        @include flex(column, space-between, center, wrap);
      }
    }
  }

  .answerSummarySectionTags {
    @include flex(row, flex-start, center, wrap);
  }

  // #checkAllCheckbox {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: row;


  //   label {
  //     width: 70%;
  //   }
  // }

  #search {
    width: 50%;

    @media (max-width:$mobile-width) {
      width: 100%;
    }
  }

  #checkAll {
    width: 30%;
    height: 25px;
  }

  #selectLabel {
    margin-bottom: var(--gap-5-px);
    color: var(--header-background-color);
  }
}