.bdContentSection {
    padding: var(--gap-40-px);
    align-items: flex-start;
}

.tc {
    &-container {
        margin: var(--gap-40-px);
    }

    &-title {
        margin-top: var(--gap-30-px);
    }

    &-content {
        margin-top: var(--gap-20-px);
    }

    &-list {
        display: grid;
        grid-template-columns: 4% auto;
    }

    &-sub-list {
        display: grid;
        grid-template-columns: 6% auto;
        margin-left: 45px;
    }

    &-table-list {
        display: grid;
        grid-template-columns: 6% auto;
    }

    &-title-align {
        text-align: center;
    }

    &-bold {
        font-weight: bold;
    }

    &-table-content {
        margin: 2px;
    }

    &-content-c {
        margin-left: 45px;
    }
}