#supplierOverView {
    @include flex(column, flex-start, unset, nowrap);
    width: 100%;

    .componentsContainer {
        @include flex(row, space-between, stretch, wrap, var(--gap-20-px));
        width: 100%;

        #requestOverviewCard {
            .dashboardCardMain {
                overflow: unset;
            }
        }
    }

    .sectionHeader {
        margin-bottom: 0rem;
    }

    .dashboardContainer {
        @include flex(row, space-between, center, wrap);
        width: 100%;
    }

    #supplierActions {
        height: auto;

        #supplierActionContainer {
            @include flex(row, flex-end, center, wrap);
        }
    }


    // #regionalOfficeOrHeadquartersCard {
    //     @include flex(column, space-between, center, wrap);

    //     .dashboardCard {
    //         @include flex(column, flex-start, flex-start, wrap);
    //         height: fit-content;
    //     }
    // }

    .dashboardContainer #actions .uploadContainer {
        margin-top: 0rem;
    }

    #riskStatusButton {
        margin-left: var(--gap-20-px);
    }

    #creditScoreButton {
        margin-left: var(--gap-20-px);
    }


    .department {
        margin-left: var(--gap-20-px);
    }

    .departmentContent {
        @include flex(column, space-between, stretch, wrap);
        width: 99%;
        margin-top: var(--gap-10-px);
    }

    .accountContent {
        @include flex(column, center, flex-start, nowrap);
        width: 98%;
        margin-top: var(--gap-10-px);

        .cardContentContainer {
            margin-top: var(--gap-20-px);
            width: 98.5%;

            .solutionAccessRequestItem {
                border: var(--input-border);
                border-radius: 5px;
                padding: var(--gap-10-px);
            }

            .button:first-of-type {
                margin-bottom: 0rem;
                width: auto;
            }
        }
    }

    .departmentRequestItem {
        margin-bottom: 0rem;
    }

    #noDepartmentContent {
        margin: var(--gap-20-px);
    }

    #bdAccordion {
        .accordion-section {
            margin-bottom: var(--gap-20-px);
            border: 1px solid var(--header-background-color);
            border-radius: 5px;

            &:last-child {
                margin-bottom: var(--gap-10-px);
            }

            @media (max-width: $tablet-width) {
                margin-bottom: var(--gap-10-px);
            }

            &-header {
                border-radius: 5px;
                padding: 0 var(--gap-20-px);

                img {
                    padding: 0;
                }

                @media (max-width: $tablet-width) {
                    padding: 0 var(--gap-10-px);
                }

                &.contentOpen {
                    border-radius: 5px 5px 0 0;
                }

                &-data {
                    pointer-events: none;
                    word-break: break-word;

                    p {
                        padding: var(--gap-10-px) 0;
                    }

                    .headerTitleContent {
                        @include flex(row, center, center, nowrap);

                        .share-icon {
                            height: 20px;
                            width: 20px;
                            margin-right: var(--gap-10-px);
                        }
                    }

                    .headerTitle {
                        margin-right: var(--gap-20-px);

                        @media (max-width: $tablet-width) {
                            margin-right: var(--gap-10-px);
                        }
                    }

                    .bdAccordionSectionHeaderAddon {
                        @include flex(row, center, center, nowrap);

                        .status-label:not(:last-child) {
                            margin-right: var(--gap-20-px);
                        }

                        .status-label {
                            min-width: 130px;
                            max-width: 250px;
                            background: map-get($colour-palette, open-white);
                            color: map-get($colour-palette, open-white);
                            border: 1px solid var(--header-background-color);
                            border-radius: 50px;

                            &.red {
                                color: map-get($colour-palette, open-red);
                                border: 1px solid map-get($colour-palette, open-red);
                            }

                            &.amber {
                                color: map-get($colour-palette, open-amber);
                                border: 1px solid map-get($colour-palette, open-amber);
                            }

                            &.green {
                                color: map-get($colour-palette, open-green);
                                border: 1px solid map-get($colour-palette, open-green);
                            }

                            &.primary {
                                background: map-get($colour-palette, open-blue-90);
                                border: 1px solid map-get($colour-palette, open-blue-90);
                            }
                        }

                        &.cancelled {
                            background-color: map-get($colour-palette, open-red);
                            border-radius: var(--gap-50-px);
                            margin: var(--gap-10-px);
                            width: 135px;
                            height: 25px;
                        }

                        &.projects {
                            background-color: map-get($colour-palette, open-green);
                            border-radius: var(--gap-50-px);
                            margin: var(--gap-10-px);
                            width: 135px;
                            height: 25px;
                        }

                        .label {
                            margin-right: var(--gap-10-px);

                            &.projects {
                                color: map-get($colour-palette, open-white);
                                padding: 0;
                                margin: 0 auto;
                            }
                        }

                        .headerTag {
                            color: map-get($colour-palette, open-grey);
                            background: map-get($colour-palette, open-footer-border);
                            min-width: 120px;
                            border-radius: 50px;
                            font-size: var(--global-body-16-font-size);
                            text-align: center;
                            margin: var(--gap-10-px) 0;

                            &.active {
                                color: map-get($colour-palette, open-white);
                                background: map-get($colour-palette, open-green);
                                padding: 0 10px;
                            }

                            @media (max-width: $mobile-width) {
                                width: 100px;
                                font-size: var(--global-body-14-font-size);
                            }
                        }
                    }
                }
            }

            &-content {
                &.open {
                    border-top: 1px solid var(--header-background-color);
                    border-radius: 0 0 5px 5px;
                    overflow-y: auto;
                    max-height: 18rem;
                }

                &-data {
                    @include flex(column, flex-start, space-evenly, nowrap);
                    @include boxSizing(border-box);

                    .bdAccordionSectionContentData {
                        margin: var(--gap-20-px) var(--gap-20-px) 0rem var(--gap-20-px);
                        word-break: break-word;
                    }
                }
            }
        }
    }

    .title-small,
    .title-xSmall {
        line-height: var(--gap-20-px);
    }
}

#supplierActionCard {
    #actions {
        .title {
            margin-bottom: var(--gap-20-px);
        }
    }
}



#supplierOverView.new-supplierOverview {
    & .componentsContainer {
        margin-bottom: var(--gap-10-px);
    }

    & .dashboardCard {
        background: map-get($colour-palette, n-open-light-grey);

        & .dashboardCardMain {
            @include flex(column, normal, normal, nowrap, var(--gap-20-px));
        }
    }


    #bdAccordion .accordion-section {

        border: var(--input-border);
    }

    #bdAccordion .accordion-section-content.open {
        border-top: var(--input-border);
    }

    #supplierActions #supplierActionContainer {
        padding: 3px;
    }
}