.flex-container {
    display: flex;
}

.description {
    margin-bottom: 10px;
}

.sbLegalViewContainer {
    @include flex(row, space-between, center, wrap);
    width: 100%;
    max-height: 50px;
    background-color: map-get($colour-palette, open-white);
    padding: var(--gap-20-px) 0;
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);

    .companyName {
        margin-left: var(--gap-20-px);
        color: var(--header-background-color);
    }

    .statusLabel {
        @include flex(row, space-between, center, wrap);

        .button {
            margin-left: var(--gap-20-px);
            min-width: max-content;
        }

        margin-right: var(--gap-20-px);
    }
}

.sbContentHeader {
    @include flex(row, flex-end, center, wrap);
    width: 100%;
    max-height: 50px;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    padding: var(--gap-20-px) 0;
    margin-bottom: var(--gap-30-px);

    #esHeaderLabel-statuslabel {
        margin-right: var(--gap-20-px);
    }

    .headerEvaluationContainer {
        @include flex(row, space-between, center, nowrap);
        width: 100%;
        margin-left: var(--gap-20-px);

        p {
            color: var(--header-background-color);
        }
    }

    .headerButtonContainer {
        @include flex(row, flex-end, center, nowrap);

        .button {
            margin-right: var(--gap-20-px);
            width: auto;
            min-width: 130px;
        }

        // .standstill-end-label {
        //     margin-right: var(--gap-20-px);
        // }
        #colorRed {
            margin-right: var(--gap-10-px);
            color: red;
        }

        #addPadding {
            margin-right: var(--gap-10-px);
        }
    }
}

.sbContentSection {
    @include flex(column, flex-start, center, nowrap);
    width: 100%;
    // background-color: map-get($colour-palette, open-white);
    // border-radius: 10px;
    // border: 1px solid map-get($colour-palette, open-content-container-border);
    // margin-bottom: var(--gap-20-px);
    padding: 0;
    @include boxSizing(border-box);
    flex: none;

    .sbContentBody {
        @include flex(column, flex-start, center, nowrap, var(--gap-20-px));
        width: 100%;
        // padding: var(--gap-10-px) 0;

        #sbAccordion,
        #sbAccordion_Supplier {
            width: 100%;
            max-width: 100%;
            margin-bottom: 0;

            .break {
                flex-basis: 100%;
                height: 0;
            }

            .timestamp-container {
                text-align: left;
            }

            .accordion-section-content {
                &.open {
                    max-height: none !important;
                }
            }

            .accordion-section {
                margin-bottom: var(--gap-30-px);
                border: none;

                .supplierLevel {
                    margin-bottom: var(--gap-20-px);
                    border-bottom: 2px solid map-get($colour-palette, open-divider);
                    overflow-y: auto;

                    .headerLevel {
                        padding-left: var(--gap-40-px);
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &-header {
                    border-radius: 5px;
                    padding: 0 var(--gap-20-px);
                    background-color: none;
                    height: 55px;

                    &.contentOpen {
                        border-radius: 5px 5px 0 0;
                    }

                    &-data {
                        pointer-events: none;

                        .headerTitle,
                        .headerLevel {
                            margin-right: var(--gap-20-px);
                            color: var(--header-background-color);
                        }

                        .left-align-header {
                            text-align: left;
                        }

                        .sbAccordionSectionHeaderAddon {
                            @include flex(row, space-between, center, nowrap);

                            .spacer,
                            .label {
                                padding: 0 var(--gap-10-px);
                            }

                            .label {
                                padding-right: var(--gap-10-px);
                                font-size: var(--global-body-14-font-size);
                                color: map-get($colour-palette, open-text-primary);
                            }

                            @media (max-width: $mobile-width) {
                                width: auto;
                            }

                            .button {
                                width: 125px;
                                pointer-events: all;
                            }
                        }
                    }
                }

                &-content {
                    &.open {
                        // margin-top: var(--gap-10-px);
                        border-top: 2px solid map-get($colour-palette, open-divider);
                        border-radius: 0 0 5px 5px;
                        overflow-y: auto;
                    }

                    &-data {
                        @include flex(column, flex-start, center, nowrap);
                        @include boxSizing(border-box);

                        .sbAccordionSectionContentRow {
                            @include flex(row, space-between, center, wrap);
                            width: 100%;

                            .sbAccordionSectionContentData {
                                @include flex(column, flex-start, flex-start, wrap);
                                padding: var(--gap-15-px) var(--gap-50-px);

                                .label {
                                    margin-right: var(--gap-10-px);
                                    color: map-get($colour-palette, open-text-primary);
                                    font-size: var(--global-body-14-font-size);
                                }

                                >p {
                                    padding-bottom: var(--gap-10-px);
                                }
                            }

                            .lotLevelAdditionalPadding {
                                padding-left: var(--gap-80-px);
                            }

                            .sbAccordionSectionContentAward {
                                @include flex(column, flex-start, center, nowrap);
                                width: 100%;
                                padding: var(--gap-10-px) var(--gap-20-px);

                                .sbAccordionSectionContentAwardData {
                                    @include flex(column, flex-start, flex-start, wrap);
                                    padding: var(--gap-15-px) var(--gap-20-px);
                                    width: 100%;

                                    .label {
                                        margin-right: var(--gap-10-px);
                                        color: map-get($colour-palette, open-text-primary);
                                        font-size: var(--global-body-14-font-size);
                                    }

                                    >p {
                                        padding-bottom: var(--gap-10-px);
                                    }

                                    .sbAccordionSectionContentDataAwardRow {
                                        @include flex(row, flex-start, center, nowrap);
                                        padding-bottom: var(--gap-15-px);

                                        >p {
                                            color: map-get($colour-palette, open-green);
                                        }

                                        .statuslabel-content {
                                            margin: 0 var(--gap-15-px);
                                        }

                                        .button {
                                            padding: 0 var(--gap-30-px);
                                            margin-right: var(--gap-30-px);
                                        }
                                    }

                                    .sbAccordionSectionContentDataUpload {
                                        @include flex(row, flex-start, center, nowrap);
                                        width: 100%;

                                        .uploadContainer {
                                            width: auto;
                                        }
                                    }
                                }

                                .sbAccordionSectionContentDataFooter {
                                    @include flex(row, flex-end, flex-end, nowrap);
                                    width: 100%;

                                    .button {
                                        padding: 0 var(--gap-30-px);
                                    }

                                    .button:first-child {
                                        margin-right: var(--gap-30-px);
                                    }
                                }
                            }

                            .sbAccordionSectionContentAddon {
                                @include flex(row, space-between, center, nowrap);
                                padding: var(--gap-15-px) var(--gap-20-px);

                                .label {
                                    padding: 0 var(--gap-10-px);
                                    font-size: var(--global-body-14-font-size);
                                    color: map-get($colour-palette, open-text-primary);
                                    min-width: max-content;
                                }

                                @media (max-width: $mobile-width) {
                                    width: auto;
                                }

                                .button {
                                    min-width: max-content;
                                    margin-left: var(--gap-10-px);
                                }
                            }

                            .splitter {
                                margin: 0 var(--gap-20-px);
                                width: 98%;
                                border-bottom: 2px solid map-get($colour-palette, open-divider);
                            }
                        }

                        .intentStatusButtonContainer {
                            @include flex(row, flex-end, center, nowrap);
                            width: 100%;
                            margin: var(--gap-10-px);

                            .button:last-child {
                                margin-right: var(--gap-20-px);
                                margin-left: var(--gap-10-px);
                            }
                        }
                    }
                }
            }
        }
    }

    .sbAccordionSectionContentDataUpload {
        padding-top: var(--gap-20-px);
        width: 100%;
        @include flex(row, flex-start, center, nowrap);
        margin-left: var(--gap-50-px);

        .sbAccordionSectionContentDataAwardRow {
            width: 100%;
            margin-bottom: var(--gap-20-px);
            @include flex(column, flex-start, flex-start, wrap);

            div:not(:last-child) {
                margin-bottom: var(--gap-10-px);
            }

            .flex-break {
                flex-basis: 100%;
                height: 0;
                margin: 0 0;
            }

            .sbAccordionSectionContentDataAwardRowDPS {
                width: 100%;
                @include flex(row, flex-start, flex-start, wrap, var(--gap-20-px));

                .button:not(:last-child) {
                    margin-right: var(--gap-20-px);
                }
            }

            .button {
                min-width: max-content;
            }
        }
    }

    .sbAccordionSectionContentDataUploadProject {
        width: 100%;
        @include flex(row, flex-start, center, nowrap);
        border-top: 2px solid #ebebeb;
        border-radius: 0 0 5px 5px;
        overflow-y: auto;
        background-color: white;

        .marginContainer {
            width: 100%;
        }

        .sbAccordionSectionContentDataAwardRowProject {
            width: 100%;
            margin-bottom: var(--gap-20-px);
            @include flex(column, flex-start, flex-start, wrap);

            div:not(:last-child) {
                margin-bottom: var(--gap-10-px);
            }

            .uploadContainer .upFormInputContainer {
                padding: var(--gap-20-px) var(--gap-20-px) 0rem var(--gap-20-px);

                .flex-break {
                    flex-basis: 100%;
                    height: 0px;
                    margin: 0;
                }
            }

            .statuslabel-content {
                margin: var(--gap-20-px);
            }
        }
    }
}

.standstill-container {
    .labelled-radio-button {
        margin-bottom: var(--gap-20-px);
    }

    .standstill-form-item {
        margin-top: var(--gap-20-px);
    }

    .standstill-form-label {
        margin-bottom: var(--gap-10-px);
    }

    .standstill-date-input {
        width: 250px;
        max-width: 250px;
    }

    .formateDate {
        white-space: nowrap;
    }

    .optional {
        color: gray;
    }
}

.disqualifySuppliersModal {
    .disqualifySelect {
        margin-top: var(--gap-10-px);
        margin-bottom: var(--gap-20-px);

        p {
            margin-bottom: var(--gap-10-px);
        }
    }

    .disqualifyItem {
        margin-bottom: var(--gap-10-px);

        .supplierTitle {
            margin-top: var(--gap-10-px);
        }

        p {
            margin-bottom: var(--gap-10-px);
        }

        #reason-textarea {
            width: 100%;
            height: 130px;
        }

        .inputContainer {
            @include flex(row, space-between, center, wrap);

            .labelled-input {
                flex-grow: 2;
                margin-right: var(--gap-10-px);
            }
        }
    }
}

#sendAwardModal {
    .button {
        margin-top: var(--gap-10-px);
        min-width: fit-content;
    }
}

[data-theme="new-style"] {
    .sbAccordionSectionHeaderAddon {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 30px;
        gap: 20px;
    }

    .sbAccordionSectionContentRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        gap: 20px;
    }

    .sbAccordionSectionContentAddon {
        display: flex;
        align-items: center;
        gap: 20px;

        & #disqualifySupplier,
        & #submitForApproval {
            width: fit-content;
        }
    }

    .intentStatusButtonContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        gap: 20px;
    }

    .sbContentHeader .headerButtonContainer .button {
        width: fit-content;
        min-width: fit-content;
        padding-left: var(--gap-20-px);
        padding-right: var(--gap-20-px);
    }

    .lot-title {
        color: #2c4874;
        flex-wrap: wrap;
        font-size: var(--global-body-18-font-size);
        font-weight: 700;
    }

    .sbContentSection .user-icon {
        color: map-get($colour-palette, open-text-primary);

        & img {
            height: 15px;
            margin-right: 5px;
            width: 15px;
        }
    }
}