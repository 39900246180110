.recipients-container {
  margin-top: 10px;

  .recipients {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  #seeMoreSeeLess .caption {
    font-family: var(--font-family);
    font-size: var(--global-body-12-font-size);
    font-style: italic;
    color: #999;
  }
}