.worknote-item {
    @include flex(column, space-between, initial, nowrap);

    .worknote-item-content {
        justify-content: flex-start;
    }

    .buttonContainer {
        @include flex(column, space-between, initial, nowrap);

        .button:not(:last-child) {
            margin-bottom: var(--gap-10-px);
        }
    }

    .buttonDivider {
        width: var(--gap-20-px);
    }

    .worknote-item-row {
        @include flex(row, space-between, initial, nowrap);
        margin-top: var(--gap-10-px);
        width: 100%;
    }
}

.av-status-message {
    font-style: italic;
}