.dashboardContainer {
  @include flex(row, space-between, stretch, nowrap, var(--gap-20-px));
  width: 100%;


  @media (max-width: $tablet-width) {
    @include flex(row, space-between, stretch, wrap);

  }

  @media (max-width: $mobile-width) {
    @include flex(row, space-between, stretch, wrap);

  }

  .addProject {
    width: auto;
  }

  #selection-questionnaire {
    .overview-body {
      p {
        margin-bottom: var(--gap-10-px);
      }
    }
  }

  #actions {
    #dashboardActionContainer {
      margin-top: var(--gap-20-px);

      #dashboardActionWorknotes>*:not(:last-child) {
        margin-bottom: var(--gap-10-px);
      }

      #dashboardActionWorknotes {
        margin-bottom: var(--gap-20-px);

        & .buttonContainer {
          margin: 0 3px;
        }
      }
    }

    .uploadContainer {

      .upFormInputContainer {
        width: 100%;
        max-width: 100%;

        .flex-break {
          margin: 0;
          height: 0;
        }

        .upDocumentButtonContainer {
          padding: 0 3px;
          margin: 0;
        }
      }

      .upFormInputContainer>*:not(:last-child) {
        margin-bottom: var(--gap-10-px);
      }
    }
  }
}

#supplierPendingApproval {
  @include flex(column, flex-start, center, nowrap);
  width: 100%;
  flex: 1;
  border-radius: 10px;
  background-color: map-get($colour-palette, open-white);
  border: 1px solid map-get($colour-palette, open-content-container-border);
  padding-bottom: var(--gap-40-px);

  #boError {
    margin: var(--gap-40-px) 0 0 0;
  }
}

.headerTitle {
  color: var(--header-background-color);
}

.cardContentContainer {
  @include flex(column, space-between, space-between, nowrap);
  width: 100%;
  max-height: 520px;
  padding-bottom: var(--gap-10-px);
  box-sizing: border-box;

  @media (max-width: $mobile-width) {
    padding-bottom: var(--gap-15-px);
  }

  .eventItem {
    @include flex(row, flex-start, flex-start, nowrap, var(--gap-10-px));
    margin-bottom: var(--gap-10-px);
    max-width: 360px;
    width: 100%;

    .leftColumn {
      width: 22%;
      margin-right: 0.2rem;
      word-break: break-word;

      p {
        color: map-get($colour-palette, open-grey);
        margin-top: var(--gap-5-px);
        font-family: var(--font-family);
      }
    }

    .middleColumn {
      width: 48%;

      .eventHeader {
        @include flex(row, space-between, center, nowrap);
        width: 100%;
        padding-bottom: var(--gap-10-px);
        word-break: break-word;

        .eventStatus {
          color: map-get($colour-palette, open-grey);
          text-align: right;
        }
      }

      .eventContent p {
        line-height: var(--line-height);
      }
    }

    .rightColumn {
      width: 30%;

      .statusText {
        @include flex(column, space-around, center, nowrap);
        height: 25px;
        min-width: 100px;
        border-radius: 50px;
        color: map-get($colour-palette, open-white);

      }

    }

    #Overdue {
      background-color: #da6a6a;
    }

    #In-progress {
      background-color: #f7d08e;
    }

    #Pending {
      background-color: #78cbc3;
    }

    #Approve {
      width: 100px;
      height: 30px;
      font-size: var(--global-body-14-font-size);
    }

    #Approve:first-of-type {
      margin-bottom: var(--gap-10-px);
    }
  }

  .eventItem:last-of-type {
    margin-bottom: 0;
  }

  .submitRequestItem {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    margin-bottom: var(--gap-20-px);

    .button {
      height: 30px;
      width: 100px;
      border-radius: 5px;
    }
  }

  .submitRequestItem:last-of-type {
    margin-bottom: 0;
  }

  .approvalItem {
    @include flex(column, space-between, space-between, nowrap);
    width: 100%;
    margin: var(--gap-10-px) 0;

    .button {
      width: 100%;
      max-width: 200px;
      font-size: var(--global-body-14-font-size);
      height: 30px;
      margin-top: var(--gap-5-px);
    }
  }

  .approvalItem:last-of-type {
    margin-bottom: 0;
  }

  .solutionAccessRequestItem {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    margin-bottom: var(--gap-20-px);

    .button {
      height: 30px;
      width: 100px;
      border-radius: 5px;
    }

    .button:first-of-type {
      margin-bottom: var(--gap-10-px);
    }
  }

  .escalationItem {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    margin: var(--gap-10-px) 0;

    .caption {
      margin-top: var(--gap-5-px);
    }

    .caption-small {
      text-align: right;
    }

    .button {
      height: 30px;
      width: 100px;
      font-size: var(--global-body-14-font-size);
      margin-top: var(--gap-5-px);
    }
  }

  .escalationItem:last-of-type {
    margin-bottom: 0;
  }

  .requestItem {
    @include flex(column, space-between, space-between, nowrap);
    margin: var(--gap-10-px) 0;

    .requestButtons {
      @include flex(row, space-between, space-between, nowrap);
      margin-top: var(--gap-10-px);

      .button {
        height: 30px;
        max-width: 100px;
        font-size: var(--global-body-14-font-size);
      }

      .button:first-of-type {
        width: 120px;
        max-width: 120px;
        margin-right: 10%;
      }

      .button:last-of-type {
        margin-left: var(--gap-10-px);
      }

      @media (max-width: $tablet-width) {
        @include flex(column, space-between, flex-end, nowrap);

        .button:first-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

#openRequestContentCard,
#openUserRequestContentCard {
  .accountAccessRequestItem {
    @include flex(column, space-between, center, nowrap);
    width: 100%;
    margin-bottom: var(--gap-20-px);

    .userDetails {
      width: 100%;
      margin-bottom: var(--gap-10-px);
    }

    .actionsButtonsContainer {
      width: 100%;
      @include flex(row, space-between, center, nowrap);

      .button {
        height: 30px;
        width: 100px;
        border-radius: 5px;
      }

      .button:first-of-type {
        margin-right: var(--gap-10-px);
      }
    }
  }
}


.new-dashboardContainer {
  & .dashboardCard {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: none;
    margin-left: auto;
    background: map-get($colour-palette, n-open-light-grey);
  }

}

.dashboardContainer.new-dashboardContainer {

  & #actions #dashboardActionContainer #dashboardActionWorknotes .sectionLabel {
    color: map-get($colour-palette, n-open-blue);
  }

  & .uploadContainer {
    background-color: map-get($colour-palette, n-open-light-grey);

    & .upFormInputContainer .formLabel .title {
      color: map-get($colour-palette, n-open-blue);
    }
  }

  & .cardContentContainer .eventItem #Pending.pill-yellow {
    background-color: map-get($colour-palette, n-status-yellow-fill);
    border: 1px solid map-get($colour-palette, n-status-yellow);
    color: map-get($colour-palette, n-status-yellow);
  }

  & .cardContentContainer .eventItem #In-progress.pill-green {
    background-color: rgba(map-get($colour-palette, n-status-green), 0.2);
    border: 1px solid map-get($colour-palette, n-status-green);
    color: map-get($colour-palette, n-status-green);
  }

  & .cardContentContainer .eventItem #Overdue.pill-red {
    background-color: rgba(map-get($colour-palette, n-status-red), 0.2);
    border: 1px solid map-get($colour-palette, n-status-red);
    color: map-get($colour-palette, n-status-red);
  }

  & .statusText p {
    font-weight: 600;
    font-size: var(--global-body-13-font-size);
  }

}

.my-contracts.dashboardCard .dashboardCardMain .dashboardCardMainRow,
.my-contracts.dashboardCard .dashboardCardMain .skeletonRow {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: $tablet-width) {
    grid-template-columns: 1fr 1fr 1fr;

  }

  @media (max-width: $mobile-width) {
    flex-wrap: wrap;
    grid: none;

  }

}

.dashboardContainer.new-dashboardContainer .dashboardCardMain {
  margin-top: 0;
  overflow: auto;
  padding-right: 0;
}