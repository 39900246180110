.previous-responses-tree {
    margin-top: var(--gap-20-px);
}

.previous-responses-tree div[aria-level='0']>a {
    color: #2c4874;
    font-size: var(--global-body-19-font-size);
}

.previous-responses-tree div[aria-level='1']>a {
    padding-left: var(--gap-20-px);
    font-weight: 600;
}

.previous-responses-tree div[aria-level='2']>a {
    padding-left: var(--gap-40-px);
    color: #2c4874;
    font-weight: 600;
}

.previous-responses-tree div[aria-level='2']>a .tree-view-menu {
    margin-left: auto;
    margin-right: inherit;
}

.previous-responses-tree div[aria-level='3']>div {
    padding-left: var(--gap-60-px);
}

.current-responses-tree div[aria-level='0']>a {
    color: #2c4874;
    font-size: var(--global-body-14-font-size);
    font-weight: 600;
}

.current-responses-tree div[aria-level='1']>div {
    padding-left: var(--gap-20-px);

    & .title {
        font-family: inherit;
        font-size: var(--global-body-14-font-size);
    }
}

.button.link {
    text-decoration: none;
}