.checkbox-title {
    font-weight: var(--input-label-font-weight);
    color: var(--input-label-color);
}

.labelled-checkBox {
    @include flex(column, flex-start, start, nowrap);

    &.row {
        @include flex(row, flex-start, center, nowrap);

        .form-radio-button-container {
            margin-right: var(--gap-40-px);
        }
    }

    .form-checkBox-container {
        @include flex(row, flex-start, center, nowrap);
        position: relative;

        .form-label {
            margin: var(--gap-10-px);
            flex: none;
            cursor: pointer;
            width: fit-content;
            word-break: break-word;
        }

        .checkBox-label {
            padding: 0 0 0 var(--gap-40-px);
        }

        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        input[type="checkbox"]+.form-label {
            display: block;
            position: relative;
            padding: 0 0 0 var(--gap-40-px);
        }

        input[type="checkbox"]+.form-label:before {
            left: 0;
        }

        input[type="checkbox"]+.form-label:before {
            content: "";
            display: inline-block;
            width: 21px;
            height: 21px;
            background-color: map-get($colour-palette, open-white);
            margin-right: var(--gap-15-px);
            position: absolute;
            top: 0rem;
            z-index: 1;
        }

        input[type="checkbox"]:focus+.form-label:before {
            outline: 1px solid black;
        }

        input[type="checkbox"]:focus+.form-label:after {
            outline: 1px solid black;
        }

        input[type="checkbox"]:checked+.form-label:before {
            border-width: 1px;
            border-color: map-get($colour-palette, open-green);
        }

        input[type="checkbox"]+.form-label:before {
            border: 1px solid map-get($colour-palette, open-green);
            background-clip: padding-box;
        }

        input[type="checkbox"]:checked+.form-label:after {
            content: "";
            display: inline-block;
            width: 21.5px;
            height: 21.5px;
            background-color: map-get($colour-palette, open-green);
            background-image: url("../../styles/icons/white/check.svg");
            background-position: 50% 40%;
            background-size: 50%;
            background-repeat: no-repeat;
            position: absolute;
            top: 0.5px;
            left: 0.5px;
            z-index: 1;
         }
    }
    

}