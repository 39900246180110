.supplierCard:hover {
    box-shadow: 0 4px 8px 0 #c9d8e4;
}

.supplierCard {
    @include flex(column, flex-start, flex-start, nowrap);
    @include boxSizing(border-box);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 0 3px;


    // margin: 1rem 0;
    position: relative;
    height: 100%;

    .content {
        padding: var(--gap-10-px) 0;
        width: 100%;
    }
}

.supplier-card-container {
    @include flex(column, flex-start, flex-start, nowrap);
    max-width: 225px;
    max-height: 32rem;
    overflow: hidden;
    @include boxSizing(border-box);
    padding: var(--gap-20-px) var(--gap-10-px);

    .header {
        width: 100%;
        overflow: hidden;
        @include boxSizing(border-box);
    }

    .projectTitle {
        margin-top: var(--gap-10-px);
        color: var(--header-background-color);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;

        &.alertRed {
            color: map-get($colour-palette, open-high-risk);
            margin-top: 0;
        }

        &:hover {
            -webkit-line-clamp: unset;
            text-overflow: unset;
        }
    }

    .rtm {
        margin-top: var(--gap-10-px);
        overflow-wrap: anywhere;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .contentItem {
        @include flex(column, space-between, space-between, nowrap);
        width: 100%;
        padding-bottom: var(--gap-10-px);

        .itemLabel {
            width: 100%;
            word-break: break-word;
            font-family: var(--font-family);
        }

        .itemValue {
            width: 100%;
            color: var(--header-background-color);
            overflow-wrap: anywhere;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-family: var(--font-family);
        }
    }

    .btnFooter {
        @include flex(column, flex-start, center, nowrap);
        width: calc(100% - 2rem);
        flex: 1;
        vertical-align: baseline;
        margin-top: var(--gap-20-px);
        position: absolute;
        bottom: var(--gap-20-px);
    }

    .itemValue.ellipsis-on-unhover {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;

        &:hover {
            -webkit-line-clamp: unset;
            text-overflow: unset;
        }
    }
}

.supplierCard.new-supplierCard {
    cursor: pointer;
    border: 1px solid map-get($colour-palette, n-card-border);
    box-shadow: none;
    margin: 0;
    background: map-get($colour-palette, n-open-white);

    &:hover {
        background-color: map-get($colour-palette , n-open-base-blue);
    }

    & .small.secondary {
        padding-left: var(--gap-10-px);
        padding-right: var(--gap-10-px);
        width: auto;

    }

    & .content {
        padding: 0;
    }

    & .card-type {
        font-family: var(--font-family-h);
        color: map-get($colour-palette, n-open-blue);
        font-size: var(--global-body-18-font-size);
        font-weight: 700;
        margin-bottom: 0.5rem;
    }

    & .supplier-card-container .rtm {
        color: map-get($colour-palette, n-open-blue);
        margin-top: 0.5rem;
    }

    & .supplier-card-container {
        text-decoration: none;
        height: 100%;
        width: 100%;

        & .itemLabel,
        & .caption-small {
            color: map-get($colour-palette , open-text-primary)
        }

    }
}