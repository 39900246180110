#notificationsButtonContainer {
    @include flex(row, flex-start, center, nowrap);

    #notificationToggleButton {
        @include flex(row, flex-start, center, nowrap);
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        @include boxSizing(border-box);
        color: map-get($colour-palette, open-white);

        // &:focus {
        //     outline: none;
        // }

        #bellIcon {
            height: 20px;

            @media (max-width: $tablet-width) {
                height: 30px;
            }
        }

        #notificationToggleBtnLabel {
            margin: 0 var(--gap-15-px);

            @media (max-width: $tablet-width) {
                display: none;
            }
        }
    }

    #notificationsCounter {
        @include flex(row, center, center, nowrap);
        cursor: pointer;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: map-get($colour-palette, open-yellow);
        color: map-get($colour-palette, open-blue);
        font-family: NotoSans-Bold;
        font-size: var(--global-body-16-font-size);
        margin-left: 0.1rem;

        @media (max-width: $tablet-width) {
            display: none;
        }
    }
}