.previous-responses-tree {
    margin-top: 2rem;
}

.previous-responses-tree div[aria-level='0']>a {
    color: #2c4874;
    font-size: var(--global-body-19-font-size);

    & .tree-view-menu {
        margin-right: inherit;
        margin-left: auto;
    }
}

.previous-responses-tree div[aria-level='1']>a {
    padding-left: 2rem;
    font-weight: 600;
}

.previous-responses-tree div[aria-level='2']>a {
    padding-left: 4rem;
    color: #2c4874;
    font-weight: 600;
}

.previous-responses-tree div[aria-level='3']>div {
    padding-left: 6rem;
}

.current-responses-tree div[aria-level='0']>a {
    color: #2c4874;
    font-size: var(--global-body-14-font-size);
    font-weight: 600;

    & .tree-view-menu {
        margin-left: auto;
        margin-right: inherit;
    }
}

.current-responses-tree div[aria-level='1']>div {
    padding-left: 2rem;

    & .title {
        font-family: inherit;
        font-size: var(--global-body-14-font-size);
    }
}

h2.submission-label {
    font-size: var(--global-body-19-font-size);
    margin-right: auto;
    color: #2c4874;
    padding: 2rem;
    background: #fff;
    width: 100%;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid lightgrey;
    font-family: 'NotoSans-Regular';

    & span {
        font-size: var(--global-body-16-font-size);
        display: block;
        margin-top: 1rem;
    }
}

.current-responses-tree {
    border-radius: 0 0 10px 10px;
}

p.previous-submission-label {
    & span {
        display: block;
        margin-top: 1rem;
        font-size: var(--global-body-13-font-size);
        font-weight: normal;
    }
}

.current-responses-tree .statuslabel-txt-container a,
.previous-responses-tree {
    text-transform: capitalize;
}