#createStatusModal {
    max-width: 800px;

    .labelled-textarea {
        #correspondenceText-textarea {
            max-width: 800px !important;
            width: 743px !important;
        }
    }

    .modal-content {
        .labelled-select {
            @include flex(column, flex-start, stretch, nowrap);
            margin-top: var(--gap-10-px);
        }

        .labelled-input {
            margin-top: var(--gap-10-px);
        }

        #uploadTitle {
            margin-bottom: var(--gap-10-px);
            font-weight: var(--input-label-font-weight);
            color: var(--input-label-color);
        }

        .select-search {
            position: relative;
            box-sizing: border-box;
        }

        .select-search *,
        .select-search *::after,
        .select-search *::before {
            box-sizing: inherit;
        }

        /**
   * Value wrapper
   */
        .select-search__value {
            position: relative;
        }

        .select-search__value::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: calc(50% - 9px);
            right: 19px;
            width: 11px;
            height: 11px;
        }

        /**
   * Input
   */
        .select-search__input {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            border: 1px solid var(--header-background-color);
            padding: 0 var(--gap-50-px) 0 var(--gap-10-px);
            background-image: url("../../styles/icons/grey/dropdown.svg");
            background-repeat: no-repeat;
            background-position: right var(--gap-10-px) center;
            display: inline-block;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        .select-search__input::-webkit-search-decoration,
        .select-search__input::-webkit-search-cancel-button,
        .select-search__input::-webkit-search-results-button,
        .select-search__input::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }

        .select-search__input:not([readonly]):focus {
            cursor: initial;
        }

        /**
   * Options wrapper
   */
        .select-search__select {
            background: #fff;
            box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
            border: solid 1px #000;
        }

        /**
   * Options
   */
        .select-search__options {
            list-style: none;
        }

        /**
   * Option row
   */
        .select-search__row:not(:first-child) {
            border-top: 1px solid #eee;
        }

        /**
   * Option
   */
        .select-search__option,
        .select-search__not-found {
            display: block;
            height: 36px;
            width: 100%;
            padding: 0 16px;
            border: none;
            outline: rgba(0, 0, 0, 0.15);
            font-family: NotoSans-Regular !important;
            font-size: var(--global-body-14-font-size) !important;
            text-align: left;
            cursor: pointer;
            background: #fff;
        }

        .select-search--multiple .select-search__option {
            height: 48px;
        }

        .select-search__option.is-selected {
            background: map-get($colour-palette, open-green);
            color: map-get($colour-palette, open-white);
        }

        .select-search__option.is-highlighted,
        .select-search__option:not(.is-selected):hover {
            background: rgba(47, 204, 139, 0.1);
        }

        .select-search__option.is-highlighted.is-selected,
        .select-search__option.is-selected:hover {
            background: var(--header-background-color);
            color: map-get($colour-palette, open-white);
        }

        /**
   * Group
   */
        .select-search__group-header {
            font-size: var(--global-body-14-font-size);
            text-transform: uppercase;
            background: #eee;
            padding: 8px 16px;
        }

        /**
   * States
   */
        .select-search.is-disabled {
            opacity: 0.5;
        }

        .select-search.is-loading .select-search__value::after {
            background-size: 11px;
        }

        .select-search:not(.is-disabled) .select-search__input {
            cursor: pointer;
            font-size: var(--global-body-14-font-size);
            font-family: 'NotoSans-Regular';
        }

        /**
   * Modifiers
   */
        .select-search--multiple {
            border-radius: 3px;
            overflow: hidden;
        }

        .select-search--multiple .select-search__input {
            cursor: initial;
        }

        .select-search--multiple .select-search__input {
            border-radius: 3px 3px 0 0;
        }

        .select-search--multiple:not(.select-search--search) .select-search__input {
            cursor: default;
        }

        .select-search:not(.select-search--multiple) .select-search__input:hover {
            border-color: map-get($colour-palette, open-text-success);
        }


        .select-search:not(.select-search--multiple) .select-search__select {
            position: relative;
            z-index: 2;
            top: 0;
            right: 0;
            left: 0;
            border-radius: 3px;
            overflow: auto;
            max-height: 360px;
        }

        .select-search--multiple .select-search__select {
            position: relative;
            overflow: auto;
            max-height: 260px;
            border-top: 1px solid #eee;
            border-radius: 0 0 3px 3px;
        }

        .select-search__not-found {
            height: auto;
            padding: 16px;
            text-align: center;
            color: #888;
        }
    }
}