.changeHistoryModal {
    .changeHistoryRow {
        @include flex(row, space-between, center, nowrap);
        margin-top: var(--gap-10-px);
    }

    .changeHistoryCol {
        &.view-btn {
            button {
                width: 'fit-content';
            }
        }
    }
}