html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: var(--global-font-size);
    font-family: var(--font-family);
    font-weight: var(--font-weight);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-color);
    scroll-padding-top: 140px;
}

p,
.title,
li {
    line-height: var(--line-height);
}

h1,
h2,
h3,
h4,
h5,
h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong {
    font-family: var(--font-family-h);
}

button {
    font-family: var(--font-family);
    font-weight: var(--font-weight);
}

#root {
    height: 100%;
    min-height: 100vh;
    @include flex(column, flex-start, center, nowrap);
    color: map-get($colour-palette, open-text-primary);
    overflow-y: auto;
}

.theme-change-button {
    position: fixed;
    z-index: 999;
    bottom: 2%;
    right: 2%;
    display: none;

    & button {
        background: map-get($colour-palette, open-yellow);
        border: none;
        padding: var(--gap-10-px);
        border-radius: 20px;
        color: var(--header-background-color);
        cursor: pointer;
    }

}

.mainContent {
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
    max-width: 1280px;
    flex: 1;
    margin: var(--gap-100-px) 0 var(--gap-30-px) 0;
    margin-top: var(--main-content-margin-top);
    padding: 0 var(--gap-30-px);
    @include boxSizing(border-box);

    @media (max-width: $tablet-width) {
        margin-top: var(--gap-80-px);
        margin-bottom: var(--gap-20-px);
        padding: 0 var(--gap-20-px);
    }

    @media (max-width: $mobile-width) {
        margin-top: var(--gap-90-px);
        margin-bottom: var(--gap-10-px);
        padding: 0 var(--gap-10-px);
    }
}

.m-50 {
    margin-top: 50px;
}

.nepo-container {
    @include flex(column, flex-start, stretch, nowrap);
    width: 100%;
    flex: 1;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    padding: var(--gap-20-px);
    @include boxSizing(border-box);
}

.select-search__input {
    font-family: var(--font-family) !important;
}

// Override react-daterange-picker package styles
.customDateInput {
    width: 100%;
    height: 40px;
    padding: var(--gap-5-px);
    border-radius: 5px;
    background-color: map-get($colour-palette, open-white);
    color: map-get($colour-palette, open-text-primary);
    border: var(--select-border);
    font-family: var(--font-family);
    font-size: var(--global-body-14-font-size);

    .react-daterange-picker__wrapper {
        border: none;
        font-family: var(--font-family);
        font-size: var(--global-body-16-font-size);

        .react-daterange-picker__inputGroup {
            text-align: center;
        }

        .react-daterange-picker__range-divider {
            padding: 0 var(--gap-10-px);
        }

        .clearIcon {
            vertical-align: middle;
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-14-font-size);

            .react-daterange-picker__range-divider {
                padding: 0;
            }
        }
    }

    .react-calendar {
        font-family: var(--font-family);
        font-size: var(--global-body-14-font-size);

        @media (max-width: $tablet-width) {
            max-width: 250px;
            width: 100%;
        }

        @media (max-width: $mobile-width) {
            max-width: 230px;
            width: 100%;
        }

        button {
            font-family: var(--font-family);
            font-size: var(--global-body-14-font-size);
        }

        .react-calendar__tile--now {
            color: map-get($colour-palette, open-white);
            background-color: map-get($colour-palette, open-green);
        }

        .react-calendar__tile--hasActive {
            color: map-get($colour-palette, open-white);
            background-color: map-get($colour-palette, open-green);
        }
    }

    .react-date-picker__calendar,
    .react-time-picker__clock {
        z-index: 2 !important;
    }

    .react-date-picker__wrapper,
    .react-time-picker__wrapper {
        border: none !important;
        font-family: var(--font-family) !important;
        font-size: var(--global-body-16-font-size) !important;


        .react-date-picker__inputGroup input,
        .react-time-picker__inputGroup input {
            border: none !important;
            text-align: center !important;
            // padding: 0 1rem !important;
            height: 30px !important;
        }

        .react-date-picker__inputGroup__leadingZero {
            margin-left: 18px;
        }

        .react-date-picker__inputGroup__day,
        .react-date-picker__inputGroup__month,
        .react-time-picker__inputGroup__hour,
        .react-time-picker__inputGroup__minute {
            width: 50px !important;

            @media (max-width: $mobile-width) {
                padding: 0 !important;
            }

            &.react-date-picker__inputGroup__input--hasLeadingZero {
                width: 30px !important;
            }
        }

        .react-date-picker__inputGroup__year {
            width: 61px !important;

            @media (max-width: $mobile-width) {
                padding: 0 !important;
            }
        }

        .react-date-picker__inputGroup__divider,
        .react-time-picker__inputGroup__divider {
            padding: 0 var(--gap-5-px);
        }

        .clearIcon {
            vertical-align: middle;
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-14-font-size);

            .react-date-picker__inputGroup__divider {
                padding: 0;
            }
        }
    }
}

.loader {
    border: 4px solid map-get($colour-palette, open-light-grey);
    border-top: 4px solid map-get($colour-palette, open-green);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    z-index: 99;
    position: fixed;
    left: 50%;
    top: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.sectionTitle {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: var(--gap-20-px);
    padding-bottom: var(--gap-20-px);
    width: 100%;
}

.sectionHeader {
    margin-bottom: var(--gap-20-px);
}

.label-margin {
    margin-bottom: var(--gap-10-px);
}

.actionButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

.bold {
    font-weight: bold;
}

.xx-large-title {
    font-size: var(--global-body-22-font-size);
}

.justify-content {
    text-align: justify;
}

.textIconContainer {
    .sectionHeader {
        font-weight: bold;
    }
}

.datePickerLabel,
.selectSearchTitle,
.dateRangeBetween {
    margin-bottom: var(--gap-10-px);
    font-weight: var(--input-label-font-weight);
    color: var(--input-label-color);
}

.keyInfo {
    font-weight: bold;
    color: var(--header-background-color) !important;
}

.screenTitleHeading {
    font-weight: bold;
    color: var(--header-background-color);
}

.dashboardHeaderText {
    .headerTitle {
        font-weight: bold;
        color: var(--header-background-color);
        font-family: var(--font-family-h);
    }

    // .guidanceText{
    //     color: map-get($colour-palette, open-jade)!important;
    // }
}

.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    outline: none;
}

.itemBody {
    .sectionHeader {
        font-weight: 600;
        color: var(--header-background-color);
    }
}

.justify {
    text-align: justify;
}

#guidanceBanner-supplierCodeInfo,
#guidanceBanner-supplierRegionInfo {
    margin-bottom: var(--gap-20-px);
}

.rbc-allday-cell {
    z-index: 0;
}

.newOrUnread {
    @include flex(row, center, center, nowrap);

    &.tag {
        background-color: map-get($colour-palette, open-unread);
        border-radius: var(--gap-50-px);
        width: 20px;
        height: 20px;
        color: white;
    }
}

.newOrUnreadTitleMargin {
    margin-left: var(--gap-10-px);
}

.line-break {
    white-space: pre-line;
}

.formatted-html {
    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    ins {
        text-decoration: underline;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
        unicode-bidi: isolate;

        ul {
            list-style-type: circle;
        }
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }

    li {
        display: list-item;
        text-align: -webkit-match-parent;
        unicode-bidi: isolate;
    }

    li::marker {
        font-size: var(--global-body-15-font-size);
    }

    p:empty {
        min-height: var(--gap-24-px);
    }

}

.loading-page,
.error-page {
    @include flex(row, center, center, wrap);
    width: 100%;
    font-size: var(--global-body-14-font-size);
}

.ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.info-tooltip {
    @include tooltip($data-content: attr(data-tooltip),
        $width: 220px,
        $padding: 5px 12px,
        $offset-y: -18px,
        $offset-x: 158px,
        $arrow-transform: translateY(68%) rotate(90deg),
    );
    width: 20px;
    display: flex;
    margin-left: 5px;

    img {
        width: 20px;
    }
}

//generic styles for the new style (some of them forced )

[data-theme="new-style"] {

    .sectionContent {
        padding: 0;
        border: none;
        box-sizing: border-box;
        word-break: break-word;
        @include flex(row, center, flex-start, wrap, var(--gap-10-px));
        flex-direction: column;

        @media (max-width: $mobile-width) {
            padding-bottom: var(--gap-10-px);
        }

        &Tags {
            @include flex(row, flex-start, flex-start, wrap, var(--gap-10-px));
            flex: 1;
        }

        .sectionRow {
            @include flex(row, flex-start, flex-start, nowrap, var(--gap-20-px));
            margin: 0 !important;
            width: 100%;

            @media (max-width: $mobile-width) {
                flex-wrap: wrap;

                p {
                    width: 100%;

                }
            }

            .sectionLabel {
                width: 100%;

                @media (min-width: $mobile-width) {
                    max-width: 230px;
                }

                @media (min-width: $laptop-width) {
                    max-width: 350px;
                }


            }

            .sectionValue {
                width: 100%;
                word-break: break-word;
                color: map-get($colour-palette, open-text-primary);
            }
        }

        & .title {
            font-weight: 530;
        }

        & .statuslabel-txt.body {
            font-weight: 600;
        }


        & .tag-txt.body {
            font-size: var(--global-body-12-font-size);
        }

        & .sectionRow {
            & .title.sectionLabel {

                border-bottom: 1px solid map-get($colour-palette , n-open-border-bottom);
            }

            &>div,
            & .upFormInputContainer {
                & .formLabel .title {

                    border-bottom: 1px solid map-get($colour-palette , n-open-border-bottom);
                }
            }

        }

    }

    .overviewLabelValuePair .caption-large.label,
    .overviewLabelValuePair .title-large.value {
        font-weight: 600;
        font-size: var(--global-body-14-font-size);
    }

    .viewHeading {
        font-family: var(--font-family-h);
        font-size: var(--global-body-20-font-size);
    }

    .dashboardHeaderText {
        .headerTitle {
            font-size: var(--global-body-20-font-size);
        }
    }

    p.title-large.sectionItemHeading {
        margin-bottom: var(--gap-20-px);
        font-weight: 600;
    }

    .bdContentSectionWrapper .bdContentSection .bdContentBody #bdAccordion .accordion-section {
        border: 1px solid map-get($colour-palette, n-open-border-grey);
    }

    .bdContentSectionWrapper .bdContentSection .bdContentBody #bdAccordion .accordion-section-content.open {
        border-top: 1px solid map-get($colour-palette, n-open-border-grey);
    }
}