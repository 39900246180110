.labelled-textarea {
    @include flex(column, flex-start, flex-start, nowrap);

    @media print {
        width: 100%;
    }

    .form-label {
        margin-bottom: var(--gap-10-px);
        font-weight: var(--input-label-font-weight);
        color: var(--input-label-color);
    }

    .form-textarea {
        width: 350px;
        max-width: 100%;
        height: 150px;
        min-height: 150px;
        border-radius: var(--input-radius);
        vertical-align: top;
        padding: var(--gap-5-px) var(--gap-5-px) var(--gap-5-px) var(--gap-10-px);
        background-color: map-get($colour-palette, open-white);
        border: var(--input-border);
        font-weight: var(--font-weight);
        color: map-get($colour-palette, open-text-primary);
        resize: both;
        @include boxSizing(border-box);
        font-family: var(--font-family);

        &:hover {
            border: 1px solid var(--header-background-color);
        }

        @media (max-width: $tablet-width) {
            width: 100%;
        }

    }

    #char-count {
        @include flex(row, flex-end, flex-start, nowrap);
        width: 100%;
        margin-top: var(--gap-10-px);
        font-family: NotoSans-Regular;
        font-size: var(--global-body-12-font-size);

        span {
            padding: 0 0.25rem;
        }
    }

    .rich-text-editor-wrapper {
        width: 100%;
        height: 280px;
        padding-bottom: 50px;
        font-family: var(--font-family);
        line-height: normal;
    }

    .rich-text-editor {
        border: 1px solid #F1F1F1;
        line-height: normal;
        overflow: hidden;
    }
}

.DraftEditor-editorContainer {
    height: 275px;
    overflow: auto;
    padding: 0px 14px;
}

.modal {
    .rich-text-editor-wrapper {
        height: 200px;
    }

    .DraftEditor-editorContainer {
        height: 195px;
    }

    .rdw-option-wrapper {
        margin: 0 3px;
    }
}