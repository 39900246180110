.recipient-item {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    padding-top: var(--gap-10-px);
    // padding-right: var(--gap-20-px);
    // padding-left: var(--gap-20-px);
    padding-bottom: var(--gap-10-px);
    border-bottom: 1px solid map-get($colour-palette, open-divider);

    &:first-of-type {
        margin-top: 0;
    }

    p {
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .statuslabel-content {
        margin-left: var(--gap-20-px);
    }

    @media (max-width: 500px) {
        @include flex(column, center, center, nowrap);

        .statuslabel-content {
            margin-left: 0rem;
        }

        p {
            white-space: normal;
        }
    }
}

.recipient-heading {
    @include flex(column, left, left, wrap);
    margin-top: var(--gap-10-px);
    padding-bottom: var(--gap-10-px);
    line-height: var(--line-height);
    word-break: break-word;
    border-bottom: 1px solid map-get($colour-palette, open-divider);

    p {
        text-align: left;
    }
}

.recipient-item-divider {
    border-bottom: 1px solid map-get($colour-palette, open-divider);

    &:last-of-type {
        display: none;
    }
}