.bottom-navigation-item {
    @include flex(column, center, center, nowrap);
    height: 100%;
    min-width: 100px;
    word-break: break-word;
    padding: var(--gap-10-px);
    @include boxSizing(border-box);
    cursor: pointer;

    &-icon {
        height: 40%;
        margin-bottom: var(--gap-10-px);
    }

    &-wrapper {
        @include flex(row, space-between, center, nowrap);

        @media (max-width: $mobile-width) {
            .bottom-navigation-item-counter {
                height: 25px;
                width: 25px;
            }

            p,
            div {
                font-size: var(--global-body-14-font-size);
            }
        }
    }

    &-counter {
        margin-left: var(--gap-10-px);
        @include flex(row, center, center, nowrap);
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: map-get($colour-palette, open-red);
        color: map-get($colour-palette, open-white);
    }
}