#solutionAdminPanel {
  display: flex;
  flex: 1;

  #solutionAccessRequestsCard {
    flex: 1;
    margin-right: 10px;
  }

  #solutionUsersCard {
    flex: 1;
  }

  @media (max-width:480px) {
    /* smartphones, Android phones, landscape iPhone */
    display: block;
  }

  .requests {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;

    .request-item {
      margin-bottom: 15px;

      .username {
        font-size: var(--global-body-16-font-size);
      }

      .departmentName {
        margin-top: 5px;
        font-size: var(--global-body-12-font-size);
        font-weight: 500;
      }

      .actions {
        display: flex;
        flex: 1;
        margin-top: 10px;

        button.primary {
          margin-right: 10px;
        }
      }
    }
  }

}