#lotContainer {

    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    height: 93%;
    gap: var(--gap-20-px);
    column-gap: var(--gap-20-px);

    @media (max-width: $tablet-width) {
        grid-template-columns: 1fr 1fr 1fr;

    }

    @media (max-width: $mobile-width) {
        flex-wrap: wrap;
        grid: none;

    }

    .lotCard {
        @include flex(column, flex-start, flex-start, nowrap);
        @include boxSizing(border-box);
        border: 1px solid map-get($colour-palette, open-green);
        border-radius: 10px;
        width: 100%;
        max-width: 225px;
        margin: var(--gap-10-px) var(--gap-10-px) var(--gap-20-px) 0;

        @media (max-width: $tablet-width) {
            margin: var(--gap-10-px) var(--gap-20-px) var(--gap-20-px) 0;
        }

        @media (max-width: $mobile-width) {
            &:last-of-type {
                margin-right: var(--gap-20-px);
            }
        }

        .content {
            min-height: 200px;
            padding: var(--gap-10-px) 0;
        }
    }

    .lot-card-container {
        @include flex(column, flex-start, flex-start, nowrap);
        max-width: 225px;
        @include boxSizing(border-box);
        padding: var(--gap-20-px) var(--gap-10-px);
        width: 100%;
        text-decoration: none;
        height: 100%;

        & .itemLabel,
        & .caption-small {
            color: map-get($colour-palette , open-text-primary)
        }

        .header {
            width: 100%;
            height: 12.5%;
            @include boxSizing(border-box);
        }

        .projectTitle {
            // margin-top: var(--gap-10-px);
            color: var(--header-background-color);
            word-break: break-word;
        }

        .projectHeader {
            margin-top: var(--gap-10-px);
        }

        .contentItem {
            @include flex(column, flex-start, flex-start, nowrap);
            width: 100%;
            word-break: break-word;
        }

        .btnFooter {
            @include flex(column, flex-start, center, nowrap);
            width: 100%;
            flex: 1;
            vertical-align: baseline;
            margin-top: var(--gap-20-px);

            button {
                width: 120px;
            }
        }
    }

    & .lotCard.new-lotCard {
        cursor: pointer;
        border: 1px solid map-get($colour-palette, n-card-border);
        box-shadow: none;
        margin: 0;
        background: #ffffff;

        &:hover {
            background-color: map-get($colour-palette , n-open-base-blue);
        }

        & .small.secondary {
            padding-left: var(--gap-10-px);
            padding-right: var(--gap-10-px);
            width: auto;

        }

        & .content {
            padding: 0;
        }

        & .card-type {
            font-family: var(--font-family-h);
            color: map-get($colour-palette, n-open-blue);
            font-size: var(--global-body-18-font-size);
            font-weight: 700;
            margin-bottom: var(--gap-5-px);
        }

    }
}