#workflowEventForm {
    width: 100%;

    #createEventHeader {
        padding: var(--gap-20-px) var(--gap-20-px);
        border-bottom: 1px solid map-get($colour-palette, open-divider);
    }

    .labelled-textarea {
        width: 100%;
    }

    #createEventBody {
        padding: var(--gap-20-px) var(--gap-20-px);

        .labelled-textarea {
            width: 100%;
            max-width: 350px;
        }

        #assigned,
        #questionnaireType,
        #selectedSteeringGroupAttendees-input,
        #selectedQuestionnaires-input,
        #selectedEvaluationEvents-input {
            max-width: 350px;
        }
    }

    .modal-content {
        width: 100%;

        .modal-header-title {
            width: 80%;
        }

        .formElementsRow {
            @include flex(row, space-between, flex-start, wrap);
            width: 100%;
        }

        .form-textarea {
            width: 100%;
        }
    }

    #selectedSteeringGroupAttendees-input,
    #selectedQuestionnaires-input,
    #selectedEvaluationEvents-input,
    #assigned,
    #questionnaireType {
        margin-top: var(--gap-10-px);
    }

    .datePickerWrapper,
    .timePickerWrapper {
        @include flex(column, flex-start, flex-start, nowrap);
        width: auto;

        &:nth-child(odd) {
            margin-right: var(--gap-20-px);
        }

        label {
            margin-bottom: var(--gap-10-px);
        }

        // .customDateInput {
        //     width: 210px !important;
        //     max-width: 250px;

        //     .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day,
        //     .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month.react-date-picker__inputGroup__input--hasLeadingZero {
        //         width: 35px !important;
        //     }

        //     .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
        //         width: 50px !important;
        //     }

        //     .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day,
        //     .react-date-picker__inputGroup__divider,
        //     .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month.react-date-picker__inputGroup__input--hasLeadingZero,
        //     .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
        //         padding: 0.5rem !important;
        //     }
        // }

        .customTimeInput {
            width: 210px !important;
            font-family: NotoSans-Regular;
            font-size: var(--global-body-16-font-size);

            .react-time-picker__wrapper {
                border-radius: 5px;
                border: 1px solid var(--header-background-color);
            }

            .react-time-picker__inputGroup {
                margin: var(--gap-5-px);
            }

            .react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour,
            .react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
                width: 50px !important;
                height: 28px !important;
            }

            .react-time-picker__inputGroup__divider {
                margin: 0 var(--gap-10-px);
            }
        }
    }
}