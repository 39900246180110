.mobile-menu-toggle-btn {
  @include flex(row, space-around, center, nowrap);
  height: 35px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  @include boxSizing(border-box);

  &:focus {
    outline: none;
  }

  .toggle-btn-label {
    color: map-get($colour-palette, open-white);

    @media (max-width: $mobile-width) {
      font-size: var(--global-body-16-font-size);
    }
  }

  .toggle-btn-lines {
    @include flex(column, space-around, center, nowrap);
    width: 35px;
    height: 100%;
    margin-left: var(--gap-10-px);

    .toggle-btn-line {
      background-color: map-get($colour-palette, open-white);
      width: 35px;
      height: 5px;
      border-radius: 50px;
    }
  }

  @media (min-width: $tablet-width) {
    display: none;
  }
}