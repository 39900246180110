#userManagementContainer {
  @include flex(column, flex-start, center, nowrap);
  width: 100%;
  flex: 1;
  border-radius: 10px;
  background-color: map-get($colour-palette, open-white);
  @include boxSizing(border-box);
  padding: var(--gap-20-px);

  @media (max-width: $mobile-width) {
    padding: var(--gap-20-px);
  }

  #userManagementBtnContainer {
    width: 100%;
    @include flex(row, flex-end, center, nowrap);
    padding-bottom: var(--gap-10-px);
    border-bottom: 1px solid map-get($colour-palette, open-content-container-border);
    @include boxSizing(border-box);
  }

  .userContentSection {
    @include flex(column, flex-start, center, nowrap);
    width: 100%;
    flex: 1;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    padding: var(--gap-20-px);
    @include boxSizing(border-box);

    .userContentHeader {
      @include flex(row, flex-start, center, wrap);
      width: 97%;
      min-height: 50px;

      .searchBarContainer {
        width: 50%;
        padding: var(--gap-10-px);
        @include boxSizing(border-box);

        @media (max-width: $mobile-width) {
          width: 100%;
        }
      }

      .sortFilterContainer {
        @include flex(row, flex-end, center, nowrap, var(--gap-20-px));
        width: 50%;
        padding: var(--gap-10-px);
        @include boxSizing(border-box);

        #usermanagementSortDropdown {


          @media (max-width: $tablet-width) {}
        }

        #usermanagementFilterButton {


          @media (max-width: $tablet-width) {}
        }

        @media (max-width: $mobile-width) {
          @include flex(row, space-between, center, nowrap);
          width: 100%;
        }
      }
    }

    .userContentBody {
      @include flex(column, flex-start, center, nowrap);
      width: 100%;
      flex: 1;
      padding: var(--gap-30-px) 0;

      #userAccordion {
        width: 100%;


        .accordion-section {
          margin-bottom: var(--gap-30-px);
          border: 1px solid var(--header-background-color);
          border-radius: 5px;
          //border-bottom: 1px solid var(--header-background-color);
          //padding: 5px 0;

          &:last-child {
            margin-bottom: 0;
          }

          @media (max-width: $tablet-width) {
            margin-bottom: var(--gap-20-px);
          }

          &-header {
            border-radius: 5px;
            padding: 0 var(--gap-20-px);

            img {
              padding: 0;
            }

            @media (max-width: $tablet-width) {
              padding: 0 var(--gap-10-px);
            }

            &.contentOpen {
              border-radius: 5px 5px 0 0;
            }

            &-data {
              pointer-events: none;

              p {
                padding: var(--gap-10-px) 0;
                margin-right: var(--gap-20-px);
              }

              .headerTitle {
                margin-right: var(--gap-20-px);

                @media (max-width: $tablet-width) {
                  margin-right: var(--gap-10-px);
                }
              }
            }
          }

          &-content {
            &.open {
              border-top: 1px solid var(--header-background-color);
              border-radius: 0 0 5px 5px;
              overflow-y: auto;
            }

            &-data {
              @include flex(column, flex-start, center, nowrap);
              @include boxSizing(border-box);

              .userAccordionSectionContentRow {
                @include flex(row, space-between, flex-start, wrap);
                width: 100%;

                .userAccordionSectionContentData {
                  @include flex(row, flex-start, flex-start, wrap);
                  padding: var(--gap-10-px) var(--gap-20-px);

                  .label {
                    margin-right: var(--gap-10-px);
                  }

                  @media (max-width: $tablet-width) {
                    padding: var(--gap-10-px);
                  }

                  @media (max-width: $mobile-width) {
                    padding: var(--gap-5-px);

                    p {
                      padding: var(--gap-5-px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #userFilterPanel {
      @include flex(column, flex-start, flex-start nowrap);
      top: 100px;
      bottom: 20px;

      @media (max-width: $mobile-width) {
        top: 0;
        bottom: 0;
      }
    }

    .userContentFooter {
      width: 100%;
      height: 80px;
    }
  }

  .muSelectSearch {
    margin-bottom: var(--gap-30-px);

    #specialPrivileges {
      margin-top: var(--gap-10-px);
    }

  }


  #userContent {
    padding: 0;
    border: none;

    .label {
      @media (max-width: $mobile-width) {
        width: auto;
      }
    }

    .button {
      margin: 0;

      @media (max-width: $mobile-width) {
        margin-bottom: 0;
      }
    }

    .buttonItem {
      @include flex(row, flex-end, center, nowrap);
      width: 100%;
      margin: var(--gap-15-px) 0 var(--gap-20-px);

      @media (max-width: $mobile-width) {
        @include flex(column, center, flex-start, nowrap);
      }

      .userButtonsContainer {
        @include flex(row, space-between, center, nowrap);
        padding: 0 var(--gap-15-px) 0 var(--gap-20-px);
        width: 100%;

        @media (max-width: $mobile-width) {
          @include flex(column, space-between, center, nowrap);
        }

        .userPasswordWrapper {
          @include flex(row, flex-start, flex-start, nowrap);

          .button {
            max-width: 120px;
            width: 120px;
            height: 30px;
            font-size: var(--global-body-12-font-size);
            // word-break: break-word;
            padding: var(--gap-5-px);
          }

          @media (max-width: $mobile-width) {
            margin: 0 var(--gap-10-px) var(--gap-20-px) 0;
          }
        }

        .userButtonsWrapper {
          @include flex(row, flex-start, flex-start, nowrap);

          @media (max-width: $mobile-width) {
            @include flex(column, flex-start, flex-start, nowrap);
          }

          .button {
            max-width: 100px;
            width: 100px;
            height: 30px;
            font-size: var(--global-body-14-font-size);
          }

          .button:first-of-type {
            margin-right: var(--gap-20-px);

            @media (max-width: $mobile-width) {
              margin-bottom: var(--gap-20-px);
            }
          }

          @media (max-width: $mobile-width) {
            margin-left: 0;
          }
        }

        @media (max-width: $mobile-width) {
          @include flex(column, space-between, center, nowrap);
          margin-right: var(--gap-15-px);
        }

        .button:last-of-type {
          @media (max-width: $mobile-width) {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.Mui-extra .userButtonsContainer {
  @include flex(row, space-between, center, nowrap);

  & .userButtonsWrapper {
    @include flex(row, space-between, center, nowrap, var(--gap-20-px));
  }
}

[data-theme="new-style"] {

  .userAccordionSectionContentRow {
    @include flex(row, space-between, center, nowrap);
    margin-bottom: var(--gap-20-px);

    & .userAccordionSectionContentData {
      @include flex(row, space-between, center, nowrap, var(--gap-10-px));

      & .label {
        font-weight: 600;
      }
    }
  }

  & .userContentHeader {
    margin: 0;
    padding: var(--gap-15-px);

    width: auto;
    background: map-get($colour-palette, n-open-searchbar);
    border-radius: 10px;
    gap: var(--gap-20-px);
    flex-wrap: nowrap;


    @media (max-width: $mobile-width) {
      flex-wrap: wrap;

    }

    & .searchBarContainer {
      padding: 0;
      width: 55%;

      @media (max-width: $mobile-width) {
        width: 100%;

      }
    }

    & .sortFilterContainer {
      padding: 0;
      width: 43%;

      & select#projectsSortDropdown,
      & button#projectsFilterButton,
      & button#addNewButton {
        margin: 0;
      }

      @media (max-width: $tablet-width) {
        width: 58%;
      }

      @media (max-width: $mobile-width) {
        width: 100%;
        gap: var(--gap-20-px)
      }

    }

    & button {
      width: fit-content;
      padding-left: var(--gap-20-px);
      padding-right: var(--gap-20-px);
      font-weight: 600;
    }

    .searchBar {
      background-color: map-get($colour-palette, open-white);
      color: map-get($colour-palette, open-text-primary);
      border: var(--input-border);
      font-weight: var(--font-weight);
      border-radius: var(--input-radius);
      max-width: none;
    }
  }

}