.opportunityOverviewContainer {
  @include flex(row, space-between, stretch, wrap, var(--gap-20-px));
  width: 100%;

  #submissionDeadlineContainer {
    width: 100%;
  }

  #opportunityDetails {
    height: 100%;

    .dashboardCardMain {
      overflow: hidden;

      .sectionHeader {
        margin-top: var(--gap-20-px);
      }

      .sectionContent {
        padding-top: 0;
        padding-right: 0;
        padding-left: var(--gap-20-px);
        padding-bottom: var(--gap-20-px);
        border-bottom: 1px solid map-get($colour-palette, open-divider);

        &Tags {
          @include flex(row, flex-start, flex-start, wrap);
          flex: 1;

          @media (max-width: $mobile-width) {
            padding: var(--gap-15-px) 0;
          }

          .tag-content {
            margin: 0 var(--gap-20-px) var(--gap-20-px) 0;

            @media (max-width: $mobile-width) {
              margin: 0 0 var(--gap-10-px) 0;
            }
          }
        }
      }

      .sectionContent:last-child {
        border-bottom: none;
      }
    }
  }

  #lots {
    min-height: 520px;
    height: 100%;
  }

  #correspondenceContent {
    margin-bottom: var(--gap-10-px);
  }

  #correspondenceContent>*:not(:last-child) {
    margin-bottom: var(--gap-10-px);
  }

  #pastActionContanier {
    @include flex(column, flex-start, flex-start, wrap);

    .actionItem {
      width: 100%;
      margin-top: var(--gap-20-px);

      p {
        margin-bottom: var(--gap-10-px);
      }

      button {
        width: auto;
      }
    }
  }
}

.faq_content {
  padding: var(--gap-15-px);
}

#activityLog {
  .logItem {
    @include flex(column, flex-start, flex-start, nowrap);

    .headerTitle {
      margin: 0;
    }
  }

  .logItem:not(:last-child) {
    margin-bottom: var(--gap-10-px);
  }
}

#removeInterestModal {
  .caption {
    margin: var(--gap-10-px) 0 var(--gap-10-px) 0;
  }

  #statusReasons-select {
    height: 50px;
    width: 100%;
    max-width: 100%;
    font-size: var(--global-body-14-font-size);
    margin-bottom: var(--gap-10-px);
  }

  #otherReason-textarea {
    width: 100%;
  }
}