#contractManagementDocuments,
#documentModal {
    .documentContainer {
        width: 100%;

        .documentItem {
            @include flex(row, space-between, center, nowrap);
            width: 100%;
            margin-top: var(--gap-20-px);

            .button {
                height: 30px;
                width: 100px;
                border-radius: 5px;
                font-size: var(--global-body-14-font-size);
            }
        }
    }

    .downloadButton {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background-color: var(--header-background-color);
        color: map-get($colour-palette, open-white);
    }
}