.side-drawer {
    background-color: map-get($colour-palette, open-white);
    position: fixed;
    width: 75%;
    max-width: 400px;
    top: 0;
    z-index: 100;
    transition: transform 0.3s ease-out;
    border-radius: 10px 0 0 10px;
    overflow-y: auto;

    &.left {
        left: 0;
        transform: translateX(-100%);

        &.open {
            transform: translateX(0);
        }
    }

    &.right {
        right: 0;
        transform: translateX(100%);

        &.open {
            transform: translateX(0);
        }
    }

    &.tablet-mobile-only {
        @media (min-width: $tablet-width) {
            display: none;
        }
    }

    .tablet-mobile-only {
        @media (min-width: $tablet-width) {
            display: none;
        }
    }

    @media (max-width: $mobile-width) {
        max-width: 300px;
    }

    @media (max-width: $small-mobile-width) {
        width: 85%;
    }
}