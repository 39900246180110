#guidanceBanner-guidanceBannerJoinAccount {
    height: fit-content;
    margin-bottom: var(--gap-20-px);

    .guidanceBannerContent {
        min-height: min-content;

        .showText {
            width: 100%;
        }
    }
}