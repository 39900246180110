.filterPanelHeader {
    @include flex(row, space-between, center, nowrap);
    padding: var(--gap-20-px);
    border-top-left-radius: 10px;
    @include boxSizing(border-box);

    .closeBtn {
        position: absolute;
        right: 0;
        margin-right: 2rem;
        border: none;
        background: none;

        &-img {
            cursor: pointer;
            height: 30px;
        }
    }

    @media (max-width: $mobile-width) {
        padding: var(--gap-20-px) var(--gap-10-px);
    }

    @media (max-width: $small-mobile-width) {
        padding: var(--gap-10-px);
    }
}

.filterPanelContent {
    flex: 1;
    padding: var(--gap-20-px);
    overflow-y: auto;
    @include boxSizing(border-box);
    border-top: 1px solid map-get($colour-palette, open-content-container-border);
    border-bottom: 1px solid map-get($colour-palette, open-content-container-border);

    @media (max-width: $mobile-width) {
        padding: var(--gap-20-px) var(--gap-10-px);
    }
}

.filterPanelFooter {
    @include flex(row, space-around, flex-start, nowrap);
    padding: var(--gap-20-px) var(--gap-20-px) var(--gap-30-px);
    border-bottom-left-radius: 10px;
    @include boxSizing(border-box);

    @media (max-width: $mobile-width) {
        padding: var(--gap-20-px) var(--gap-10-px);
    }
}