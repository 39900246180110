.worknote-item {
    margin-top: var(--gap-10-px);
    margin-bottom: var(--gap-10-px);

    &:first-of-type {
        margin-top: 0;
    }
}

.worknote-item-content {
    @include flex(row, center, center, nowrap);
    margin-top: var(--gap-10-px);
    padding-bottom: var(--gap-10-px);
    line-height: var(--line-height);
    word-break: break-word;
}

.worknote-item-divider {
    border-bottom: 1px solid map-get($colour-palette, open-divider);

    &:last-of-type {
        display: none;
    }
}