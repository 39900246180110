$colour-palette: (
    open-brand-primary: #2c4874,
    open-text-primary: #0b0c0c,
    open-text-grey: #6a6a6a,
    open-text-footer: #5a5a5a,
    open-text-success: #10844E,
    open-text-warning: #B00020,
    open-body-grey: #c9d8e420,
    open-footer-border: #cccece,
    open-divider: #ebebeb,
    open-screen-overlay: rgba(34, 34, 34, 0.4),
    open-white: #ffffff,
    open-red: #ce0000,
    open-text-error: #CB4B0B,
    open-amber: #cb4b0b,
    open-green: #2c4874,
    open-browser-link-blue: #0000ee,
    open-grey: #868686,
    open-content-container-border: #f1f1f1,
    open-bottom-navigation-border: #dddddd,
    open-green-light:#d8e4e4,
    open-light-grey:#f3f3f3,
    open-platinum: #E5E4E2,
    open-silver: #C0C0C0,
    open-gold: #FFD700,
    open-warning: #FEFFB8,
    open-critical-risk: #990000,
    open-high-risk: #FF0000,
    open-medium-risk: #FF9737,
    open-text-risk:#69665F,
    open-jade: #20b3aa,
    open-blue: #1f3765,
    open-blue-80: #43557e,
    open-blue-90: #2c4874,
    open-yellow: #F9DB9E,
    open-yellow-80: #f6ca80,
    open-yellow-dark: #F57F17,
    open-base-yellow: #fcf0db,
    open-yellow-light: #FCF0DB,
    open-base-blue: #c9d8e4,
    open-base-blue-20: #c9d8e420,
    open-brown-dark: #A52A2A,
    open-light-green: #90EE90,
    open-skyblue: #87ceeb,
    open-yellow-focus: #d3b04a,
    open-divider-grey: lightgrey,
    open-awarded-pill: #008000,
    open-active-pill: #df8d10,
    open-successful: #CCE6CC,
    open-unsuccessful: #F2CCCC,
    open-successful-border: #008000,
    open-unsuccessful-border: #CB0404,
    open-unread: #DF1010,
    n-open-blue: #1f3765,
    n-open-base-blue: #c9d8e4,
    n-open-jade: #20b3aa,
    n-open-jade-hover: #20b3aa47,
    n-open-yellow: #F9DB9E,
    n-open-yellow-strong: #f5c35d,
    n-open-border-grey: #C1BBBB,
    n-open-links: #004AD8,
    n-card-border: #726C6C,
    n-status-yellow: #702B05,
    n-status-yellow-fill: #FFECBC,
    n-status-red: #BC0202,
    n-status-green: #008000,
    n-open-dark-yellow: #D1910D,
    n-open-light-grey: #fafafa,
    n-open-white: #ffffff,
    n-open-border-bottom: #eeeeee,
    n-open-event-status-not-started: #696865,
    n-open-event-status-started: #000000,
    n-open-event-status-completed: #1F3765,
    n-open-event-status-awaiting-publish: #FF5F15,
    n-open-event-status-published: #008000,
    n-open-event-status-closed: #BC0202,
    n-open-hover-menu-header: #B2C3E4,
    n-open-hover-tabs: #507898,
    n-open-searchbar: #eff1f3,
    n-open-extra-card: #e6e6e6,
    n-open-link-hover: #D5DBE4,
    n-open-view-edit-remove-hover: #F3F6F9,
    n-open-tag-blue: #E3EBF1,
);

// Device sizes
$small-mobile-width: 320px;
$mobile-width: 600px;
$tablet-width: 840px;
$laptop-width: 1366px;

[data-theme="default-style"] {
    --bg-color: rgba(201, 216, 228, .125);
    --global-font-size: 62.5%; //it's an antipattern. Should be removed.
    --global-body-50-font-size: 5rem;
    --global-body-40-font-size: 4rem;
    --global-body-38-font-size: 3.8rem;
    --global-body-36-font-size: 3.6rem;
    --global-body-35-font-size: 3.5rem;
    --global-body-34-font-size: 3.4rem;
    --global-body-32-font-size: 3.2rem;
    --global-body-28-font-size: 2.8rem;
    --global-body-26-font-size: 2.6rem;
    --global-body-24-font-size: 2.4rem;
    --global-body-23-font-size: 2.3rem;
    --global-body-22-font-size: 2.2rem;
    --global-body-20-font-size: 2rem;
    --global-body-19-font-size: 1.9rem;
    --global-body-18-font-size: 1.8rem;
    --global-body-17-font-size: 1.7rem;
    --global-body-16-font-size: 1.6rem;
    --global-body-15-font-size: 1.5rem;
    --global-body-14-font-size: 1.4rem;
    --global-body-13-font-size: 1.3rem;
    --global-body-12-font-size: 1.2rem;
    --global-body-10-font-size: 1rem;
    --line-height: 150%;

    --font-family-h: sans-serif;
    --font-family: sans-serif;
    --header-background-color: #2c4874;
    --header-background-image: radial-gradient(circle at center, #20b3aa -100%, #1f3765);
    --main-content-margin-top: 10rem;
    --primary-button-background: #2c4874;
    --primary-button-outline-hover: 2.5px solid #d3b04a;
    --primary-button-background-hover: #2c4874;
    --buttons-box-shadow: none;
    --font-weight: normal;
    --input-radius: 5px;
    --input-border: 1px solid #2c4874;
    --input-label-color: #0b0c0c;
    --input-label-font-weight: 700;
    --secondary-button-background: #FCF0DB;
    --secondary-button-border: 1px solid #F9DB9E;
    --secondary-button-color: #1f3765;
    --secondary-button-outline-hover: 2.5px solid #d3b04a;
    --tertiary-button-background: #FCF0DB;
    --tertiary-button-color: #2c4874;
    --tertiary-button-border: 1px solid #F9DB9E;
    --tertiary-button-outline-hover: 2px solid #d3b04a;
    --tertiary-button-weight: inherit;
    --primary-red-button-background: #ce0000;
    --primary-red-button-background-hover: #ce0000;
    --secondary-ghost-button-outline: 2px solid #d3b04a;
    --tag-background: #78cbc3;
    --tag-border: 1px solid #20b3aa;
    --tag-font-color: #fff;
    --select-border: 1px solid #2c4874;
    --gap-5-px: 0.5rem;
    --gap-10-px: 1rem;
    --gap-15-px: 1.5rem;
    --gap-20-px: 2rem;
    --gap-25-px: 2.5rem;
    --gap-24-px: 2.4rem;
    --gap-30-px: 3rem;
    --gap-40-px: 4rem;
    --gap-40-px: 4rem;
    --gap-50-px: 5rem;
    --gap-60-px: 6rem;
    --gap-70-px: 7rem;
    --gap-80-px: 8rem;
    --gap-90-px: 9rem;
    --gap-100-px: 10rem;
    --gap-120-px: 12rem;
}

[data-theme="new-style"] {
    --bg-color: #f5f5f5;
    --global-font-size: 100%;
    --global-body-50-font-size: 3.125rem;
    --global-body-40-font-size: 2.5rem;
    --global-body-38-font-size: 2.375rem;
    --global-body-36-font-size: 2.25rem;
    --global-body-35-font-size: 2.1875rem;
    --global-body-34-font-size: 2.125rem;
    --global-body-32-font-size: 2rem;
    --global-body-28-font-size: 1.75rem;
    --global-body-26-font-size: 1.625rem;
    --global-body-24-font-size: 1.5rem;
    --global-body-23-font-size: 1.4375rem;
    --global-body-20-font-size: 1.25rem;
    --global-body-19-font-size: 1.1875rem;
    --global-body-18-font-size: 1.125rem;
    --global-body-17-font-size: 1.0625rem;
    --global-body-16-font-size: 1rem;
    --global-body-15-font-size: .9375rem;
    --global-body-14-font-size: .875rem;
    --global-body-13-font-size: .8125rem;
    --global-body-12-font-size: .75rem;
    --global-body-10-font-size: .625rem;
    --line-height: 1.2rem;


    --font-family-h: 'Roboto-condensed', sans-serif;
    --font-family: 'Montserrat', sans-serif;
    --header-background-color: #1f3765;
    --header-background-image: #1f3765;
    --main-content-margin-top: 7rem;
    --primary-button-background: #1f3765;
    --primary-button-outline-hover: none;
    --primary-button-background-hover: #000000;
    --buttons-box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px 0px;
    --font-weight: 480;
    --input-radius: 5px;
    --input-border: 1px solid #d3d3d3;
    --input-label-color: #1f3765;
    --input-label-font-weight: 600;
    --secondary-button-background: #F9DB9E;
    --secondary-button-border: none;
    --secondary-button-color: #1F3765;
    --secondary-button-outline-hover: none;
    --secondary-button-background-hover: #F5C35D;
    --tertiary-button-background: #fff;
    --tertiary-button-color: #000;
    --tertiary-button-border: 1px solid #20b3aa;
    --tertiary-button-outline-hover: 2px solid #20b3aa;
    --tertiary-button-weight: 600;
    --primary-red-button-background: #BC0202;
    --primary-red-button-background-hover: #8B0101;
    --secondary-ghost-button-outline: 2px solid #F9DB9E;
    --tag-background: #20b3aacc;
    --tag-border: 1px solid #20B3AA;
    --tag-font-color: #000;
    --select-border: 1px solid #d3d3d3;
    --gap-5-px: .3125rem;
    --gap-10-px: .625rem;
    --gap-15-px: .9375rem;
    --gap-20-px: 1.25rem;
    --gap-25-px: 1.5625rem;
    --gap-24-px: 1.5rem;
    --gap-30-px: 1.875rem;
    --gap-40-px: 2.5rem;
    --gap-50-px: 3.125rem;
    --gap-60-px: 3.75rem;
    --gap-70-px: 4.375rem;
    --gap-80-px: 5rem;
    --gap-90-px: 5.625rem;
    --gap-100-px: 6.25rem;
    --gap-120-px: 7.5rem;
}