.modal-container {
    @include flex(column, center, center, nowrap);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 105;
    padding: 0 var(--gap-10-px);
    @include boxSizing(border-box);

    .modal {
        @include flex(column, flex-start, flex-start, nowrap);
        border-radius: 10px;
        background-color: map-get($colour-palette, open-white);
        min-width: 250px;

        &-header {
            @include flex(row, center, center, nowrap);
            padding: 3.5rem;
            width: calc(100% - 7rem); // all - horizontal padding left&right
            height: 25px;
            border-bottom: 1px solid map-get($colour-palette, open-divider);
            border-radius: 10px 10px 0 0;
            position: relative;
            padding-top: var(--gap-20-px);
            padding-bottom: var(--gap-20-px);

            &-title {
                width: 100%;
                text-align: center;

                @media (max-width: $mobile-width) {
                    inline-size: 68%;
                    overflow-wrap: break-word;
                }
            }

            &-close-btn {
                position: absolute;
                right: 0;
                margin-right: var(--gap-20-px);
                border: none;
                background: none;

                &-img {
                    cursor: pointer;
                    height: 30px;
                }
            }
        }

        &-content {
            flex: 1;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            margin: var(--gap-20-px) 0;
            padding: 0 var(--gap-20-px);
            @include boxSizing(border-box);

            &-title {
                font-size: var(--global-body-16-font-size);
            }

            &-label {
                font-size: var(--global-body-14-font-size);
            }

            input:not(.regSearch, #openLinkInNewWindow, #linkTitle, #linkTarget, #fileUpload-input) {
                width: 100%;
                height: 40px;
                padding: var(--gap-10-px);
                // border: 1px solid var(--header-background-color);
                @include boxSizing(border-box);
                border-radius: 5px;
            }

            select {
                width: 100%;
                height: 40px;
                max-width: inherit;
                border: var(--input-border);
                @include boxSizing(border-box);
                border-radius: 5px;
            }
        }

        &-footer {
            width: 100%;
            padding: var(--gap-10-px) var(--gap-20-px) var(--gap-30-px) var(--gap-20-px);
            @include boxSizing(border-box);
            @include flex(row, space-between, center, wrap);

            @media (max-width: $mobile-width) {
                padding: var(--gap-10-px) var(--gap-20-px) var(--gap-20-px) var(--gap-20-px);
            }

            #main-only-action-btn {
                width: 100%;
                height: 40px;
            }

            @media (max-width: $mobile-width) {
                padding: var(--gap-10-px) var(--gap-20-px) var(--gap-20-px) var(--gap-20-px);

                #main-only-action-btn {
                    width: 100%;
                    height: 35px;
                }

                #second-action-btn {
                    width: 120px;
                    height: 35px;
                }

                #main-action-btn {
                    width: 120px;
                    height: 35px;
                }
            }

            #helper-txt-container {
                @include flex(row, center, center, wrap);
                width: 100%;
                margin-top: var(--gap-15-px);

                @media (max-width: $mobile-width) {
                    margin-top: var(--gap-10-px);
                }

                #help-action-button {
                    width: auto;
                    min-width: auto;
                    height: 100%;
                    color: map-get($colour-palette, open-browser-link-blue);
                    background: none;
                    padding: 0;
                    padding-left: 0.3rem;
                }
            }
        }

        &.small {
            width: 100%;
            max-width: 400px;
            height: 100%;
            max-height: 400px;
        }

        &.small-long {
            width: 100%;
            max-width: 550px;
            height: 100%;
            max-height: 400px;
        }

        // This is for placeholder guidance banner images and should be changed once we receive
        // proper ones from Nepo.
        &.temp-size {
            width: 100%;
            max-width: 640px;
            height: 100%;
            max-height: 540px;
        }

        &.medium {
            width: 100%;
            max-width: 500px;
            height: 100%;
            // min-height: 400px;
            max-height: 650px;

            @media (max-width: $mobile-width) {
                min-height: 100%;
                max-height: 100%;
                max-width: 100%;
            }
        }

        &.large {
            height: 500px;
            width: 600px;
            // min-height: 500px;
            max-height: 650px;

            @media (max-width: $mobile-width) {
                min-height: 100%;
                max-height: 100%;
                max-width: 100%;
            }
        }

        &.large-long {
            width: 100%;
            max-width: 550px;
            height: 100%;
            max-height: 750px;
        }

        &.x-large {
            width: 100%;
            max-width: 700px;
            height: 100%;
            max-height: 650px;
        }

        &.xxl {
            width: 100%;
            max-width: 1500px;
            height: 100%;
            max-height: 830px;

            // applying all changes here to only affect the modals that are using the extra size, not altering the others
            & .modal-header-title {
                text-align: start;
                font-size: 24px;
            }

            & .modal-content {
                display: flex;
                flex-direction: column;

                & .uploadContainer .formLabel>.title {
                    margin-top: 2rem;
                    margin-bottom: 1rem;
                    font-weight: var(--input-label-font-weight);
                    color: var(--input-label-color);
                }

                & .labelled-textarea {
                    flex-grow: 1;

                    & .form-label {
                        margin-top: 2rem;
                        margin-bottom: 1rem;
                        font-weight: var(--input-label-font-weight);
                        color: var(--input-label-color);
                    }

                    & .rich-text-editor-wrapper {
                        min-height: 200px;
                        flex-grow: 1;

                        & .DraftEditor-editorContainer {
                            min-height: 195px;
                            height: 100%;

                            & .notranslate.public-DraftEditor-content {
                                // this element gets height inline style, we need overwriting it
                                // Prevents scroll bar if not enough content
                                height: calc(100% - 16px) !important
                            }

                        }
                    }
                }
            }
        }
    }
}

.modal-container.session-timeout {
    z-index: 106
}