.button {
    @include flex(row, center, center, nowrap);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    transition: 0.2;

    @media (max-width: $tablet-width) {
        min-width: 120px;
        height: 35px;
    }

    @media (max-width: $mobile-width) {
        min-width: 100px;
        height: 30px;
    }

    &-icon {
        margin-right: var(--gap-10-px);

        @media (max-width: $tablet-width) {
            height: 60%;
        }

        @media (max-width: $mobile-width) {
            height: 50%;
        }
    }

    &.primary {
        background-color: var(--primary-button-background);
        color: map-get($colour-palette, open-white);

        &:hover,
        &:focus-visible {
            outline: var(--primary-button-outline-hover);
            background: var(--primary-button-background-hover);
        }

        &:active {
            box-shadow: var(--buttons-box-shadow);
        }

        &.white {
            color: var(--header-background-color);
            background-color: map-get($colour-palette, open-white);
            border: 1px solid var(--header-background-color);
        }

        &.skinless {
            background: none;
            width: 120px;

            &:hover,
            &:focus-visible {
                outline: 1px solid #1B75BC;
            }

            &.white {
                color: map-get($colour-palette, open-jade);
                background-color: map-get($colour-palette, open-white);
                border: 1px solid map-get($colour-palette, open-jade);

                &:hover,
                &:focus-visible {
                    outline: 1px solid #78cbc3;
                }
            }

            &.red {
                color: map-get($colour-palette, open-red);
                background-color: map-get($colour-palette, open-white);
                border: 1px solid map-get($colour-palette, open-red);

                &:hover,
                &:focus-visible {
                    outline: 1px solid map-get($colour-palette, open-brown-dark);
                }
            }

            &.green {
                color: map-get($colour-palette, open-text-success);
                background-color: map-get($colour-palette, open-white);
                border: 1px solid map-get($colour-palette, open-text-success);

                &:hover,
                &:focus-visible {
                    outline: 1px solid map-get($colour-palette, open-light-green);
                }
            }
        }

        &.red {
            background-color: var(--primary-red-button-background);
            color: map-get($colour-palette, open-white);
            border: none;

            &:hover,
            &:focus-visible {
                background: var(--primary-red-button-background-hover);
            }

            &:active {
                box-shadow: var(--buttons-box-shadow);
            }
        }

    }

    &.secondary {
        border: var(--secondary-button-border);
        color: var(--secondary-button-color);
        background: var(--secondary-button-background);

        &:hover,
        &:focus-visible {
            outline: var(--secondary-button-outline-hover);
            background: var(--secondary-button-background-hover);
        }

        &:active {
            box-shadow: var(--buttons-box-shadow);
        }

        &.white {
            color: map-get($colour-palette, open-white) !important;
            border: 1px solid map-get($colour-palette, open-white);
            background: none;

            &:hover,
            &:focus-visible {
                outline: 2px solid map-get($colour-palette, open-yellow-focus);
            }
        }

        &.skinless {
            //ghost button
            color: var(--header-background-color);
            border: none;
            background: none;

            &:hover,
            &:focus-visible {
                outline: var(--secondary-ghost-button-outline);
            }
        }

        &.cancel {
            background-color: var(--primary-red-button-background);
            color: map-get($colour-palette, open-white);
            border: none;
        }
    }


    &.tertiary {
        border: var(--tertiary-button-border);
        color: var(--tertiary-button-color);
        min-width: 134px;
        height: 30px;
        background: var(--tertiary-button-background);
        font-size: var(--global-body-14-font-size);
        font-weight: var(--tertiary-button-weight);


        &:hover,
        &:focus-visible {
            outline: var(--tertiary-button-outline-hover);
        }

        &:active {
            box-shadow: var(--buttons-box-shadow);
        }


        @media (max-width: $mobile-width) {
            width: 100px;
            font-size: var(--global-body-12-font-size);
        }

        &.worknoteView {
            background-color: map-get($colour-palette, open-white);
            color: map-get($colour-palette, open-text-primary);
            border-radius: 5px;
            height: 35px;
            margin-right: 70px;
        }

        &.worknoteAdd {
            background-color: var(--header-background-color);
            color: map-get($colour-palette, open-white);
            border-radius: 5px;
            height: 35px;
        }

        &.riskStatus {
            border: none;
            border-radius: 50px;
            font-size: var(--global-body-14-font-size);
            height: 40px;
            min-width: 140px;

            &.default {
                background-color: map-get($colour-palette, open-platinum);
                color: map-get($colour-palette, open-text-risk);
            }

            &.low {
                background-color: map-get($colour-palette, open-text-success);
                color: map-get($colour-palette, open-white);
            }

            &.amber {
                background-color: map-get($colour-palette, open-medium-risk);
                color: map-get($colour-palette, open-white);
            }

            &.high {
                background-color: map-get($colour-palette, open-high-risk);
                color: map-get($colour-palette, open-white);
            }

            &.high {
                background-color: map-get($colour-palette, open-high-risk);
                color: map-get($colour-palette, open-white);
            }

            &.critical {
                background-color: map-get($colour-palette, open-critical-risk);
                color: map-get($colour-palette, open-white);
            }
        }

        &.creditScore {
            border: none;
            border-radius: 50px;
            font-size: var(--global-body-14-font-size);
            height: 40px;
            min-width: 140px;

            &.default {
                background-color: map-get($colour-palette, open-platinum);
                color: map-get($colour-palette, open-text-risk);
            }

            &.lowest {
                background-color: map-get($colour-palette, open-text-success);
                color: map-get($colour-palette, open-white);
            }

            &.low {
                background-color: map-get($colour-palette, open-light-green);
                color: map-get($colour-palette, open-white);
            }

            &.amber {
                background-color: map-get($colour-palette, open-medium-risk);
                color: map-get($colour-palette, open-white);
            }

            &.high {
                background-color: map-get($colour-palette, open-brown-dark);
                color: map-get($colour-palette, open-white);
            }

            &.highest {
                background-color: map-get($colour-palette, open-high-risk);
                color: map-get($colour-palette, open-white);
            }
        }
    }

    &.danger {
        background-color: map-get($colour-palette, n-status-red);
        color: map-get($colour-palette, open-white);

        &:hover,
        &:focus-visible {
            outline: var(--primary-button-outline-hover);
            background: map-get($colour-palette, n-status-red);
        }

        &:active {
            box-shadow: var(--buttons-box-shadow);
        }

    }

    &.regular {
        width: 150px;
        height: 40px;
        font-size: var(--global-body-18-font-size);
    }

    &.medium {
        width: 120px;
        height: 35px;
        font-size: var(--global-body-16-font-size);

        &.tertiary {
            width: 100px;
            height: 20px;
        }
    }

    &.small {
        width: 100px;
        height: 30px;
        font-size: var(--global-body-14-font-size);

        &.tertiary {
            width: 80px;
            height: 20px;
        }

        &.edit {
            width: 40px;
            height: 20px;
        }
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }

    &.oneLineText {
        white-space: nowrap;
        text-align: center;
    }

    &.size-fit-text {
        width: fit-content;
        height: 35px;
    }
}

.button.normal-link {
    background: none;
    color: var(--header-background-color);
    text-decoration: underline;
    padding: 0 var(--gap-5-px) !important;
    width: fit-content;

    &:hover,
    &:focus-visible {
        color: var(--header-background-color);
        background: map-get($colour-palette, n-open-view-edit-remove-hover);
    }
}

.button.remove-link {
    background: none;
    color: var(--primary-red-button-background);
    text-decoration: underline;
    padding: 0 var(--gap-5-px) !important;

    &::before {
        margin-right: 5px;
        margin-top: 4px;
        content: url(../../styles/icons/red/bin.svg);

    }

    &:hover,
    &:focus-visible {
        color: var(--primary-red-button-background-hover);
        background: map-get($colour-palette, n-open-view-edit-remove-hover);
    }
}

.button.edit-link {
    background: none;
    color: var(--header-background-color);
    text-decoration: underline;
    padding: 0 var(--gap-5-px) !important;
    width: fit-content;

    &::before {
        margin-right: 5px;
        margin-top: 4px;
        content: url(../../styles/icons/blue/edit.svg);

    }

    &:hover,
    &:focus-visible {
        color: var(--header-background-color);
        background: map-get($colour-palette, n-open-view-edit-remove-hover);
    }
}

.button.view-link {
    background: none;
    color: var(--header-background-color);
    text-decoration: underline;
    padding: 0 var(--gap-5-px) !important;
    margin: 0;
    width: fit-content;

    &::before {
        margin-right: 5px;
        margin-top: 4px;
        content: url(../../styles/icons/blue/view.svg);

    }

    &:hover,
    &:focus-visible {
        color: var(--header-background-color);
        background: map-get($colour-palette, n-open-view-edit-remove-hover);
    }
}

.button.import-link {
    background: none;
    color: var(--header-background-color);
    text-decoration: underline;
    padding: 0 var(--gap-5-px) !important;
    margin: 0;
    height: 30px;

    &::before {
        margin-right: 5px;
        margin-top: 4px;
        content: url(../../styles/icons/blue/import.svg);

    }

    &:hover,
    &:focus-visible {
        color: var(--header-background-color);
        background: map-get($colour-palette, n-open-link-hover);
    }
}

.button.export-link {
    background: none;
    color: var(--header-background-color);
    text-decoration: underline;
    padding: 0 var(--gap-5-px) !important;
    margin: 0;
    height: 30px;
    width: fit-content;

    &::before {
        margin-right: 5px;
        margin-top: 4px;
        content: url(../../styles/icons/blue/export.svg);

    }

    &:hover,
    &:focus-visible {
        color: var(--header-background-color);
        background: map-get($colour-palette, n-open-link-hover);
    }
}

.button.download-link {
    background: none;
    color: var(--header-background-color);
    text-decoration: underline;
    padding: 0 var(--gap-5-px) !important;
    margin: 0;
    height: 30px;
    width: fit-content;

    &::before {
        margin-right: 5px;
        margin-top: 4px;
        content: url(../../styles/icons/blue/download.svg);

    }

    &:hover,
    &:focus-visible {
        color: var(--header-background-color);
        background: map-get($colour-palette, n-open-link-hover);
    }
}


.button.add-link {
    background: none;
    color: var(--header-background-color);
    text-decoration: underline;
    padding: 0 var(--gap-5-px) !important;
    margin: 0;
    height: 30px;

    &::before {
        margin-right: 5px;
        margin-top: 4px;
        content: url(../../styles/icons/blue/add-ellipse-2.svg);

    }

    &:hover,
    &:focus-visible {
        color: var(--header-background-color);
        background: map-get($colour-palette, n-open-link-hover);
    }
}

.button.successful {
    background: map-get($colour-palette, open-successful);
    color: black;
    width: fit-content;
    border: 1px solid map-get($colour-palette, open-successful-border);

    & .button-icon {
        height: 20px;
    }

    &:hover,
    &:focus-visible {
        outline: var(--primary-button-outline-hover);
        background: map-get($colour-palette, open-successful);
    }
}

.button.unsuccessful {
    background: map-get($colour-palette, open-unsuccessful);
    color: black;
    width: fit-content;
    min-width: 145px;
    border: 1px solid map-get($colour-palette, open-unsuccessful-border);

    & .button-icon {
        height: 24px;
    }

    &:hover,
    &:focus-visible {
        outline: var(--primary-button-outline-hover);
        background: map-get($colour-palette, open-unsuccessful);
    }

}


[data-theme="new-style"] {

    .button.successful {

        &:hover,
        &:focus-visible {
            background: darken(map-get($colour-palette, open-successful), 20%);
        }
    }

    .button.unsuccessful {

        &:hover,
        &:focus-visible {
            background: darken(map-get($colour-palette, open-unsuccessful), 20%);
        }

    }

    .button.regular {
        width: fit-content;
        min-width: 150px;
        font-size: var(--global-body-18-font-size);
        padding: 0 var(--gap-20-px);
        font-size: var(--global-body-16-font-size);
    }
}