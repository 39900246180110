.backToTopBtn {
    background-color: var(--header-background-color);
    width: 50px;
    height: 50px;
    position: fixed;
    right: 1.5%;
    bottom: 10%;
    z-index: 4;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    transition: opacity 0.5s linear;
    visibility: visible;

    &.hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.5s, opacity 0.5s linear;
    }

    &-icon {
        width: 60%;
    }
}