.navbar {
    @include flex(column, flex-start, center, nowrap);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 3;

    #skipper {
        color: map-get($colour-palette, open-white);
        height: 30px;
        left: 50%;
        padding: 8px;
        position: absolute;
        transform: translateY(-100%);
        transition: transform 0.3s;
    }

    #skipper:focus {
        transform: translateY(0%);
    }

    &-primary-navigation {
        @include flex(row, space-between, center, nowrap);
        background-color: var(--header-background-color);
        background-image: var(--header-background-image);
        width: 100%;
        height: 60px;
        padding: 0 var(--gap-40-px);
        @include boxSizing(border-box);

        @media (max-width: $tablet-width) {
            height: 80px;
            padding: 0 var(--gap-20-px);
            margin: 0;
        }

        @media (max-width: $mobile-width) {
            height: 80px;
            padding: 0 var(--gap-10-px);
            margin: 0;
        }

        .logo {
            @include flex(row, flex-start, center, nowrap);
            cursor: pointer;

            img {
                height: 50px;
            }

            @media (max-width: $tablet-width) {
                img {
                    height: 55px;
                }
            }

            @media (max-width: $mobile-width) {
                img {
                    height: 50px;
                }
            }

            // All beta CSS. Can be removed safely once out of beta.
            .betabox {
                background-color: map-get($colour-palette, open-white);
                padding: var(--gap-5-px);
                border: 1px solid map-get($colour-palette, open-white);
                border-radius: 2px;
                color: var(--header-background-color);
                margin-right: var(--gap-10-px);
                display: none;

                // This is a custom width due to the size of the navbar.
                @media (max-width: 1100px) {
                    display: block;
                }

            }

            .betatext {
                color: map-get($colour-palette, open-white);

                a {
                    color: inherit;
                }

                display: none;

                // This is a custom width due to the size of the navbar.
                @media (max-width: 1100px) {
                    display: block;
                }

                @media (max-width: $mobile-width) {
                    position: absolute;
                    left: 1px;
                    top: 1px;
                }

                @media (max-width: 355px) {
                    // This conflicts with scroll to top, so we don't show
                    // any text at this size.
                    display: none;
                }
            }
        }

        #navMenuItems {
            @include flex(row, center, space-between, nowrap);
            @include boxSizing(border-box);
        }

        #additionalItems {
            @include flex(row, center, center, nowrap, var(--gap-10-px));
            @include boxSizing(border-box);

            @media (max-width: $tablet-width) {
                margin-right: var(--gap-10-px);
            }

            #notificationWrapper {
                position: relative;
                @include flex(row, flex-start, center, nowrap);


                #notificationsContainer {
                    position: absolute;
                    top: 35px;
                    left: -175px;
                    width: 350px;
                    max-height: 470px;
                    overflow-y: auto;
                    z-index: 1;

                    @media (max-width: $tablet-width) {
                        left: -300px;
                    }
                }
            }

            #userNavMenuWrapper {
                position: relative;
                margin-left: var(--gap-50-px);
                @include flex(row, flex-start, center, nowrap);

                @media (max-width: $tablet-width) {
                    display: none;
                }

                #userNavMenuContainer {
                    position: absolute;
                    top: 35px;
                    left: -70px;
                    width: 220px;
                    height: fit-content;
                    height: -moz-fit-content;
                    @include boxSizing(border-box);
                }
            }

            #loginBtn {
                width: 70px;
                margin: 0.25rem 10px;

                @media (max-width: $tablet-width) {
                    display: none;
                }
            }

            #getStartedBtn {
                border-radius: 50px;
                width: 120px;
                margin: 0.25rem 0;

                @media (max-width: $tablet-width) {
                    display: none;
                }
            }

            // @media (max-width: $tablet-width) {
            //     display: none;
            // }
        }
    }

    &-secondary-navigation {
        width: 100%;
        height: 40px;
        // background-color: map-get($colour-palette, open-jade);
        background-color: var(--header-background-color);
        background-image: var(--header-background-image);
        border-top: 1px solid white;

        // All beta CSS. Can be removed safely once out of beta.
        .betabar {
            @include flex(row, flex-start, center, nowrap);
            position: absolute;
            left: 5px;
            top: 64.2px;

            // This is a custom width due to the size of the navbar.
            @media (max-width: 1366px) {
                max-width: 300px;
            }

            @media (max-width: 1100px) {
                display: none;
            }

            .betabox {
                background-color: map-get($colour-palette, open-white);
                padding: var(--gap-5-px);
                border: 1px solid map-get($colour-palette, open-white);
                border-radius: 2px;
                color: var(--header-background-color);
                margin-right: var(--gap-10-px);
            }

            .betatext {
                color: map-get($colour-palette, open-white);

                a {
                    color: inherit;
                }
            }
        }

        .items {
            @include flex(row, center, center, nowrap, var(--gap-20-px));
            list-style: none;
            margin: 0 var(--gap-20-px);
            padding: 0;
            height: 100%;
            @include boxSizing(border-box);
            flex: auto;
        }

        @media (max-width: $tablet-width) {
            display: none;
        }
    }
}

.navbar.new-navbar {
    height: auto;

    #skipper {
        display: none;
    }

    & .navbar-primary-navigation {
        height: auto;
        padding-top: var(--gap-10-px);
        padding-bottom: var(--gap-10-px);
        align-items: center;

        .logo img {
            height: 50px;
        }

        @media (max-width: $laptop-width) {
            .logo img {
                height: 50px;
            }
        }

        @media (max-width: $mobile-width) {
            padding: var(--gap-20-px) var(--gap-10-px);

            .logo img {
                height: 45px;
            }
        }

        & #additionalItems {


            & #notificationWrapper,
            & #userNavMenuWrapper {
                padding: var(--gap-10-px);

                &:hover {
                    background-color: rgba(map-get($colour-palette, n-open-hover-menu-header), 0.2);
                    border-radius: 5px;
                }
            }

            & #loginBtn {
                text-decoration: underline;
                text-underline-offset: 5px;
                margin: 0;
                padding: 0;

                &:hover {
                    outline: 2px solid map-get($colour-palette, n-open-yellow-strong);
                    border: none;
                }
            }

            & #getStartedBtn {
                border-radius: 5px;
                font-weight: 600;
                background-color: map-get($colour-palette, n-open-jade);
                color: map-get($colour-palette, n-open-blue);
                margin: 0;

                &.button.primary:hover,
                &.button.primary:focus-visible {
                    outline: 2px solid map-get($colour-palette, n-open-yellow-strong);
                    border: none;
                }
            }
        }

    }

    & .navbar-secondary-navigation {

        border-top: 1px solid #ffffff40;

        & .betabar {
            top: 4.7rem;
        }

        & .item-active-strip {
            height: 2px;
            bottom: 1px;
        }
    }

}

.navbar.new-navbar.logged-navbar {


    & .navbar-primary-navigation {
        & #additionalItems {
            gap: var(--gap-20-px);

            & #userNavMenuWrapper {
                margin: 0;
            }
        }
    }

    & .item-link.title {
        // font-size: var(--global-body-14-font-size);
    }

    & #userNavMenuButtonContainer #userNavMenuToggleButton {
        color: map-get($colour-palette, n-open-white);
        gap: var(--gap-10-px);

        #userNavMenuToggleBtnLabel {
            margin: 0;
        }
    }

    & #notificationsButtonContainer {

        & #notificationToggleButton {
            color: map-get($colour-palette, n-open-white);
            gap: var(--gap-10-px);

            & #notificationToggleBtnLabel {
                margin: 0;
            }

            & #bellIcon {

                @media (max-width: $tablet-width) {
                    height: 20px;
                }

            }
        }

        & #notificationsCounter {

            position: absolute;
            right: 0px;
            top: 0px;
            color: #fff;
            font-size: var(--global-body-10-font-size);
            width: 15px;
            height: 15px;
            background-color: #DF1010;
            padding: 0.2rem;

            @media (max-width: $tablet-width) {
                display: inherit;
            }

        }
    }

    & #additionalItems #userNavMenuWrapper {
        margin: 0;
    }

}