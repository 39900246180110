.contractOverviewContainer {
  @include flex(row, space-between, stretch, wrap, var(--gap-20-px));
  width: 100%;

  #contractDetails {
    height: 100%;

    .dashboardCardMain {
      overflow: hidden;

      .sectionHeader {
        margin-top: var(--gap-20-px);
        color: #2c4874;
      }

      .sectionContent {
        padding-top: 0;
        padding-right: 0;
        padding-left: var(--gap-20-px);
        padding-bottom: var(--gap-20-px);
        border-bottom: 1px solid map-get($colour-palette, open-divider);

        .sectionRow.supplier {
          width: unset;
          max-width: unset;
          padding-right: var(--gap-30-px);

          &>div {
            flex: 1 1 0;
          }

          .sectionRowPanel {
            display: flex;
            align-items: baseline;
            width: 50%;

            &>.title,
            &>.caption {
              line-height: var(--line-height);
            }

            &>.title {
              white-space: nowrap;
            }
          }

          .sectionLabel,
          .sectionValue {
            width: unset;
            max-width: unset;
            display: block;
            margin-right: var(--gap-20-px);
            word-wrap: normal;
            word-break: keep-all;

            &.larger {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }

  .pill-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
  }

  .lotSectionLabel {
    max-width: auto;
    font-family: var(--font-family);
    line-height: 1.6;
    font-size: var(--global-body-16-font-size);
    color: var(--header-background-color);
    font-weight: 600;
  }
}

.cap-first-letters {
  text-transform: capitalize;
}