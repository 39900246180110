.ssTimerContainer {
  width: 100%;
  @include flex(row, center, center, nowrap);
  // margin-bottom: 1rem;

  @media (max-width: $tablet-width) {
    @include flex(row, center, center, wrap);
  }

  .timerDivider {
    padding: var(--gap-10-px) 0;
    border-right: 1px solid map-get($colour-palette, open-divider);
  }

  .timerTile {
    @include flex(row, center, center, nowrap);
    background-color: map-get($colour-palette, open-white);
    // padding: 2rem;
    margin: var(--gap-10-px);
    // color: var(--header-background-color);
    text-align: center;

    #time {
      color: var(--header-background-color);
      margin-right: var(--gap-5-px);
    }

    &.content {
      white-space: nowrap;

      @media (max-width: $tablet-width) {
        padding: var(--gap-20-px);
      }
    }
  }
}