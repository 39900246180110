#RebateDetailsForm {
    .section-divide {
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        margin-bottom: 2em;
    }

    .customDateInput {
        width: 300px;

        @media (max-width: $tablet-width) {
            max-width: 300px;
            width: 100%;
        }

        @media (max-width: $mobile-width) {
            max-width: 250px;
            width: 100%;
        }
    }

    .labelled-input,
    .labelled-select {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: var(--gap-10-px);

        .form-label {
            flex: 1;
            padding: 0.5em 1em 0.5em 0;
        }

        .form-input,
        .form-select {
            width: 300px;

            @media (max-width: $tablet-width) {
                max-width: 300px;
                width: 100%;
            }

            @media (max-width: $mobile-width) {
                max-width: 250px;
                width: 100%;
            }
        }

        .form-input,
        .form-select {
            border: 1px solid var(--header-background-color);
            padding: var(--gap-5-px);
        }
    }

    #addAnotherAuthorityBtn {
        justify-content: left;
        width: 250px;
    }
}