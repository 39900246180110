.bdContentSection {
    padding: var(--gap-10-px);
    align-items: flex-start;

    & img {
        max-width: 100%;
    }

    & h2 {
        font-size: var(--global-body-24-font-size);
        font-weight: 400;


        & strong {
            font-weight: 600;
        }
    }

    & h3 {
        font-size: var(--global-body-24-font-size);
        font-weight: 400;
        margin-bottom: var(--gap-20-px);

        & strong {
            font-weight: 600;
        }
    }

    & .about-us-means {
        @include flex(row, flex-start, space-around, nowrap, var(--gap-20-px));
        background-color: map-get($colour-palette , n-open-base-blue);
        padding: var(--gap-40-px);
        margin-bottom: var(--gap-40-px);


        &>div {
            flex: 1;
        }

        @media (max-width: $mobile-width) {
            flex-wrap: wrap;
            padding: var(--gap-20-px);

            &>div {
                flex: auto;
            }
        }

    }

    & ul.body {
        list-style-type: none;

        & li {
            margin-left: var(--gap-10-px);
            margin-bottom: var(--gap-10-px);

            &:before {
                content: "· ";
                font-size: var(--global-body-24-font-size);
                vertical-align: middle;
                line-height: 20px;
            }
        }


    }

    & .img-footer {
        font-style: italic;
    }

    & .about-us-banner {
        background: var(--header-background-color);
        padding: var(--gap-40-px);
        margin-top: var(--gap-40-px);
        @include flex(row, space-between, center, nowrap);

        &>div {
            display: flex;
            flex-direction: column;
            gap: var(--gap-30-px);
        }

        & label {
            color: map-get($colour-palette , n-open-white);
            font-family: var(--font-family-h);
            font-size: var(--global-body-34-font-size);
            font-weight: 700;
        }

        & img {
            width: 300px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            @media (max-width: $mobile-width) {
                display: none;
            }
        }

        & .started-button {
            width: 240px;
            height: 60px;
            border-radius: 50px;
            border: none;
            font-family: NotoSans-Bold;
            font-size: var(--global-body-28-font-size);
            background: none;
            border: 3px solid #d3b04a;
            color: #fff;

            &:hover,
            :focus-visible {
                border: 3px solid map-get($colour-palette, open-skyblue);
                outline: none;
            }
        }

        & .started-button.new-started-button {
            background-color: map-get($colour-palette, n-open-yellow-strong);
            border: none;
            border-radius: 5px;
            font-family: var(--font-family);
            font-weight: 600;
            color: map-get($colour-palette, n-open-blue);
            font-size: var(--global-body-20-font-size);
            width: 240px;
            padding-right: var(--gap-30-px);
            padding-left: var(--gap-20-px);
            display: flex;
            align-items: center;

            &::before {
                content: url("../../styles/icons/blue/chevron-right-ellipse.svg");
                width: auto;
                height: auto;
                margin-top: 5px;
                margin-right: 10px;
            }

            &:hover {
                background-color: map-get($colour-palette, n-open-dark-yellow);
            }

        }
    }
}

.about-us {
    &-title {
        margin-top: var(--gap-30-px);
    }

    &-content {
        margin-top: var(--gap-20-px);
    }

    &-list {
        padding-left: var(--gap-40-px);
        list-style-type: disc;
    }

}

.prevButton {
    position: relative;
    height: 35px;
    width: 50px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 1;
    cursor: pointer;
    font-size: var(--global-body-10-font-size);
    opacity: 0.6;
    transition: opacity 0.25s;
    right: initial;
    border: white;

    @media (max-width: $tablet-width) {
        width: 65px;
    }


    @media (max-width: $mobile-width) {
        width: 125px;
    }

    .prevButtomImage {
        height: 15px;
        width: 10px;
        background: transparent;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

.nextButton {
    position: relative;
    height: 35px;
    width: 50px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 1;
    cursor: pointer;
    font-size: var(--global-body-10-font-size);
    opacity: 0.6;
    transition: opacity 0.25s;
    border: white;

    @media (max-width: $tablet-width) {
        width: 65px;
    }

    @media (max-width: $mobile-width) {
        width: 125px;
    }


    .nextButtomImage {
        height: 15px;
        width: 10px;
        background: transparent;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

.container {
    @include flex(row, center, center, nowrap);
    margin: var(--gap-40-px) auto;
    width: 80%;

    @media (max-width: $tablet-width) {
        width: 90%;
    }

    .group {
        display: flex;
        align-items: center;

        .column {
            flex: 1;
            color: white;
            text-align: center;

            img {
                width: 60%;
            }
        }
    }
}