.breadcrumbsContainer {
	width: 100%;
	@include flex(row, flex-start, flex-start, wrap, var(--gap-10-px));
	margin-bottom: var(--gap-20-px);
	margin-left: var(--gap-5-px);

	.breadcrumbItem {
		display: inline-block;
		position: relative;
	}

	.breadcrumbLink {
		display: inline-block;
		cursor: pointer;
		color: map-get($colour-palette, open-text-primary);
		text-decoration: none;
		margin-right: var(--gap-10-px);
		vertical-align: middle;
	}

	.breadcrumbLink:hover {
		text-decoration: underline;
	}

	.breadcrumbCurrentPage {
		color: map-get($colour-palette, open-blue);
		font-weight: 600;
	}

	.breadcrumbImg {
		width: 1.625em;
		margin-right: var(--gap-10-px);
		vertical-align: top;
	}

	.breadcrumbArrow {
		width: .375em;
	}

	.breadcrumbEllipsis {
		max-width: 250px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.breadcrumbEllipsis[data-title]:before {
		content: attr(data-title);
		position: absolute;
		top: -35px;
		left: calc(50% - var(--gap-15-px));
		transform: translateX(-50%);
		background-color: #333;
		color: #fff;
		padding: 4px 8px;
		border-radius: 4px;
		font-size: 14px;
		font-weight: normal;
		white-space: nowrap;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.3s, visibility 0.3s;
	}

	.breadcrumbEllipsis[data-title]:after {
		content: "";
		position: absolute;
		top: -3px;
		left: 45%;
		transform: translateX(-50%);
		border-width: 6px;
		border-style: solid;
		border-color: #333 transparent transparent transparent;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.3s, visibility 0.3s;
	}

	.breadcrumbEllipsis[data-title]:hover::before,
	.breadcrumbEllipsis[data-title]:hover::after {
		opacity: 1;
		visibility: visible;
	}
}
