.homepageBannerContainer {
    background-color: map-get($colour-palette, open-base-blue);
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: var(--gap-15-px);
    position: relative;
    z-index: 1;
    text-align: center;
    color: var(--header-background-color);
    align-items: center;
    gap: var(--gap-30-px);

    @media (max-width: $mobile-width) {
        flex-direction: column;
        gap: var(--gap-15-px);
    }

    & .hpBannerText1 {
        font-size: var(--global-body-14-font-size);
        line-height: var(--line-height);
    }

    & .hpBannerTextBold {
        font-weight: bold;
    }

    & .button {
        background-color: transparent;
        border: 1px solid var(--header-background-color);
        border-radius: 50px;
        min-width: 120px;
        color: var(--header-background-color);
        font-size: var(--global-body-14-font-size);

        &:hover {
            outline: 2px solid var(--header-background-color);
            background: none;
        }
    }
}