#suggestedOpportunities {
  height: 560px;

  @media (max-width: $tablet-width) {
    height: auto;

  }

  @media (max-width: $mobile-width) {
    height: auto;

  }
}


.dashboardContainer {
  .dashboardCard {
    &.medium {
      &.customStyle {
        height: 560px;

        @media (max-width: $mobile-width) {
          height: auto;

        }
      }
    }

    &.small {
      &.customStyle {
        height: 560px;

        @media (max-width: $mobile-width) {
          height: auto;

        }
      }
    }

  }
}

#suggestedOpportunities.dashboardCard .dashboardCardMain .skeletonRow {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.dashboardContainer.new-dashboardContainer {
  .dashboardCard {
    &.medium {
      &.customStyle {
        height: 550px;

        @media (max-width: $mobile-width) {
          height: auto;

        }
      }
    }

    &.small {
      &.customStyle {
        height: 550px;

        @media (max-width: $mobile-width) {
          height: auto;

        }
      }
    }

  }

  & #suggestedOpportunities {
    height: 550px;

    @media (max-width: $tablet-width) {
      height: auto;

    }

    @media (max-width: $mobile-width) {
      height: auto;

    }

  }

}