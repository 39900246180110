#pageHeader {
    @include flex(column, center, flex-start, nowrap);
    //background: var(--header-background-color);
    background-image: var(--header-background-image);
    background-color: var(--header-background-color);
    border-radius: 10px;
    min-height: 100px;
    max-height: 100px;
    color: map-get($colour-palette, open-white);
    padding: var(--gap-20-px) var(--gap-40-px);
    @include boxSizing(border-box);
    width: 100%;
    margin: var(--gap-30-px) 0;

    & h1 {
        font-size: var(--global-body-28-font-size);
    }


    @media (max-width: $tablet-width) {
        min-height: 120px;
        margin: var(--gap-20-px) 0;
        padding: var(--gap-20-px) var(--gap-30-px);
    }

    @media (max-width: $mobile-width) {
        min-height: 100px;
        margin: var(--gap-10-px) 0;
        padding: var(--gap-20-px);
    }

    #userAccountName {
        font-family: NotoSans-Light;
        font-size: var(--global-body-28-font-size);
        margin-top: var(--gap-10-px);

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-26-font-size);
            margin-top: var(--gap-10-px);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-22-font-size);
            margin-top: var(--gap-10-px);
        }
    }
}

#pageHeader.new-page-header {
    min-height: auto;
    padding: var(--gap-20-px);
    margin: 1.25rem 0;

    #userAccountName {
        font-family: var(--font-family-h);
        font-size: var(--global-body-20-font-size);
        margin-top: var(--gap-10-px);
        font-weight: 400;
    }
}