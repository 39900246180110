#duForm {
    .modal-container {
        .modal {
            .modal-content {
                .fileUpload {
                    @include flex(column, center, center, nowrap);
                    border: 1px dashed var(--header-background-color);
                    border-radius: 10px;
                    vertical-align: middle;

                    img {
                        margin-top: var(--gap-40-px);
                    }

                    .labelled-input {

                        margin-top: 0;

                        #fileUpload-input::before {
                            text-align: center;
                        }

                        input[type="file"] {
                            border: none;
                            height: auto;
                            @include flex(column, center, center, nowrap);
                        }
                    }
                }

                .uploadedFile {
                    @include flex(row, space-between, flex-start, nowrap);
                }

                .nmiDate-imput {
                    margin-bottom: 12px;
                }

                .upload-expiry {
                    margin-bottom: var(--gap-20-px);
                    margin-top: var(--gap-10-px);
                }
            }
        }
    }

    .documentUpload-content>.caption {
        padding: var(--gap-10-px) var(--gap-15-px);
        text-align: justify;
    }
}

#downloadMultipleDocuments {
    margin-top: var(--gap-20-px);
    float: right;
}