.radio-title {
    font-weight: var(--input-label-font-weight);
    color: var(--input-label-color);
}

.labelled-radio-button {
    @include flex(column, flex-start, flex-start, nowrap);

    .form-caption {
        margin-bottom: var(--gap-10-px);
    }

    .labelled-radio {
        @include flex(column, flex-start, start, nowrap);

        &.row {
            @include flex(row, flex-start, center, nowrap, var(--gap-20-px));


        }

        .form-radio-button-container {
            @include flex(row, flex-start, center, nowrap);
            position: relative;

            @media (max-width: $mobile-width) {
                display: block;
            }

            .form-label {
                margin: var(--gap-10-px) 0;
                flex: none;
                cursor: pointer;
                width: fit-content;
                word-break: break-word;
            }

            .radio-label {
                padding: 0 0 0 3.6rem;
            }

            input[type="radio"] {
                border: 0;
                clip: rect(0 0 0 0);
                height: 0.1rem;
                margin: -0.1rem;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 0.1rem;
            }

            input[type="radio"]+.form-label {
                display: block;
                position: relative;
                padding: 0 0 0 var(--gap-30-px);
            }

            input[type="radio"]+.form-label:before {
                left: 0;
                content: "";
                display: inline-block;
                width: 21px;
                height: 21px;
                background-color: map-get($colour-palette, open-white);
                margin-right: var(--gap-15-px);
                position: absolute;
                top: 0rem;
                border: 1px solid map-get($colour-palette, open-green);
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-clip: padding-box;
            }

            input[type="radio"]:checked+.form-label:before {
                border-width: 1px;
                border-color: map-get($colour-palette, open-green);
            }

            input[type="radio"]:focus+.form-label:before {
                outline: 1px solid black;
            }

            input[type="radio"]:checked+.form-label:after {
                content: "";
                display: inline-block;
                width: 21px;
                height: 21px;
                background-image: radial-gradient(map-get($colour-palette, open-green) 0%, map-get($colour-palette, open-green) 50%, transparent 55%);
                position: absolute;
                border-radius: 50%;
                top: 1px;
                left: 1px;
            }

            input[type="radio"]:disabled+.form-label:before {
                border-color: map-get($colour-palette, open-grey);
            }

            input[type="radio"]:disabled+.form-label {
                color: map-get($colour-palette, open-grey);
            }
        }
    }
}