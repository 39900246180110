.suppliers-trees-award-info {
    width: 100%;
}

.headerEvaluationContainer #back {
    margin-left: auto;
    margin-right: 20px;
}

.award-summary-tree-supplier {
    width: 100%;
    margin-top: var(--gap-20-px);
    border: 1px solid map-get($colour-palette, n-card-border);
    border-radius: 10px;
    padding: 0;

    &>div {
        padding-bottom: 0;
    }

    & div[aria-level='0']>a {
        color: #2c4874;
        font-size: var(--global-body-18-font-size);
        font-weight: bold;
        flex-wrap: wrap;
        padding-left: var(--gap-20-px);
        padding-right: var(--gap-20-px);
        border-bottom: 1px solid map-get($colour-palette, n-card-border);

        & .tree-view-menu {
            margin-right: inherit;
            margin-left: auto;
        }

        & p.title {
            font-size: var(--global-body-18-font-size);
        }
    }

    & div[aria-expanded='false']>a {
        border-bottom: none;
    }

    & div[aria-level='1']>a {
        padding-left: var(--gap-20-px);
        color: #2c4874;
        font-weight: 600;
        row-gap: var(--gap-10-px);

        & .tree-view-menu {
            margin-left: inherit;
            margin-right: auto;
        }
    }

    & div[aria-level='1'] {
        &>.tree-view-child {
            display: inherit;
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }

    & .redButton {
        background: #ce0000;
        color: #fff;
    }

    & .statuslabel-txt {
        font-size: var(--global-body-16-font-size);
    }

    & .user-icon {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }

    & .submission-label span {
        display: block;
        margin-top: var(--gap-5-px);
        font-weight: normal;
        font-size: var(--global-body-13-font-size);
    }

    & .title {
        width: fit-content;
    }

    &.statuslabel-txt-container a {
        text-transform: capitalize;
    }
}

.award-info-data-upload {
    margin: 0;
    border-radius: 0px 0px 10px 10px;
    padding: var(--gap-20-px);
}

.sbAccordionSectionContentDataAwardRowDPS {
    @include flex(row, flex-start, flex-start, wrap, var(--gap-20-px));


}

.award-summary-tree-submissions {
    width: 100%;
    padding-bottom: 0rem;
    border-radius: 0 0 10px 10px;
    padding: 0;

    & div[aria-level='0']>a {
        padding-left: var(--gap-20-px);
        color: #0b0c0c;
        font-weight: 600;
        row-gap: var(--gap-10-px);
        font-size: var(--global-body-14-font-size);

        & .tree-view-menu {
            margin-right: inherit;
            margin-left: auto;
        }
    }

    & div[aria-level='1']>a {
        padding-left: var(--gap-40-px);
        color: #2c4874;
        font-weight: 600;
        row-gap: var(--gap-10-px);
        padding-right: var(--gap-20-px);

        & .button {
            width: auto;
            padding-right: var(--gap-20-px);
            padding-left: var(--gap-20-px);
        }

        @media only screen and (max-width : 700px) {
            padding-left: var(--gap-20-px);
        }
    }

    & div[aria-level='1'] .tree-view-menu span {
        color: #0b0c0c;
        font-weight: normal;
    }

    & div[aria-level='2'] {
        &>div {
            padding-left: var(--gap-60-px);
            background: #f2f6f9;
            padding-right: var(--gap-20-px);
            flex-wrap: wrap;
            row-gap: var(--gap-20-px);
            border-radius: 0 0 10px 10px;

            @media only screen and (max-width : 700px) {
                padding-left: var(--gap-20-px);
            }
        }

        & .tree-view-child {
            display: flex;

            @media only screen and (max-width : 700px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        & .tree-view-menu {
            @media only screen and (max-width : 700px) {
                flex-wrap: wrap;
                align-items: flex-start;
            }
        }

    }

    & .tree-viewer-item>a .tree-view-menu {
        margin-left: auto !important;
        margin-right: inherit !important;

        @media only screen and (max-width : 700px) {
            display: block;

            &>div {
                margin-bottom: var(--gap-10-px);
            }
        }
    }

    & .redButton {
        background: #ce0000;
        color: #fff;
    }

    & .statuslabel-txt {
        font-size: var(--global-body-16-font-size);
    }

    & .user-icon {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }

    & .submission-label span {
        display: block;
        margin-top: var(--gap-5-px);
        font-weight: normal;
        font-size: var(--global-body-13-font-size);
    }

    &.statuslabel-txt-container a {
        text-transform: capitalize;
    }
}

.prev-submissions-sent {
    margin: 0;

    & .uploadContainer {
        background-color: transparent;
    }

    & .upFormInputContainer {
        display: flex;
        gap: var(--gap-15-px);
        padding: var(--gap-5-px);
        width: 100% !important;
        max-width: 100% !important;
        align-items: center;
    }

    & .flex-break {
        display: none;
    }

    & .formLabel {
        margin: 0 !important;
        padding: 0 !important;

        & p {
            font-size: var(--global-body-14-font-size);
            font-weight: 600;
        }
    }

    & .formCaption {
        margin: 0 !important;
        padding: 0 !important;

        & p {
            font-size: var(--global-body-14-font-size);
        }
    }

    & .upDocumentButtonContainer {
        margin: 0 !important;
        padding: 0 !important;

        & p {
            font-size: var(--global-body-14-font-size);
        }
    }
}