#projectOverview {
  @include flex(row, space-around, stretch, wrap);
  width: 100%;

  .modal-container .modal-content .labelled-textarea .form-textarea {
    width: 100%;
  }

  #faqModal,
  #registerRequestModal,
  #escalate {
    max-height: 525px;
  }

  .progress-bar {
    margin-bottom: var(--gap-30-px);
  }

  .projectOverviewContainer {
    @include flex(row, space-between, stretch, wrap, var(--gap-20-px));
    width: 100%;

    .dashboardCard {

      &.small,
      &.medium {
        height: 410px;
      }
    }

    #steeringSection .button {
      @include flex(row, flex-end, center, nowrap);
    }

    #projectDetailsEvent,
    #projectDetails {
      height: auto;
      min-height: 590px;

      .dashboardCardHeader {

        @include boxSizing(border-box);

        .dashboardHeaderText {
          margin-left: -var(--gap-20-px);
          word-break: break-word;
        }

        .dashboardHeaderBtn {
          @include flex(row, space-between, center, nowrap);

          @media (max-width: $mobile-width) {
            @include flex(column, space-between, center, nowrap);

            .button:first-child {
              margin-right: 0rem;
              margin-bottom: var(--gap-20-px)
            }
          }
        }
      }

      #error {
        padding-left: var(--gap-20-px);
      }
    }

    .dashboardCardMain #noWorkflow,
    #noLot {
      @include flex(column, center, center, nowrap);
      height: 100%;

      #workFlowText,
      #lotText {
        margin: var(--gap-20-px);
        margin-bottom: 0;
      }
    }

    .dashboardCardMain {
      padding: 3px;
    }

    #steeringGroupEvent .sectionContent {
      padding: var(--gap-10-px) 0 var(--gap-20-px);
    }

    #interestRemoved .sectionContent {
      padding: var(--gap-10-px) 0 var(--gap-20-px);
    }

    #steeringGroupEvent .button {
      @include flex(row, flex-end, center, nowrap);
    }

    #faqEvents #dashbaordActionBtn {
      @include flex(row, flex-end, center, nowrap);
    }

    #registerInterestRequests .button {
      @include flex(row, center, center, nowrap);
    }

    .sectionContent {
      padding: 0;
      border: none;
      box-sizing: border-box;
      word-break: break-word;
      @include flex(row, center, flex-start, wrap, var(--gap-10-px));
      flex-direction: column;

      @media (max-width: $mobile-width) {
        padding-bottom: var(--gap-10-px);
      }

      &Tags {
        @include flex(row, flex-start, flex-start, wrap, var(--gap-10-px));
        flex: 1;
      }

      .sectionRow {
        @include flex(row, flex-start, flex-start, nowrap, var(--gap-20-px));
        margin: 0;
        width: 100%;

        @media (max-width: $mobile-width) {
          flex-wrap: wrap;

          p {
            width: 100%;

          }
        }

        .sectionLabel {
          width: 100%;

          @media (min-width: $mobile-width) {
            max-width: 230px;
          }

          @media (min-width: $laptop-width) {
            max-width: 350px;
          }


        }

        .sectionValue {
          width: 100%;
          word-break: break-word;
          color: map-get($colour-palette, open-text-primary);
        }
      }
    }

    .taskEventContentContainer {
      @include flex(column, space-between, space-between, nowrap);
      width: 100%;
      max-height: 520px;
      padding-bottom: var(--gap-10-px);
      @include boxSizing(border-box);

      @media (max-width: $mobile-width) {
        padding-bottom: var(--gap-15-px);
      }

      @media (max-width: $tablet-width) {
        #regIntReqBtnContainer {
          .button {
            margin-right: 0;
          }

          .actionBtnContainer {
            @include flex(row, center, center, nowrap);

            button {
              background: transparent !important;
              color: var(--header-background-color);
            }

            @media (max-width: $mobile-width) {
              .button {
                height: fit-content;
                height: -moz-fit-content;
                width: 80px;
              }
            }
          }
        }
      }

      .registerItem {
        @include flex(column, space-between, space-between, nowrap);
        margin: var(--gap-10-px) 0;

        @media (max-width: $tablet-width) {
          @include flex(row, space-between, center, nowrap);
        }

        #regIntReqBtnContainer {
          @include flex(row, space-between, space-between, nowrap);
          margin-top: var(--gap-10-px);

          .button {
            max-width: 120px;
            width: 120px;
            height: 30px;
            font-size: var(--global-body-14-font-size);
          }

          .button:first-of-type {
            width: 120px;
            max-width: 120px;
            margin-right: 5%;
          }

          @media (max-width: $tablet-width) {
            @include flex(column, space-between, flex-end, nowrap);
            margin-right: var(--gap-15-px);

            .button:first-of-type {
              margin-right: 0;
              margin-bottom: var(--gap-10-px);
            }

            .button:nth-child(2) {
              margin-bottom: var(--gap-10-px);
            }
          }

          .button:last-of-type {
            margin-left: var(--gap-10-px);
          }
        }

        #regIntBtnContainer {
          @include flex(row, space-between, space-between, nowrap);
          margin-top: var(--gap-10-px);

          .button {
            max-width: 120px;
            width: 120px;
            height: 30px;
            font-size: var(--global-body-14-font-size);
          }

          .button:first-of-type {
            width: 120px;
            max-width: 120px;
            margin-right: 5%;
          }

          @media (max-width: $tablet-width) {
            @include flex(column, space-between, flex-end, nowrap);
            margin-right: var(--gap-15-px);

            .button:first-of-type {
              margin-right: 0;
              margin-bottom: var(--gap-q0-px);
            }

            .button:nth-child(2) {
              margin-bottom: var(--gap-q0-px);
            }
          }

          .button:last-of-type {
            margin-left: var(--gap-10-px);
          }
        }
      }

      .documentItem {
        @include flex(row, space-between, center, nowrap);
        width: 100%;
        margin: var(--gap-10-px) 0;

        #documentBtn {
          font-size: var(--global-body-14-font-size);
        }
      }

      .documentItem:last-of-type {
        margin-bottom: 0;
      }
    }

    #projectActions {
      height: auto;

      #projectActionContainer {
        @include flex(row, flex-end, center, nowrap, var(--gap-20-px));
        // width: 100%;

        @media (max-width: $mobile-width) {
          @include flex(column, space-between, center, nowrap);
        }

        .cancelButtonContainer {
          @include flex(row, flex-start, center, nowrap);

          @media (max-width: $mobile-width) {
            @include flex(row, space-between, space-between, nowrap);
          }
        }



        .moreActionContainer {
          @include flex(row, flex-end, center, nowrap);
          width: 100%;

          button {
            margin-left: var(--gap-30-px);
          }

          @media (max-width: $mobile-width) {
            @include flex(row, space-between, space-between, nowrap);
            padding-top: var(--gap-10-px);

            button {
              margin-left: 0;
            }
          }
        }
      }
    }

    #projectGuidanceContainer {
      width: 100%;

      .guidanceBannerContainer {
        width: auto;
      }

      .dashboardCardMain {
        margin-bottom: 0;
        padding: 0;

        .guidanceBannerContainer {
          margin-bottom: 0;
        }
      }

      #publishEventGuidance {
        .publish-icon {
          height: 20px;
          width: 20px;
        }

        .showButton {
          button {
            width: auto;
            margin-right: 0;
          }
        }
      }

      #missingSteeringGroup {
        .showButton {
          button {
            width: auto;
            margin-right: 0;
          }
        }
      }
    }

    #projectGuidanceContainer>* {
      height: auto;
    }

    #projectActivityLog {
      .logItem {
        @include flex(column, flex-start, flex-start, nowrap);

        .headerTitle {
          margin: 0;
        }
      }
    }
  }

  #escalationModal {
    .escalationReasonItem:not(:last-child) {
      margin-bottom: var(--gap-20-px);
    }
  }

  .projectOverviewWorkflowContainer {
    @include flex(row, space-between, stretch, nowrap, var(--gap-20-px));

    @media (max-width: $mobile-width) {
      @include flex(column, space-between, stretch, nowrap);
    }

    width: 100%;

    .dashboardCard {

      &.small,
      &.medium {
        height: 410px;
      }
    }

    .leftContainer {

      #actions {
        #projectActionContent {

          #worknoteContent,
          #correspondenceContent {
            margin-top: 0;



            &.unread {
              border-radius: 5px;
              padding: var(--gap-10-px);
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 5px 0px 0px map-get($colour-palette, open-text-error);
            }
          }
        }

        .uploadContainer {


          .upFormInputContainer {
            width: 100%;
            max-width: 100%;

            .flex-break {
              margin: 0;
              height: 0;
            }

            .upDocumentButtonContainer {
              padding: 0;
              margin: 0;
            }

            & .formLabel {
              margin-bottom: 0;
            }
          }


        }
      }
    }

    #workflowEvent {
      height: 1270px;
    }
  }

  #publishEventModal {
    max-width: 730px;
    max-height: 730px;
    overflow: auto;

    .modalQuestionnairesAttached {
      margin-top: var(--gap-20-px);
      border: solid 1px black;
      border-radius: 10px;
      background-color: white;
      padding: var(--gap-10-px);
    }

    .modalContentBody {
      border-top: 1px solid #ebebeb;
      margin-top: var(--gap-20-px);
      padding-top: var(--gap- 0-px);
    }

    .formElementsRow {
      @include flex(row, flex-start, flex-start, wrap);
      width: 100%;
      margin-bottom: var(--gap-10-px);

      .label {
        color: var(--header-background-color);
      }

      .underline {
        padding: var(--gap-10-px) 0;
        border-bottom: 1px solid #ebebeb;
        width: 100%;
      }

      .datePickerWrapper,
      .timePickerWrapper {
        @include flex(column, flex-start, flex-start, nowrap);
        width: auto;

        &:nth-child(odd) {
          margin-right: var(--gap-20-px);
        }

        label {
          margin-bottom: var(--gap-10-px);
        }

        // .customDateInput {
        //   width: 210px !important;
        //   max-width: 250px;

        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day,
        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month.react-date-picker__inputGroup__input--hasLeadingZero {
        //     width: 35px !important;
        //   }

        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
        //     width: 50px !important;
        //   }

        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day,
        //   .react-date-picker__inputGroup__divider,
        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month.react-date-picker__inputGroup__input--hasLeadingZero,
        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
        //     padding: 0.5rem !important;
        //   }
        // }

        .customTimeInput {
          width: 170px !important;
          font-family: var(--font-family);
          font-size: var(--global-body-16-font-size);

          .react-time-picker__wrapper {
            border-radius: 5px;
            border: 1px solid var(--header-background-color);
          }

          .react-time-picker__inputGroup {
            margin: var(--gap-5-px);
          }

          .react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour,
          .react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
            width: 50px !important;
            height: 28px !important;
          }

          .react-time-picker__inputGroup__divider {
            margin: 0 var(--gap-10-px);
          }
        }
      }
    }
  }

  #projectEscalations {
    .escalationItem {
      @include flex(column, space-between, nowrap);
    }

    #escalationBtnContainer {
      @include flex(row, space-between, center, nowrap);
      margin-top: var(--gap-5-px);
    }
  }

  #contractDocument {
    .contractItem {
      @include flex(row, space-between, center nowrap);
    }
  }
}



// #guidanceContent{
//   height:35%;
//   @media (max-width: $mobile-width) {
//     height:80%;
//   }
// }
.event-section-header-content .title {
  font-size: var(--global-body-16-font-size);
}

.workflowContent {
  padding: 0;
  border: none;
  box-sizing: border-box;
  word-break: break-word;
  @include flex(row, center, flex-start, wrap);
  flex-direction: column;
  margin: var(--gap-20-px) 0;

  & .workflowRow {

    @include flex(row, flex-start, flex-start, nowrap, var(--gap-20-px));
    margin: 0;
    width: 100%;

    @media (max-width: $mobile-width) {
      flex-wrap: wrap;

      p {
        width: 100%;

      }
    }

    .label {
      width: 100%;
      border-bottom: 1px solid map-get($colour-palette, n-open-border-bottom);

      @media (min-width: $mobile-width) {
        max-width: 180px;
      }

      @media (min-width: $laptop-width) {
        max-width: 180px;
      }


    }

    .value {
      width: 100%;
      word-break: break-word;
      color: map-get($colour-palette, open-text-primary);
    }

  }
}



#projectActionContent {
  @include flex(column, center, flex-start, nowrap, var(--gap-20-px));

  &>div,
  & .upFormInputContainer {
    width: 100%;
    @include flex(column, center, space-around, nowrap, var(--gap-5-px));

  }

}

#projectOverview.new-projectOverview {

  & .projectOverviewContainer {
    margin-bottom: var(--gap-10-px);
  }

  & .dashboardCard {
    background: map-get($colour-palette, n-open-light-grey);

    & .dashboardCardMain {
      @include flex(column, normal, normal, nowrap, var(--gap-20-px));
    }
  }

  & .statuslabel-txt.body {
    font-weight: 600;
  }


  & .tag-txt.body {
    font-size: var(--global-body-12-font-size);
  }

  #projectActionContent {
    & .title.sectionLabel {

      border-bottom: 1px solid map-get($colour-palette , n-open-border-bottom);
    }

    &>div,
    & .upFormInputContainer {
      & .formLabel .title {

        border-bottom: 1px solid map-get($colour-palette , n-open-border-bottom);
      }
    }

  }

  & .title {
    font-weight: 530;
  }

  & .steeringGroupItem {
    width: 100%;

    & .title,
    & .projectOverviewContainer .sectionContent .sectionRow .sectionLabel {
      border-bottom: 1px solid map-get($colour-palette, n-open-border-bottom);
    }
  }

  & .projectOverviewContainer .sectionContent .sectionRow .sectionLabel {
    border-bottom: 1px solid map-get($colour-palette, n-open-border-bottom);
  }

  & .event-section-container.new-event-section-container {

    & .event-section-card {
      border-radius: 0;
      padding: var(--gap-20-px);
      border: 1px solid map-get($colour-palette, n-card-border);
      border-radius: 5px;
      box-shadow: none;
      background: #fff;
      margin: 0;
      display: block;


      &.started {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-started);
      }

      &.pending {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-not-started);
      }

      &.awaitingPublish {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-awaiting-publish);
      }

      &.completed {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-completed);
      }

      &.closed {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-closed);
      }

      &.published {
        border-left: 5px solid map-get($colour-palette, n-open-event-status-published);
      }

      & .statuslabel-content {
        padding: var(--gap-5-px) var(--gap-10-px);

        &.amber {
          color: map-get($colour-palette, n-status-red);
          border: 1px solid map-get($colour-palette, n-status-red);

        }

        &.started {
          border: 1px solid map-get($colour-palette, n-open-event-status-started);
          background-color: rgba(map-get($colour-palette, n-open-event-status-started), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-started);
            font-weight: 600;
          }
        }

        &.pending {
          border: 1px solid map-get($colour-palette, n-open-event-status-not-started);
          background-color: rgba(map-get($colour-palette, n-open-event-status-not-started), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-not-started);
            font-weight: 600;
          }
        }

        &.awaitingPublish {
          border: 1px solid map-get($colour-palette, n-open-event-status-awaiting-publish);
          background-color: rgba(map-get($colour-palette, n-open-event-status-awaiting-publish), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-awaiting-publish);
            font-weight: 600;
          }
        }

        &.completed {
          border: 1px solid map-get($colour-palette, n-open-event-status-completed);
          background-color: rgba(map-get($colour-palette, n-open-event-status-completed), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-completed);
            font-weight: 600;
          }
        }

        &.closed {
          border: 1px solid map-get($colour-palette, n-open-event-status-closed);
          background-color: rgba(map-get($colour-palette, n-open-event-status-closed), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-closed);
            font-weight: 600;
          }
        }

        &.published {
          border: 1px solid map-get($colour-palette, n-open-event-status-published);
          background-color: rgba(map-get($colour-palette, n-open-event-status-published), 0.2);

          & .statuslabel-txt.body {
            color: map-get($colour-palette, n-open-event-status-published);
            font-weight: 600;
          }
        }
      }
    }

    & .event-section-content {
      margin: 0;

      & .success,
      & .warning {
        color: map-get($colour-palette, open-text-primary);
      }

    }

    .event-section-card .event-section-header .event-section-header-button {
      background: none;
    }

    & .button.primary.skinless.red.danger {
      background-color: map-get($colour-palette, n-status-red);
      color: map-get($colour-palette, open-white);

      &:hover,
      &:focus-visible {
        outline: var(--primary-button-outline-hover);
        background: map-get($colour-palette, n-status-red);
      }

      &:active {
        box-shadow: var(--buttons-box-shadow);
      }

    }

    & .button.primary.skinless.white.complete-event {
      background-color: var(--primary-button-background);
      color: map-get($colour-palette, open-white);
      border: none;

      &:hover,
      &:focus-visible {
        outline: var(--primary-button-outline-hover);
        background: var(--primary-button-background-hover);
      }

      &:active {
        box-shadow: var(--buttons-box-shadow);
      }

    }

    .event-section-button {
      margin-top: var(--gap-20-px)
    }

    .project-overview-options {
      margin-top: var(--gap-20-px);
      background: #f5f5f5;
      align-items: center;
      justify-content: flex-end;
      padding: var(--gap-20-px) var(--gap-10-px);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    }

    .event-section-subsection {
      border-top: none;
    }
  }

  .new-button-container {
    @include flex(column, center, flex-end, wrap, var(--gap-10-px));

    & .button-group {
      @include flex(row, flex-end, center, wrap, var(--gap-10-px));

      &.button-group-new-options {
        & .button.primary.skinless.white {
          color: var(--header-background-color);
          background-color: #ffffff;
          border: 1px solid var(--header-background-color);

          &:hover {

            outline: 1px solid var(--header-background-color);
          }

          &.complete-event {
            background-color: var(--primary-button-background);
            color: #ffffff;
            border: 1px solid var(--header-background-color);

            &:hover {
              background-color: var(--primary-button-background-hover);
              border: 1px solid var(--primary-button-background-hover);
            }
          }

          &::before {
            margin-top: 5px;
            margin-right: 5px;
          }

          &.publish::before,
          &.start-event::before {
            content: url(../../styles/icons/blue/confirm.svg);
          }

          &.manage-questionnaire::before {
            content: url(../../styles/icons/blue/manage.svg);
          }

          &.edit-opportunity::before {
            content: url(../../styles/icons/blue/edit.svg);
          }

          &.manage-suppliers::before {
            content: url(../../styles/icons/blue/supplier.svg);
          }

          &.evaluation-summary::before,
          &.award-summary::before {
            content: url(../../styles/icons/blue/evaluation.svg);
          }
        }
      }
    }

    & .button {
      height: 30px;
      width: fit-content;

      &.complete-event {
        width: 8.55rem;
      }
    }
  }
}

#lotContainerMain.new-lotContainerMain {
  & #lotContainer {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    height: 93%;
    gap: var(--gap-20-px);
    column-gap: var(--gap-20-px);

    @media (max-width: $tablet-width) {
      grid-template-columns: 1fr 1fr 1fr;

    }

    @media (max-width: $mobile-width) {
      flex-wrap: wrap;
      grid: none;

    }
  }
}

.event-section-subsection.event-docs {
  margin-top: var(--gap-20-px);
}