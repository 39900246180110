#prMainContainer {
    @include flex(column, flex-start, initial, nowrap);
    border: 1px solid map-get($colour-palette, open-content-container-border);
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    width: 100%;
    padding: var(--gap-40-px);

    @media (max-width: $mobile-width) {
        @include flex(column, initial, initial, nowrap);
    }

    .prFormInputContainer {
        max-width: 350px;

        @media (max-width: $tablet-width) {
            max-width: 250px;
            // width: 100%;
        }

        @media (max-width: $mobile-width) {
            max-width: 230px;
            // width: 100%;
        }

        .react-date-picker__inputGroup__input:invalid {
            background: none;
        }
    }

    .formLabel {
        margin-bottom: var(--gap-10-px);
    }

    .buttonDivider {
        width: var(--gap-60-px);

        @media (max-width: $tablet-width) {
            width: var(--gap-30-px);
        }
    }

    .formCaption {
        margin-bottom: var(--gap-5-px);
    }

    .prDocumentButtonContainer {
        @include flex(row, space-between, flex-end, nowrap)
    }

    .prFormSubmitContainer {
        @include flex(row, flex-end, flex-end, nowrap);

        @media (max-width: $mobile-width) {
            @include flex(row, space-between, initial, nowrap)
        }

        .prFormButtonContainer {
            margin: 0 var(--gap-20-px) 0 var(--gap-20-px);

            @media (max-width: $mobile-width) {
                margin: 0;
            }
        }
    }

    .prFormContent {
        @include flex(column, flex-start, initial, nowrap)
    }

    .prFormTitle {
        margin-bottom: var(--gap-20-px);
        color: var(--header-background-color);
        font-family: NotoSans-Medium;
        font-size: var(--global-body-18-font-size);
        line-height: normal;

        &-xSmall {
            font-family: NotoSans-Medium;
            font-size: var(--global-body-16-font-size);
            line-height: normal;
        }

        &-small {
            font-family: NotoSans-Medium;
            font-size: var(--global-body-16-font-size);
            line-height: normal;
        }

        &-large {
            font-family: NotoSans-Medium;
            font-size: var(--global-body-20-font-size);
            line-height: normal;
        }

        &-xLarge {
            font-family: NotoSans-Medium;
            font-size: var(--global-body-22-font-size);
            line-height: normal;

            @media (max-width: $mobile-width) {
                font-size: var(--global-body-18-font-size);
            }
        }

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-16-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-16-font-size);
        }
    }

    .prContainerHeader {
        margin-bottom: var(--gap-20-px);
        border-bottom: 1px solid map-get($colour-palette, open-grey);
    }

    .prContainerTitle {
        margin-bottom: var(--gap-20-px);
        color: var(--header-background-color);
        font-family: NotoSans-Medium;
        font-size: var(--global-body-20-font-size);
        line-height: normal;

        &-xSmall {
            font-family: NotoSans-Medium;
            font-size: var(--global-body-16-font-size);
            line-height: normal;
        }

        &-small {
            font-family: NotoSans-Medium;
            font-size: var(--global-body-18-font-size);
            line-height: normal;
        }

        &-large {
            font-family: NotoSans-Medium;
            font-size: var(--global-body-22-font-size);
            line-height: normal;
        }

        &-xLarge {
            font-family: NotoSans-Medium;
            font-size: var(--global-body-24-font-size);
            line-height: normal;

            @media (max-width: $mobile-width) {
                font-size: var(--global-body-20-font-size);
            }
        }

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-14-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-14-font-size);
        }

    }
}