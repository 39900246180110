.award-info-data-upload {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    border-radius: 0px;
    background: map-get($colour-palette, n-open-extra-card);
    margin: 1rem 0;
    gap: 1.5rem;

    & .supplier-data-upload-divs {
        border-top: none;
        border-bottom: none;
        padding: 0;
    }

    & .flex-break {
        display: none;
    }

    & .uploadContainer {
        display: flex;
        flex-direction: column;
        align-items: initial;
        justify-content: flex-start;
        flex-wrap: nowrap;
        width: auto;
        background: none;

        & .upDocumentButtonContainer {
            gap: 1.5rem
        }

    }

    & .upFormInputContainer {
        display: flex;
        gap: 1.5rem;
        padding: 0;
        width: 100% !important;
        max-width: 100% !important;
        align-items: center;
    }

    & .formLabel {
        margin: 0 !important;
        padding: 0 !important;

        & p {
            font-size: var(--global-body-14-font-size);
            font-weight: 600;
        }
    }

    & .formCaption {
        margin: 0 !important;
        padding: 0 !important;

        & p {
            font-size: var(--global-body-14-font-size);
        }
    }

    & .upDocumentButtonContainer {
        margin: 0 !important;
        padding: 0 !important;

        & p {
            font-size: var(--global-body-14-font-size);
        }
    }

    & .sbAccordionSectionContentDataUpload {
        margin-left: auto !important;
        display: inherit !important;
        width: auto !important;
        padding: 0 !important;

        & .sbAccordionSectionContentDataAwardRow {
            margin-bottom: 0 !important;
        }

        & button {
            margin: 0 !important;
        }
    }

    & .sbAccordionSectionContentDataAwardRow {
        margin-bottom: 0 !important;
    }

    & .sbAccordionSectionContentDataAwardRowDPS {
        display: flex;
        gap: 1.5rem;
    }

    & .sbAccordionSectionContentDataAwardRow #submitForApproval,
    .sbAccordionSectionContentDataAwardRow #sendAwardLetters {
        width: 160px;
    }

}

.accordion-container .award-info-data-upload {
    width: calc(100% - 6rem);
}

.award-info-data-upload:empty {
    display: none;
}

.title.award-lot-supplier {
    color: var(--header-background-color);
    font-size: var(--global-body-18-font-size);
    font-weight: bold;
    flex-wrap: wrap;

    & img {
        height: 18px;
        margin-right: 5px;
        width: 18px;
    }
}