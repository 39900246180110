#supplierProfileData {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;
  @include boxSizing(border-box);

  .itemContainer {
    width: 100%;
    height: auto;
    background-color: map-get($colour-palette, open-white);
    border-radius: var(--gap-10-px);
    padding: var(--gap-40-px);
    @include boxSizing(border-box);

    @media (max-width: $tablet-width) {
      padding: var(--gap-30-px);
    }

    @media (max-width: $mobile-width) {
      padding: var(--gap-20-px);
    }

    .itemHeaderContainer {
      @include flex(row, flex-end, center, nowrap);
      border-bottom: 1px solid map-get($colour-palette, open-divider);
      width: 100%;
      padding-bottom: var(--gap-20-px);

      #actionBtn {
        margin-left: var(--gap-10-px);
      }

      @media (max-width: $mobile-width) {
        @include flex(column, center, center, nowrap);

        #actionBtn,
        #backBtn {
          margin-left: 0;
          margin-bottom: var(--gap-10-px);
        }

        padding-bottom: var(--gap-15-px);
      }

      .itemHeader {
        @include flex(column, flex-start, flex-start, nowrap);

        .headerTitle {
          margin-bottom: var(--gap-10-px);
        }

        @media (max-width: $mobile-width) {
          margin-right: var(--gap-15-px);
        }
      }

      .headerTitle {
        color: var(--header-background-color);
        margin-right: var(--gap-20-px);

        @media (max-width: $mobile-width) {
          margin-right: 0;
        }
      }

      .headerCaption {
        padding-top: var(--gap-10-px);
      }

      #actionBtnContainer {
        @include flex(row, center, center, nowrap);

        #actionBtn {
          margin-right: 0px;
          margin-left: 20px;
        }

        @media (max-width: $mobile-width) {
          .button {
            height: fit-content;
            height: -moz-fit-content;
            width: 80px;
          }

          #actionBtn {
            margin-right: 0px;
            margin-left: 20px;
          }
        }
      }
    }

    .itemBody {
      #office-accordion-id {
        .accordion-section {
          margin-bottom: var(--gap-30-px);
          border: 1px solid var(--header-background-color);
          border-radius: 5px;

          &-header {
            border-radius: 5px;

            &.contentOpen {
              border-radius: 5px 5px 0 0;
            }
          }

          &-content {
            &.open {
              border-top: 1px solid var(--header-background-color);
              border-radius: 0 0 5px 5px;
              overflow-y: auto;
              padding-left: 1%;
            }

            &-data {
              padding: var(--gap-20-px);
            }
          }
        }
      }

      .sectionHeader {
        color: var(--header-background-color);
        padding-top: var(--gap-20-px);

        &-default {
          display: none;
        }

        @media (max-width: $mobile-width) {
          padding-top: var(--gap-10-px);
        }
      }

      .sectionContent {
        padding-bottom: var(--gap-30-px);
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        word-break: break-word;

        .sectionLabel {
          width: 250px;
        }


        #personalInformation {
          .sectionLabel {
            width: 200px;
          }
        }

        #preferences {
          .sectionLabel {
            width: 200px;
          }
        }

        #organisationInformation {
          .sectionLabel {
            width: 310px;
          }
        }

        #specialAccountPrivileges {
          .sectionLabel {
            width: 500px;
          }
        }

        &:last-child {
          border: none;
        }

        @media (max-width: $mobile-width) {
          padding-bottom: var(--gap-10-px);
        }

        &Tags {
          @include flex(row, flex-start, flex-start, wrap);
          border-bottom: 1px solid map-get($colour-palette, open-divider);
          padding: var(--gap-20-px) 0;

          @media (max-width: $mobile-width) {
            padding: var(--gap-15-px) 0;
          }

          .tag-content {
            margin: 0 var(--gap-10-px) var(--gap-10-px) 0;

            @media (max-width: $mobile-width) {
              margin: 0 0 var(--gap-10-px) 0;
            }
          }
        }

        .sectionRow {
          @include flex(row, flex-start, flex-start, nowrap);
          margin-top: var(--gap-15-px);

          @media (max-width: $mobile-width) {
            flex-wrap: wrap;

            p {
              width: 100%;
              margin-left: 0;

              &:first-of-type {
                margin-bottom: var(--gap-10-px);
              }
            }
          }
        }
      }

      // .sectionLabel,
      // .form-label {
      //   width: 100%;
      //   @media (min-width: $laptop-width) {
      //     max-width: 250px;
      //   }

      //   @media (min-width: $mobile-width) {
      //     max-width: 230px;
      //   }
      // }

      .sectionValue {
        width: 100%;
        word-break: break-word;
        color: map-get($colour-palette, open-text-primary);

        @media (max-width: $tablet-width) {
          margin-left: var(--gap-20-px);
        }

        @media (min-width: $tablet-width) {
          margin-left: var(--gap-40-px);
        }
      }
    }
  }

  .labelled-input .disabled {
    cursor: not-allowed;
    opacity: 0.7;
    outline: none;
  }

  #country-select.form-select {
    width: 22.1%;
  }
}

.editBtn {
  @include flex(row, flex-start, flex-start, nowrap);
  cursor: pointer;
}

.twoActionBtnContainer {
  max-width: 100%;
  width: 100%;
  @include flex(row, flex-end, flex-start, nowrap);

  button {
    margin-left: var(--gap-20-px);
  }
}

.mpWorkflowItem {
  @include flex(column, space-between, center, nowrap);
  border-bottom: 1px solid map-get($colour-palette, open-divider);
  width: 100%;
  // border-radius: var(--gap-10-px);
  margin: var(--gap-10-px);
  padding-bottom: var(--gap-15-px);
  // min-height: 120px;

  p {
    margin-bottom: var(--gap-10-px);
  }

  .mpHeader {
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
    margin-bottom: var(--gap-10-px);
  }

  // .title {
  //   color: var(--header-background-color);
  // }
}

.mpButtonContainer {
  @include flex(row, center, center, nowrap);
  width: 100%;

  .button {
    margin: 0 var(--gap-10-px);
  }
}