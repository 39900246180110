.progress-loader-container {
    margin: 0;
    padding: 0;
    border: 0;

    &>* {
        position: fixed;
        margin: 0;
        top: 101px; // nav bar is 60px, secondary nav bar is ~41px
        left: 0;
        width: 100%;
        height: 4px;
        z-index: 99; //Just below navbar, so menus show on top

        @media (max-width: $tablet-width) {
            top: 80px; // nav bar is 80px, no 2nd navbar
        }
    }

    //Exceptional case: Login does not have navbar, stick loading bar on top
    &:not(:has(+header.navbar, +*+header.navbar))>* {
        top: 0px;
    }


    &.new {
        &>* {
            top: 110px; // top bar is +30 

            @media (max-width: $tablet-width) {
                top: 85px; // only +10 in new design
            }
        }
    }
}