#burger-menu {
    height: 100%;

    &-header {
        text-align: right;
        padding: var(--gap-20-px) var(--gap-20-px) var(--gap-10-px) var(--gap-20-px);

        @media (max-width: $mobile-width) {
            padding: var(--gap-10-px);
        }

        .closeBtn {
            border: none;
            background: none;

            &-img {
                cursor: pointer;
            }
        }
    }

    &-offline-container {
        @include flex(column, space-around, center, nowrap);
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        padding-bottom: var(--gap-40-px);
        @include boxSizing(border-box);

        button {
            margin: var(--gap-10-px) 0;
        }

        @media (max-width: $mobile-width) {
            padding-bottom: var(--gap-20-px);

            button {
                width: 120px;
                height: 35px;
                font-size: var(--global-body-16-font-size);
                margin: var(--gap-10-px) 0;
            }
        }
    }

    &-data {
        position: relative;
        height: 100%;
        overflow-x: hidden;
        @include boxSizing(border-box);

        @media (max-width: $mobile-width) {
            padding: var(--gap-20-px) 0;
        }

        section {
            @include flex(row, flex-start, center, nowrap);
            height: 60px;
            margin: var(--gap-5-px) 0;

            @media (max-width: $mobile-width) {
                height: 50px;
            }

            header {
                height: 100%;
                padding: 0;
                min-height: inherit;
                background-color: map-get($colour-palette, open-white);
                position: relative;

                .burgerMenuActiveStrip {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 8px;
                    background-color: var(--header-background-color);
                }

                p {
                    padding-left: var(--gap-40-px);
                    color: map-get($colour-palette, open-text-grey);

                    @media (max-width: $mobile-width) {
                        padding-left: var(--gap-30-px);
                        font-size: var(--global-body-16-font-size);
                    }
                }

                img {
                    display: none;
                }
            }
        }

        &-active {
            background-color: rgba(33, 61, 67, 0.05);

            header {
                background-color: rgba(33, 61, 67, 0.001) !important;

                p {
                    color: var(--header-background-color) !important;
                }
            }
        }
    }
}