.filterOptionsDivider {
    width: 100%;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    margin: var(--gap-20-px) 0;
}

.filterPanelContent {
    .inputRangeFilter {
        .title {
            margin-bottom: 3rem;
            font-weight: bold;
            font-family: var(--font-family);
            font-size: var(--global-body-16-font-size);
            line-height: normal;
        }

        .labelled-input {
            @include flex(row, flex-start, flex-start, nowrap);
            margin: var(--gap-10-px) 0;

            .form-input {
                width: 50%;
            }

            .from {
                margin-right: var(--gap-10-px);
            }
        }
    }
}

.datePickerWrapper {
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;

    // .datepickerLabel {
    //     margin-bottom: 1rem;
    // }
}

.supplierFilterWrapper {
    @include flex(row, flex-start, center, nowrap);
    width: 100%;
    justify-content: space-between;

    .codeCategoryLabel {
        margin-bottom: var(--gap-5-px);
    }

    .addWrapper {
        @include flex(row, flex-end, flex-end, nowrap);

        .icon-container {
            width: var(--gap-20-px);
            height: var(--gap-20-px);

            img {
                height: 100%;
                width: 100%;
            }
        }

        label {
            margin-left: 1rem;
        }
    }
}

.categoryFilter {
    max-height: 60%;
    overflow-y: auto;
    margin-top: 1rem;

    #sample-tag {
        height: 30px;
        margin-top: 1rem;

        p {
            font-size: var(--global-body-14-font-size);
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .tag-delete-btn-container {
            width: 20px;
            height: 20px;

            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}

#bsSectionContent {
    .textIconContainer {
        @include flex(row, space-between, center, nowrap);

        #addButton {
            .button-icon {
                @media (max-width: $tablet-width) {
                    height: auto;
                }

                @media (max-width: $mobile-width) {
                    height: auto;
                }
            }
        }
    }

    .tag-content {
        margin: 0 0 1rem 0;
        border-radius: 25px;
        padding: 0 1rem;
        min-height: auto;

        @media (max-width: 600px) {
            margin: 0 0 0.5rem 0;
        }
    }
}