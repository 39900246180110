@mixin flex($direction, $horizontal-align: center, $vertical-align: center, $wrap: nowrap, $gap: 0) {
    display: flex;
    flex-direction: $direction;
    align-items: $vertical-align;
    justify-content: $horizontal-align;
    flex-wrap: $wrap;
    gap: $gap;
}

@mixin fontFace($font, $fontfile: $font) {
    @font-face {
        font-family: $font;
        src: url(../fonts/#{$fontfile}.ttf) format("truetype");
    }
}

@mixin boxSizing($type) {
    -webkit-box-sizing: $type;
    -moz-box-sizing: $type;
    box-sizing: $type;
}

@mixin tooltip(
    $data-content: "",
    $width: 220px,
    $padding: 5px 12px,
    $offset-y: -35px,
    $offset-x: calc(50% - var(--gap-15-px)),
    $arrow-transform: translateX(-50%),
    $background: #333,
    $text-color: #fff,
    $font-size: 14px
) {
    position: relative;

    &:before {
        content: $data-content;
        position: absolute;
        top: $offset-y;
        left: $offset-x;
        transform: translateX(-50%);
        background-color: $background;
        color: $text-color;
        padding: $padding;
        border-radius: 4px;
        font-size: $font-size;
        font-weight: normal;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
        width: $width;
        text-wrap-mode: wrap;
        padding: $padding;
        z-index: 999;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 130%;
        transform: $arrow-transform;
        border-width: 6px;
        border-style: solid;
        border-color: #333 transparent transparent transparent;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
    }

    &:hover {
        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
        }
    }
}
