#faqPanel {
  margin-left: 30px;
  min-height: 400px;
  // @include flex(column, flex-start, flex-start, nowrap);
}

.panel-container {
  .panel {
    &-body {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

#documentsPanel {
  min-height: 400px;
  margin-bottom: var(--gap-20-px);

  .panel-body {
    overflow-y: auto;
    max-height: 250px;
  }
}

#browseOpportunitiesAccordion {
  width: 100%;
  max-width: 900px;

  .accordion-section {
    border: 1px solid var(--header-background-color);
    @include boxSizing(border-box);
    margin-bottom: var(--gap-10-px);
    border-radius: 5px;

    @media (max-width: $tablet-width) {
      margin-bottom: var(--gap-20-px);
    }

    &-header {
      border-radius: 10px;
      padding: var(--gap-15-px) var(--gap-20-px) var(--gap-15-px) var(--gap-20-px);

      p {
        pointer-events: none;
      }

      img {
        padding: 0;
      }

      @media (max-width: $tablet-width) {
        padding: 0 var(--gap-10-px);
      }

      &.contentOpen {
        border-radius: 10px 10px 0 0;
      }
    }

    &-content {
      &.open {
        border-top: 1px solid var(--header-background-color);
        border-radius: 0 0 10px 10px;
      }

      &-data {
        @include flex(column, flex-start, center, nowrap);
        @include boxSizing(border-box);

        .browseOpportunitiesAccordionSectionContentRow {
          @include flex(row, space-between, flex-start, wrap);
          width: 100%;

          .browseOpportunitiesAccordionSectionContentData {
            @include flex(row, flex-start, flex-start, wrap);
            padding: var(--gap-10-px) var(--gap-20-px);

            .label {
              margin-right: var(--gap-10-px);
            }

            @media (max-width: $tablet-width) {
              padding: var(--gap-10-px);
            }

            @media (max-width: $mobile-width) {
              padding: var(--gap-5-px);

              p {
                padding: var(--gap-5-px)
              }

              .label {
                margin-right: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

#overViewData {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;
  @include boxSizing(border-box);

  #supplierActionCard {
    margin-top: var(--gap-20-px);
  }

  .dashboardContainer #actionBtnS {
    margin-top: var(--gap-15-px);
    margin-bottom: var(--gap-15-px);
  }

  .panel-section {
    width: 100%;
    @include flex(row, space-between, flex-start, nowrap);
  }

  .tenderSection {
    @include flex(row, flex-start, initial, nowrap);
    margin-bottom: var(--gap-10-px);
  }

  .tenderText {
    margin-left: var(--gap-10-px);
  }

  .tenderTitle {
    line-height: var(--line-height);
  }

  #main-action-btn {
    margin-right: 0;
  }

  #opportunityRejectButton {
    color: map-get($colour-palette, open-red);
    border: 1px solid map-get($colour-palette, open-red);
    height: 40px;
  }


  #unsuccessful {
    color: map-get($colour-palette, open-red);
  }

  #successful {
    color: map-get($colour-palette, open-green);
  }

  .panel-footer {
    width: 100%;
    @include flex(row, space-evenly, flex-start, nowrap);
  }

  #faq-panel-body {
    padding: 0;
  }

  .document-item {
    @include flex(row, space-between, center, nowrap);
    font-size: var(--global-body-16-font-size);
    margin-bottom: var(--gap-20-px);

    // .button {
    //   height: 30px;
    //   width: 100px;
    //   font-size: 14px;
    // }

    .title {

      // margin-bottom: var(--gap-10-px);
      &.tender {
        margin-bottom: var(--gap-10-px);
      }
    }

    &.tender {
      @include flex (column, initial, initial, nowrap);

      .button-container {
        @include flex(row, space-between, flex-start, nowrap);
      }
    }
  }

  .withdrawInterestModal {

    .caption {
      margin: var(--gap-10-px) 0 var(--gap-10-px) 0;
    }

    #statusReason-select {
      height: 50px;
      width: 100%;
      max-width: 100%;
      font-size: var(--global-body-14-font-size);
      margin-bottom: var(--gap-10-px);
    }

    #otherReason-textarea {
      width: 100%;

    }
  }

  .twoActionBtnContainer {
    max-width: 320px !important;
    width: 100%;
    @include flex(row, space-between, flex-start, nowrap);
  }

  .itemContainer {
    width: 100%;
    height: auto;
    background-color: map-get($colour-palette, open-white);
    border-radius: var(--gap-10-px);
    padding: var(--gap-40-px);
    @include boxSizing(border-box);

    @media (max-width: $tablet-width) {
      padding: var(--gap-30-px);
    }

    @media (max-width: $mobile-width) {
      padding: var(--gap-20-px);
    }

    .itemHeaderContainer {
      @include flex(row, space-between, center, nowrap);
      border-bottom: 1px solid map-get($colour-palette, open-divider);
      width: 100%;
      padding-bottom: var(--gap-20-px);

      @media (max-width: $mobile-width) {
        padding-bottom: var(--gap-15-px);
      }

      .itemHeader {
        @include flex(column, flex-start, flex-start, nowrap);
        word-break: break-word;

        .headerTitle {
          margin-bottom: var(--gap-10-px);
        }

        @media (max-width: $mobile-width) {
          margin-right: var(--gap-15-px);
        }
      }

      .headerTitle {
        color: var(--header-background-color);
        margin-right: var(--gap-20-px);

        @media (max-width: $mobile-width) {
          margin-right: 0;
        }
      }

      .headerCaption {
        padding-top: var(--gap-10-px);
      }

      #actionBtnContainer {
        @include flex(row, center, center, nowrap);

        #actionBtn {
          margin-right: 0px;
          margin-left: 20px;

          .button-icon {
            height: 60%;
          }
        }

        @media (max-width: $mobile-width) {
          .button {
            height: fit-content;
            height: -moz-fit-content;
            width: 80px;
          }

          #actionBtn {
            margin-right: 0px;
            margin-left: 20px;
          }
        }
      }
    }

    .itemBody {
      .sectionHeader {
        color: var(--header-background-color);
        padding-top: var(--gap-20-px);

        @media (max-width: $mobile-width) {
          padding-top: var(--gap-10-px);
        }
      }

      .sectionContent {
        padding-bottom: var(--gap-30-px);
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        word-break: break-word;

        &:last-child {
          border: none;
        }

        @media (max-width: $mobile-width) {
          padding-bottom: var(--gap-10-px);
        }

        &Tags {
          @include flex(row, flex-start, flex-start, wrap);
          border-bottom: 1px solid map-get($colour-palette, open-divider);
          padding: var(--gap-20-px) 0;

          @media (max-width: $mobile-width) {
            padding: var(--gap-15-px) 0;
          }

          .tag-content {
            margin: 0 var(--gap-20-px) var(--gap-20-px) 0;

            @media (max-width: $mobile-width) {
              margin: 0 0 var(--gap-10-px) 0;
            }
          }
        }

        .sectionRow {
          @include flex(row, flex-start, flex-start, nowrap);
          margin-top: var(--gap-20-px);

          @media (max-width: $mobile-width) {
            flex-wrap: wrap;

            p {
              width: 100%;
              margin-left: 0;

              &:first-of-type {
                margin-bottom: var(--gap-10-px);
              }
            }
          }
        }
      }

      .sectionLabel,
      .form-label {
        width: 100%;

        @media (min-width: $laptop-width) {
          max-width: 250px;
        }

        @media (min-width: $mobile-width) {
          max-width: 230px;
        }
      }

      .sectionValue {
        width: 100%;
        word-break: break-word;
        color: map-get($colour-palette, open-text-primary);

        @media (max-width: $tablet-width) {
          margin-left: var(--gap-20-px);
        }

        @media (min-width: $tablet-width) {
          margin-left: var(--gap-40-px);
        }
      }
    }
  }
}