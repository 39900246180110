.body {
    font-family: var(--font-family);
    font-weight: var(--font-weight);
    line-height: var(--line-height);

    &.error,
    &.unsuccessful {
        color: map-get($colour-palette, open-red);
    }

    &.successful {
        color: map-get($colour-palette, open-green);
    }

    &.bold {
        font-weight: 500;
    }
}

.caption {
    line-height: var(--line-height);

    &-small {
        line-height: var(--line-height);

    }

    &-xSmall {
        line-height: var(--line-height);
    }
}

.links {
    text-decoration: underline;
    line-height: var(--line-height);

    &-large {
        text-decoration: underline;
        line-height: var(--line-height);
    }

    &-xLarge {
        text-decoration: underline;
        line-height: var(--line-height);
    }

    &-small {
        text-decoration: underline;
        line-height: var(--line-height);

    }

    &-xSmall {
        text-decoration: underline;
        line-height: var(--line-height);
    }
}

.button-text {
    text-transform: capitalize;
}

[data-theme="default-style"] {

    h1 {
        font-size: var(--global-body-50-font-size);
        font-weight: 500;

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-32-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-26-font-size);
        }
    }

    h2 {
        font-size: var(--global-body-36-font-size);

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-28-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-24-font-size);
        }
    }

    h3 {
        font-size: var(--global-body-28-font-size);

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-26-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-22-font-size);
        }
    }

    h4 {
        font-size: var(--global-body-19-font-size);

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-18-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-17-font-size);
        }
    }


    & .title,
    & .caption,
    & .body,
    & .button-text,
    & .links,
    li,
    p,
    span {
        font-size: var(--global-body-16-font-size);

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-14-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-14-font-size);
        }

        &-xLarge {
            font-size: var(--global-body-20-font-size);

            @media (max-width: $tablet-width) {
                font-size: var(--global-body-18-font-size);
            }

            @media (max-width: $mobile-width) {
                font-size: var(--global-body-18-font-size);
            }
        }

        &-large {
            font-size: var(--global-body-18-font-size);

            @media (max-width: $mobile-width) {
                font-size: var(--global-body-16-font-size);
            }
        }

        &-small {
            font-size: var(--global-body-14-font-size);
        }

        &-xSmall {
            font-size: var(--global-body-12-font-size);
        }
    }

    .rich-text-editor-wrapper,
    .labelled-input .form-input {
        font-size: var(--global-body-16-font-size);
    }
}

[data-theme="new-style"] {

    h1 {
        font-size: var(--global-body-50-font-size);
        font-weight: 500;

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-32-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-26-font-size);
        }
    }

    h2 {
        font-size: var(--global-body-36-font-size);
        font-weight: 500;

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-28-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-24-font-size);
        }
    }

    h3 {
        font-size: var(--global-body-28-font-size);
        font-weight: 500;

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-26-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-22-font-size);
        }
    }

    h4 {
        font-size: var(--global-body-19-font-size);
        font-weight: 500;

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-18-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-17-font-size);
        }
    }

    strong {
        font-weight: 600;
    }

    & .button {
        text-decoration: none;
    }

    & .title,
    & .caption,
    & .body,
    & .button-text,
    & .links,
    li,
    p,
    label,
    div {
        font-size: var(--global-body-14-font-size);

        @media (max-width: $tablet-width) {
            font-size: var(--global-body-12-font-size);
        }

        @media (max-width: $mobile-width) {
            font-size: var(--global-body-12-font-size);
        }

        &-xLarge {
            font-size: var(--global-body-18-font-size);

            @media (max-width: $tablet-width) {
                font-size: var(--global-body-16-font-size);
            }

            @media (max-width: $mobile-width) {
                font-size: var(--global-body-16-font-size);
            }
        }

        &-large {
            font-size: var(--global-body-16-font-size);

            @media (max-width: $mobile-width) {
                font-size: var(--global-body-14-font-size);
            }
        }

        &-small {
            font-size: var(--global-body-13-font-size);
        }

        &-xSmall {
            font-size: var(--global-body-12-font-size);
        }
    }

    .rich-text-editor-wrapper,
    .labelled-input .form-input {
        font-size: var(--global-body-14-font-size);
        font-family: var(--font-family);
    }

}