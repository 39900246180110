.registration-form {
    .labelled-input {
        .form-label {
            white-space: nowrap;
        }

        .form-input,
        .form-label {
            @media (max-width: $tablet-width) {
                // min-width: 100%;
                max-width: 250px;
                width: 100%;
            }

            @media (max-width: $mobile-width) {
                // min-width: 100%;
                width: 100%;
            }
        }
    }

    .reg-input-wrapper {
        margin-bottom: var(--gap-30-px);
        flex: 1 50px;
    }

    .ro-input-wrapper {
        margin-bottom: var(--gap-30-px);
    }

    .ro-input-wrapper.reg-input-grp .labelled-select {
        margin-bottom: var(--gap-15-px);
    }

    #no-HQ-tag {
        color: red;
        font-size: initial;
    }

    .form-btn-wrapper {
        @include flex(row, flex-end, flex-start, nowrap);
        width: 100%;
        margin-top: var(--gap-70-px);

        @media (max-width: $mobile-width) {
            @include flex(column, flex-start, flex-start, nowrap);

        }

        .sup-reg-back,
        .ros-back,
        .cnc-back {
            margin: 0 var(--gap-40-px);

            @media (max-width: $mobile-width) {
                margin: 0 0 var(--gap-40-px) 0;
            }
        }
    }

    // .term-consent-wrapper {
    //     @include flex(row, flex-start, flex-start, nowrap);
    //     // width: 100%;

    // }
    .ro-interest-wrapper {
        width: 100%;
    }

    .form-header-wrapper {
        @include flex(row, flex-start, flex-start, nowrap);
        width: 100%;
        margin-bottom: var(--gap-10-px);
    }

    #country-select,
    #roCountry-select,
    #department-select,
    #mobile-country-code-select,
    #companyCountryCode-select,
    #roBusinessStructure-select {
        min-width: 350px;

        @media (max-width: $tablet-width) {
            min-width: 250px;
            max-width: 250px;
            width: 100%;
        }

        @media (max-width: $mobile-width) {
            // min-width: 320px;
            width: 100%;
        }
    }

    .wrapper,
    #company-details-form {
        // #account-details-form {
        flex-grow: 1;
        @include flex(row, space-between, stretch, wrap);

        @media (max-width: $tablet-width) {
            @include flex(column, flex-start, stretch, wrap);
        }

        @media (max-width: $mobile-width) {
            @include flex(column, flex-start, stretch, wrap);
        }
    }

    #account-details-form-div {
        flex-grow: 1;
        @include flex(row, space-between, stretch, wrap);

        @media (max-width: $tablet-width) {
            @include flex(column, flex-start, stretch, wrap);
        }

        @media (max-width: $mobile-width) {
            @include flex(column, flex-start, stretch, wrap);
        }
    }


    #cnc-form {
        width: 100%;
        @include flex(column, space-between, stretch, wrap);

        @media (max-width: $tablet-width) {
            @include flex(column, flex-start, stretch, wrap);
        }
    }

    .reg-input-grp {
        .reg-input-wrapper {
            margin-bottom: var(--gap-10-px);
        }
    }

    .registration-section-header {
        padding-bottom: var(--gap-10-px);
        margin-bottom: var(--gap-20-px);
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        @include flex(row, space-between, center, nowrap);

        h4 {
            width: calc(100% - 90px);
        }

        .add-item {
            cursor: pointer;

            button {
                margin-right: var(--gap-10-px);
            }

            @include flex(row, flex-start, center, wrap);
        }
    }

    .ro-btn-wrapper {
        button {
            width: fit-content;
        }

        @media (max-width: $mobile-width) {
            margin-right: 0rem;
        }
    }

    .registration-section-pills {
        flex: 1;

        .pill {
            position: relative;
            border: 1px solid black;
            border-radius: 50px;
            margin-right: var(--gap-20-px);
            margin-bottom: var(--gap-30-px);
            padding: 0 0 0 var(--gap-20-px);
            width: 325px;
            height: 50px;
            @include flex(row, flex-start, center, wrap);

            @media (max-width: $mobile-width) {
                width: 100%;
            }

            p {
                width: calc(100% - var(--gap-60-px));
            }
        }

        .pills-container {
            @include flex(row, flex-start, center, wrap);
        }

        .remove-pill-btn {
            position: absolute;
            right: 16px;
            height: 30px;
            padding-right: var(--gap-20-px);
            cursor: pointer;
        }
    }

    .company-reg-button {
        margin: 0 var(--gap-30-px) var(--gap-20-px) 0;
        align-self: flex-end;
    }
}


#search-results {
    border-top: 1px solid map-get($colour-palette, open-divider);
    border-bottom: 1px solid map-get($colour-palette, open-divider);
    margin: var(--gap-20-px) 0;
    padding: var(--gap-10-px) 0;
}

#acc-reg-progress,
#company-reg-progress {
    margin-bottom: var(--gap-30-px);
}

#company-type-select {
    padding: 0 var(--gap-50-px) 0 var(--gap-10-px) !important;
    font-size: var(--global-body-14-font-size);
    max-width: calc(100% - var(--gap-10-px));
    margin-right: var(--gap-10-px);
}

.items {
    margin-top: var(--gap-40-px);

    .section-heading {
        padding-left: var(--gap-5-px);
    }
}

.roData {
    margin-left: var(--gap-50-px);
    font-family: NotoSans-Light;
}

.usersAndDepartmentsWrapper,
.codesWrapper,
.regionsWrapper {
    padding: 0 var(--gap-30-px) 0 var(--gap-20-px);

    .searchBar {
        max-width: 100%;
    }

    .rowItem {
        display: block;
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        padding-bottom: var(--gap-10-px);
        margin: 10px 0;

        input {
            margin: 0 10px;
            width: 20px;
            height: 20px;
        }

        img {
            position: absolute;
            right: 10px;
        }

        .expandLabel {
            width: 100%;
            min-height: inherit;
            @include flex(row, space-between, center, nowrap);
            background-color: map-get($colour-palette, open-white);
            cursor: pointer;
            @include boxSizing(border-box);
            border: none;

            &.interactive {
                cursor: pointer;
            }
        }

        .labelled-checkBox.checkBox-group.row {
            width: 50px;

            .form-checkBox-container {
                .form-label.checkBox-label {
                    @include flex(row, flex-start, center, nowrap);
                    padding-left: 0;
                }

                .form-label.checkBox-label::before {
                    position: inherit;
                }
            }
        }
    }

    .rowItem::before {
        content: "";
        padding: 0 var(--gap-5-px) 0 0;
    }

    .rowItem>.toggler {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        width: 100%;

        // Temporary fixes until the checkbox component is built
        //   .labelled-checkBox .form-checkBox-container input[type='checkbox'] + .form-label:before,
        //   .labelled-checkBox .form-checkBox-container input[type='checkbox'] + .form-label:after {
        //       top: 0.3rem;
        //   }
        //   .labelled-checkBox .form-checkBox-container input[type='checkbox'] + .form-label {
        //       padding: 0;
        //       margin: 0;
        //   }
    }
}