#cwAccordion {
  width: 100%;
  max-width: 750px;
  border-radius: 10px;

  .accordion-section {
    margin-bottom: 0;
    border: 1px solid map-get($colour-palette, n-open-light-grey);

    &:only-of-type {
      border-radius: 10px;
    }

    &:not(:only-of-type):first-of-type {
      border-radius: 10px 10px 0 0;

      .accordion-section-header {
        border-radius: 10px 10px 0 0;
        border-bottom: 0;
      }
    }

    &:not(:only-of-type):last-of-type {
      border-radius: 0 0 10px 10px;
      border-top: 0;

      .accordion-section-header {
        border-top: 0;
        border-radius: 0 0 10px 10px;
      }
    }

    &:not(:first-of-type):not(:last-of-type) {
      border-top: 0;
    }

    @include boxSizing(border-box);

    .accordion-section-header {
      padding: var(--gap-10-px) var(--gap-20-px);

      &:only-of-type {
        border-radius: 10px;
      }

      .accordion-section-header-data {
        pointer-events: none;

        .contentOpen {
          margin-right: var(--gap-10-px);
          border-radius: 5px 5px 0 0;
        }

        @media (max-width: $mobile-width) {
          margin: var(--gap-10-px) 0;

          .content {
            margin-right: var(--gap-10-px);
            margin-bottom: var(--gap-5-px);
          }
        }
      }

      img {
        padding: 0;
      }

      #bdAccordionSectionHeaderAddTag {
        @include flex(row, center, center, nowrap);
        border-radius: var(--gap-50-px);
        margin-right: var(--gap-20-px);
        width: 150px;
        height: 25px;

        &.overdue {
          color: map-get($colour-palette, open-red);
          border: 1px solid map-get($colour-palette, open-red);
        }

        &.in-progress,
        &.starts {
          color: map-get($colour-palette, open-text-grey);
          border: 1px solid map-get($colour-palette, open-text-grey);
        }

        &.completed-early,
        &.completed-ontime {
          color: map-get($colour-palette, open-text-success);
          border: 1px solid map-get($colour-palette, open-text-success);
        }

        &.completed-late {
          color: map-get($colour-palette, open-text-error);
          border: 1px solid map-get($colour-palette, open-text-error);
        }
      }
    }

    .open {
      border-top: 1px solid var(--header-background-color);
      border-radius: 0 0 5px 5px;
      overflow-y: auto;
      max-height: 158px !important;
    }

    .accordion-section-content {
      &:first-of-type {
        border-radius: 10px 10px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 10px 10px;
      }

      .accordion-section-content-data {
        @include flex(column, flex-start, flex-start, nowrap);
        padding-top: var(--gap-10-px);

        #faqContent {
          padding: 0 var(--gap-20-px) var(--gap-10-px);
          white-space: pre-wrap;
          word-break: break-word;
        }

        .row {
          @include flex(row, flex-start, flex-start, wrap);
          padding-left: var(--gap-20-px);
          padding-right: var(--gap-20-px);
          margin-bottom: var(--gap-10-px);
          width: 100%;
          @include boxSizing(border-box);

          .labelData {
            margin-right: var(--gap-50-px);

            &.currentBtnContainer {
              @include flex(row, space-between, center, nowrap);
              width: 100%;

              @media (max-width: $mobile-width) {
                @include flex(column, space-between, center, nowrap);
              }
            }
          }

          .eventItem {
            @include flex(row, flex-end, center, nowrap);
            width: 100%;

            @media (max-width: $mobile-width) {
              @include flex(column, center, center, nowrap);
            }

            .eventBtnContainer {
              @include flex(row, flex-end, center, nowrap);
              width: 100%;

              .button {
                max-width: 120px;
                width: 120px;
                height: 30px;
                font-size: var(--global-body-14-font-size);
                margin-bottom: var(--gap-20-px);
              }

              @media (max-width: $mobile-width) {
                @include flex(column, space-between, center, nowrap);
              }

              .button:last-of-type {
                margin-left: var(--gap-10-px);

                @media (max-width: $mobile-width) {
                  margin-left: 0;
                }
              }
            }
          }

          .currentItem {
            @include flex(row, flex-end, center, nowrap);
            width: 100%;
            margin-bottom: var(--gap-10-px);

            @media (max-width: $mobile-width) {
              @include flex(column, center, flex-start, nowrap);
            }

            .currentBtnContainer {
              @include flex(row, space-between, center, nowrap);
              width: 100%;

              @media (max-width: $mobile-width) {
                @include flex(column, flex-end, center, nowrap);
              }
            }

            .currentEscalateWrapper {
              @include flex(row, flex-start, flex-start, nowrap);
              // margin-left: var(--gap-20-px);

              @media (max-width: $mobile-width) {
                margin-left: 0;
                margin-right: var(--gap-10-px);
              }
            }

            .currentEventWrapper {
              @include flex(row, flex-start, flex-start, nowrap);

              @media (max-width: $mobile-width) {
                @include flex(column, flex-start, flex-start, nowrap);
              }

              .button:first-of-type {
                margin-right: var(--gap-10-px);
              }

              @media (max-width: $mobile-width) {
                margin-left: 0;
              }
            }

            .button {
              max-width: 120px;
              width: 120px;
              height: 30px;
              font-size: var(--global-body-14-font-size);
              margin-bottom: var(--gap-10-px);
            }

            @media (max-width: $mobile-width) {
              @include flex(column, space-between, center, nowrap);
            }

            .button:last-of-type {
              //margin-left: var(--gap-10-px);

              @media (max-width: $mobile-width) {
                margin-left: 0;
              }
            }
          }

          .faqItem {
            width: 100%;

            .faqAnswer {
              white-space: pre-wrap;
              word-break: break-word;
              width: 100%;
            }

            .faqButtonContainer {
              @include flex(row, flex-end, center, nowrap);

              .button {
                margin-left: var(--gap-20-px);
                width: 100px;
              }

              @media (max-width: $mobile-width) {
                @include flex(column, space-between, center, nowrap);
              }

              .button:last-of-type {
                @media (max-width: $mobile-width) {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .SectionContentRow {
    @include flex(row, space-between, flex-start, wrap);
    width: 100%;

    .sectionData {
      @include flex(row, flex-start, flex-start, wrap);
      padding: var(--gap-10-px) var(--gap-20-px);

      .label {
        margin-right: var(--gap-10-px);
      }

      @media (max-width: $tablet-width) {
        padding: var(--gap-10-px);
      }

      @media (max-width: $mobile-width) {
        padding: var(--gap-5-px);

        p {
          padding: var(--gap-5-px);
        }

        .label {
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }

  .button {
    margin: var(--gap-10-px) 0 var(--gap-10-px) 0;
    width: fit-content;
    width: -moz-fit-content;
  }
}

.faqButtonContainer {
  @include flex(row, flex-end, center, nowrap);

  .button {
    margin-left: var(--gap-20-px);
    width: 100px;
  }

  @media (max-width: $mobile-width) {
    @include flex(column, space-between, center, nowrap);
  }

  .button:last-of-type {
    @media (max-width: $mobile-width) {
      margin-left: 0;
    }
  }
}