#selectQuestionnaires.sticky {
  position: fixed;
  z-index: 1;
  top: 161px;
  background: white;
  transition: top 0.5s ease-in-out;
  border-bottom: 2px solid #eee;
  border-radius: 0 !important;
  max-width: 1220px;

  @media (max-width: $tablet-width) {
      top: 80px;
  }
}

.dashboardCard.sticky{
  padding: 0px;
}

.selectQuestionnaireContainer {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;
  // -webkit-box-sizing: border-box;
  // -moz-box-sizing: border-box;
  // box-sizing: border-box;
}

.sqButtonContainer {
  @include flex(row, space-between, center, nowrap);

  .button {
    margin-left: var(--gap-20-px);
  }
}

.sqSummaryContainer {
  @include flex(row, space-between, center, nowrap);
  width: -webkit-fill-available;
  margin: var(--gap-10-px);

  p {
    color: var(--header-background-color);
  }
}

.sqSelectionPanel {
  @include flex(row, flex-start, center, nowrap);
  background-color: map-get($colour-palette, open-white);
  height: 60px;
  width: 100%;
  margin-bottom: var(--gap-20-px);
  border-radius: 10px;

  button {
    margin-left: var(--gap-20-px);
    width: fit-content;
  }
}

.sqSelectedContainer {
  @include flex(column, flex-start, center, nowrap);
  width: -webkit-fill-available;
  height: 150px;
  overflow: auto;
  margin-top: var(--gap-30-px);
}

.sqSelectedRow {
  @include flex(column, flex-start, center, nowrap);
  min-height: 40px;
  width: 95%;
  padding: var(--gap-20-px) 0;
  margin-bottom: var(--gap-10-px);
  border: 1px solid var(--header-background-color);
  border-radius: 5px;
}

.sqSelectedHeader {
  @include flex(row, space-between, center, nowrap);
  width: 95%;
}

.sqSelectedLots {
  @include flex(row, flex-start, center, wrap);
  width: 95%;
}

.bqLotModal {
  padding: var(--gap-10-px) 0;
  border-bottom: 1px solid map-get($colour-palette, open-divider);

  &:last-child {
    border-bottom: 0px;
  }
}

#selectQuestionnaires {
  height: auto;
}

.sqSelectedContainer.new-sqSelectedContainer {
  padding: 0px 20px 0px 20px;
  margin-top: 0;
  @include flex(row, flex-start, center, wrap);

  & .sqSelectedRow {
    min-height: 0;
    width: 100%;
    padding: var(--gap-10-px) 0;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid map-get($colour-palette, open-divider);

    & .sqSelectedHeader {
      width: 100%;
    }
  }
}

.sticky {
  .sqSelectedContainer.new-sqSelectedContainer {
    border: 1px solid;
    background-color: #EFF1F3;
  }
}

[data-theme="new-style"] {
  #selectQuestionnaires.sticky {
    position: fixed;
    z-index: 1;
    top: 191px;
    background: white;
    transition: top 0.5s ease-in-out;
    border-bottom: 2px solid #eee;
    border-radius: none;
    max-width: none;
    border-radius: 0;
    margin: auto;
    width: auto;
    left: 0;
    right: 0;

    @media (max-width: $tablet-width) {
        top: 80px;
    }
  }
}