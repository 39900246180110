#cpFormDrawer {
    @include flex(column, flex-start, flex-start, nowrap);
    background: var(--header-background-color);
    color: map-get($colour-palette, open-white);
    border-radius: 10px;
    min-width: 350px;
    max-width: 30%;
    @include boxSizing(border-box);
    padding: var(--gap-40-px) var(--gap-20-px);

    @media (max-width: $tablet-width) {
        min-width: 250px;
        padding: var(--gap-20-px);
        max-width: 100%;
    }

    @media (max-width: $mobile-width) {
        padding: var(--gap-20-px);
        max-width: 100%;
    }

    & h2 {
        font-size: var(--global-body-20-font-size);
        line-height: normal;
    }

    .cpDescription {
        line-height: var(--line-height);
        padding-top: var(--gap-10-px);

        a {
            color: map-get($colour-palette, open-white);
        }
    }

    #cpList {
        @include flex(column, flex-start, flex-start, nowrap);
        margin-top: var(--gap-30-px);

        @media (max-width: $mobile-width) {
            margin-top: var(--gap-20-px);

            &>.cpListItems:nth-child(3) {
                margin-bottom: 0;
            }
        }

        .cpListItems {
            @include flex(row, flex-start, flex-start, nowrap);
            margin-bottom: var(--gap-20-px);

            .cpInnerIcons {
                @include flex(column, center, flex-start, nowrap);
                width: 25px;
            }

            .cpListInnerText {
                margin-left: var(--gap-20-px);
            }

            p {
                line-height: var(--line-height);
            }

            img {
                border-radius: 1px;
                border: 2px solid map-get($colour-palette, open-green);
                width: 25px;

            }

            a {
                color: map-get($colour-palette, open-white);
                margin-right: var(--gap-5-px);
            }
        }
    }
}