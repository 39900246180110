.sortDropdown {
    width: 100%;
    max-width: 250px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid var(--header-background-color);
    padding: 0 var(--gap-50-px) 0 var(--gap-10-px);
    background-image: url('../../styles/icons/grey/dropdown.svg');
    background-repeat: no-repeat;
    background-position: right var(--gap-10-px) center;
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    @media (max-width: $tablet-width) {
        height: 35px;
    }

    @media (max-width: $mobile-width) {
        height: 30px;
    }
}

.bdContentSection.new-bdContentSection {

    & .sortDropdown {
        background-color: map-get($colour-palette, open-white);
        color: map-get($colour-palette, open-text-primary);
        border: var(--input-border);
        font-weight: var(--font-weight);
        border-radius: var(--input-radius);
        background-image: url('../../styles/icons/blue/dropdown.svg');
        background-size: 1rem;
        margin-right: 0 !important;
        min-width: 7.5rem;

    }

    button {
        margin-left: 0 !important;
    }

}