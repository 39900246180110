#viewPublishNoticesSection {
    @include boxSizing(border-box);

    flex: 1;
    width: 100%;

    #viewPublishNoticesForm {
        padding: var(--gap-40-px);
        background-color: map-get($colour-palette, open-white);
        border-radius: 10px;

        // Want to do this but needs more effort to work on all form types.
        // &.submitted {
        //     input {
        //         &:invalid {
        //             border: 1px solid map-get($colour-palette, open-text-warning);
        //             outline-color: map-get($colour-palette, open-text-warning);
        //         }
        //     }
        // }


        .pageHeading,
        .subHeading {
            color: var(--header-background-color);
        }

        .subHeading {
            margin-bottom: var(--gap-10-px);

            .labelled-textarea {
                width: 33.3%;

                .form-textarea {
                    width: 92%;
                }
            }
        }

        #char-count {
            width: 30.75%;
        }

        #type-select,
        #contractType-select,
        #procedureType-select {
            min-width: 350px;
        }

        .noticeSearch {
            width: 350px;

            .select-search {
                margin-top: var(--gap-10-px);
            }

            .select-search__options {
                max-height: 150px;
            }
        }

        .noticeDatePicker {
            width: 350px;

            .customDateInput {
                margin-top: var(--gap-10-px);
            }
        }

        .vpnDivider {
            width: 100%;
            border: 1px solid map-get($colour-palette, open-divider);
            margin: var(--gap-20-px) 0 var(--gap-30-px);
        }

        .vpnItem {
            padding: var(--gap-15-px) 0;

            @media (max-width: $mobile-width) {
                padding: var(--gap-10-px) 0;
            }

            .vpnLabel {
                @include flex(row, flex-start, center, wrap);

                .sectionValue {
                    margin-left: var(--gap-10-px);
                }
            }

            .labelled-textarea #char-count {
                width: inherit;
            }

            #valueLow-input,
            #contactPhone-input,
            #budget-input,
            #procedureType-select,
            #contractType-select {
                font-size: var(--global-body-14-font-size);
            }

            &Tags {
                @include flex(row, flex-start, flex-start, wrap);
                margin-top: var(--gap-10-px);

                .tag-content {
                    margin: var(--gap-10-px) var(--gap-30-px) 0 0;

                    @media (max-width: $mobile-width) {
                        margin: 0 var(--gap-10-px) 0 0;
                    }
                }
            }

            &Edit {
                @include flex(row, flex-start, flex-start, wrap);
            }

            &ButtonGroup {
                display: flex;
                justify-content: flex-end;
            }

        }

        .vpnItemButtonGroup {
            margin-top: var(--gap-50-px);
        }
    }
}

.notice-header {
    color: #213d43;
    padding-top: var(--gap-20-px);
    margin-bottom: var(--gap-20-px);
    font-family: NotoSans-Medium;
    font-size: var(--global-body-18-font-size);
    line-height: normal;
}

.notice-title {
    width: 100%;
    max-width: 230px;
}

.notice-data-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-top: var(--gap-20-px);
}

#form_02 {

    .labelled-select,
    .labelled-input,
    .labelled-radio-button,
    .noticeSearch,
    .noticeDatePicker,
    .checkbox-title {
        margin-top: var(--gap-20-px);
        // margin-bottom: var(--gap-20-px);
    }

    .selectSearchTitle,
    .noticeSearch {
        width: 100% !important;
    }

    .select-search {
        width: 350px;
    }

    .vpnItem {
        padding: 0 !important;
    }

    .flex-break {
        flex-basis: auto;
        height: auto;
        margin: 0;
    }

}

#form_01,
#form_02,
#form_03 {
    .criteria {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-wrap: nowrap;
    }

    .vpnItemNutsCode {
        margin-top: var(--gap-10-px);
        @include flex(row, flex-start, center, nowrap);
    }

    .form-textarea {
        resize: auto !important;
    }

    #char-count {
        width: auto !important;
    }
}

.customDateInput.timeReceipt {
    margin-bottom: var(--gap-10-px);
    width: 160px;
}