.MuiAccordion-container {
    width: 96%;

    @media (max-width: $mobile-width) {
        width: 90%
    }

    & .MuiAccordionSummary-root:has(:not(a)):hover,
    .MuiButtonBase-root:has(:not(a)):hover {
        cursor: default !important;
    }


    & .MuiAccordionSummary-expandIconWrapper:hover {
        cursor: pointer;
    }

    & .MuiAccordionSummary-expandIconWrapper .material-icons {
        width: 2em;
    }

    .MuiAccordionSummary-content {
        @include flex(row, flex-start, center, wrap, var(--gap-10-px));

        & a {
            @include flex(row, flex-start, center, wrap, var(--gap-10-px));
            width: auto;
            padding-right: var(--gap-40-px);

            & label {
                cursor: pointer;
            }
        }


        & label {
            font-family: var(--font-family-h);
            font-size: var(--global-body-18-font-size);
            font-weight: 600;
            color: var(--header-background-color);

            @media (max-width: $tablet-width) {
                font-size: var(--global-body-16-font-size);
            }

            @media (max-width: $mobile-width) {
                font-size: var(--global-body-14-font-size);
            }
        }

        & .share-icon {
            width: 20px;
        }

        & .Mui-pills {
            margin-left: auto;
            @include flex(row, flex-start, center, wrap, var(--gap-20-px));
        }

        & .title-subtitle div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: var(--gap-10-px) 0;

            & p {
                font-family: var(--font-family-h);
                font-size: var(--global-body-16-font-size);
            }
        }

        & .headerReferenceValue {
            font-size: var(--global-body-18-font-size);
            font-weight: 600;
            color: var(--header-background-color);
        }
    }

    .MuiAccordionSummary-content {
        margin-right: var(--gap-40-px);

        &:has(:not(a)) {
            cursor: default;

            &:hover {
                cursor: default;
            }
        }
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: var(--gap-10-px) 0;

        &:has(:not(a)) {
            margin: 0;
            cursor: default;

            & a {
                padding: var(--gap-20-px) 0;
                padding-right: var(--gap-40-px);
            }
        }



    }

    & .MuiAccordionDetails-root {
        padding: 0 var(--gap-20-px) var(--gap-20-px) var(--gap-20-px);

        & .sbAccordionSectionContentRow {
            @include flex(row, space-between, center, wrap, var(--gap-20-px));

            & .splitter {
                display: none;
            }
        }
    }

    & .MuiPaper-root {
        border: 1px solid map-get($colour-palette, n-card-border);
        box-shadow: none;

        &:has(a):hover {
            background: map-get($colour-palette, n-open-base-blue)
        }
    }

    & .Mui-acc-link {
        text-decoration: none;
        color: map-get($colour-palette, open-text-primary);
        @include flex(column, space-between, center, wrap, var(--gap-10-px));

        &:hover {
            text-decoration: none;
        }
    }

    & .Mui-acc-row {
        width: 100%;
        @include flex(row, space-between, center, wrap, var(--gap-10-px));

        &>div {
            @include flex(row, flex-start, flex-start, nowrap, var(--gap-10-px));
        }

        & p.label {
            font-weight: 600;
        }
    }

    & .Mui-acc-tags {
        @include flex(row, flex-start, center, wrap, var(--gap-10-px));
        width: 100%;
        margin-bottom: var(--gap-10-px);

        & .card-tag {
            background: map-get($colour-palette, n-open-tag-blue);
            padding: 3px 10px;
            font-size: var(--global-body-13-font-size);
            border-radius: 5px;
        }
    }

    & .Mui-acc-grid {
        @include flex(row, space-between, flex-start, wrap, var(--gap-20-px));
        width: 100%;

        & .grid-col {
            @include flex(column, flex-start, flex-start, nowrap, var(--gap-10-px));

            &>div {
                @include flex(row, flex-start, flex-start, nowrap, var(--gap-20-px));

                & .label {
                    font-weight: 600;
                }
            }
        }
    }

    & .Mui-extra {
        background: map-get($colour-palette, n-open-extra-card);
        margin-left: calc(var(--gap-20-px) * -1);
        margin-right: calc(var(--gap-20-px) * -1);
        margin-bottom: calc(var(--gap-20-px) * -1);
        margin-top: var(--gap-20-px);
        padding: var(--gap-15-px);
        border-top: 1px solid map-get($colour-palette, n-card-border);

        & button {
            width: fit-content;
            padding: 0 var(--gap-10-px);
        }

        .Mui-extra-buttons {
            @include flex(row, flex-end, center, nowrap, var(--gap-20-px));
            min-width: 15.625rem;
        }

    }

    &>a {
        text-decoration: none;
        padding: var(--gap-10-px);
        color: map-get($colour-palette, open-text-primary);
        display: block;

        & .link-active {
            font-weight: 700;
        }
    }
}

#detailsSection {

    & .MuiAccordion-container {
        width: 100%;

        & .MuiPaper-root {
            border: none;

            &:hover {
                background: none;
            }
        }


        & .MuiAccordionSummary-content {



            & p {
                color: var(--header-background-color);
                font-weight: 600;
            }
        }
    }
}

.MuiAccordionSummary-expandIconWrapper .material-icons {
    width: auto;
}

.MuiAccordionDetails-root:has(.MuiPaper-root) {
    padding: 0;
}

.MuiPaper-root .MuiPaper-root {
    border-right: none;
    border-left: none;
    border-radius: 0 !important;

    & h3 .MuiButtonBase-root {
        padding-left: var(--gap-40-px);
        border-bottom: 1px solid #d3d3d3;
    }

    & .MuiCollapse-root {
        padding-left: var(--gap-40-px);
        background: #f2f6f9;
    }


}

.MuiAccordion-container:has(.clickable) .MuiPaper-root h3 {
    cursor: pointer;
}