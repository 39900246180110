.searchBar {
    width: 100%;
    max-width: 500px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid var(--header-background-color);
    padding: var(--gap-10-px) var(--gap-10-px) var(--gap-10-px) var(--gap-50-px);
    background-image: url('../../styles/icons/blue/search.svg');
    background-repeat: no-repeat;
    background-position: var(--gap-15-px) center;
    background-size: auto 80%;
    display: inline-block;

    @media (max-width: $tablet-width) {
        height: 35px;
        padding-left: var(--gap-40-px);
        background-position: var(--gap-10-px) center;
    }

    @media (max-width: $mobile-width) {
        height: 30px;
        padding-left: var(--gap-40-px);
        background-position: var(--gap-15-px) center;
    }
}

.bdContentSection.new-bdContentSection .searchBar {
    background-color: map-get($colour-palette, open-white);
    color: map-get($colour-palette, open-text-primary);
    border: var(--input-border);
    font-weight: var(--font-weight);
    border-radius: var(--input-radius);
    max-width: none;
}