.ppContentSection {
    padding: var(--gap-40-px);
    align-items: flex-start;
}

.pp {
    &-container {
        margin: var(--gap-40-px);
    }

    &-title {
        margin-top: var(--gap-30-px);
    }

    &-content {
        margin-top: var(--gap-20-px);
    }

    &-warning {
        margin-top: var(--gap-40-px);
        padding: var(--gap-10-px);
        background-color: map-get($colour-palette, open-warning);
    }

    &-list {
        padding-left: var(--gap-40-px);
        list-style-type: disc;

        &-content {
            margin-top: var(--gap-20-px);

            &-sublist {
                margin-left: 45px;
            }
        }
    }
}

li::marker {
    font-size: var(--global-body-20-font-size);
}