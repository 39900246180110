.timerContainer {
    width: 100%;
    @include flex(row, center, center, nowrap);
    margin-bottom: var(--gap-10-px);

    @media (max-width: $tablet-width) {
        @include flex(row, center, center, wrap);
    }

    .timerTile {
        background-color: map-get($colour-palette, open-white);
        padding: var(--gap-20-px);
        margin: var(--gap-10-px);
        color: var(--header-background-color);
        text-align: center;

        &.redTile {
            color: map-get($colour-palette, open-red);
        }

        &.amberTile {
            color: map-get($colour-palette, open-amber);
        }

        &.content {
            white-space: nowrap;

            @media (max-width: $tablet-width) {
                padding: var(--gap-20-px);
            }
        }
    }
}

#red {
    color: map-get($colour-palette, open-red);
}

#amber {
    color: map-get($colour-palette, open-amber);
}

// .timerHeaderContainer{
//     @include flex(column,center,center,nowrap);
//       .heading{
//         text-decoration: underline;
//         color: var(--header-background-color);
//        }

//     .timerDisplay{
//        display:flex;
//        flex-direction: row;

//        .timerTile{
//         background-color: white;
//         padding: 1%;
//         margin: var(--gap-10-px);
//         color: var(--header-background-color);
//         text-align: center;
//         white-space: nowrap;
//     }
// }
// }