#questionnaireQuestionForm {

    #description-textarea {
        width: 100%;
    }

    #questionFileUploadInput-input {
        border: 1px solid map-get($colour-palette, open-green);
        border-radius: 10px;
        border-style: dashed;
        height: 100px;
    }

    .labelled-radio.radio-button-group.row {
        flex-wrap: wrap;


    }

    #questionWeightageContainer {
        @include flex(row, flex-start, flex-end, nowrap);

        input {
            width: 200px;
        }

        p {
            padding-bottom: var(--gap-10-px);
            margin-left: var(--gap-10-px);
        }
    }

    #responseOptionInput-0-input {
        width: 90%;
    }

    .responseOptionsInputRow {
        @include flex(row, space-between, center, nowrap);
        width: 100%;
        margin: var(--gap-5-px) 0;

        .labelled-input {
            width: 100%;
        }

        input[id^="responseOptionInput-"] {
            width: 100%;
        }

        button {
            width: 30px;
            margin-left: var(--gap-10-px);
            background: none;
            border: none;
        }

        .responseOptionsDeleteBtn {
            pointer-events: none;
        }
    }

    #addAnotherOptionBtn {
        width: auto;
        margin-top: var(--gap-10-px);
    }
}