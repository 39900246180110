.pagination {
    @include flex(row, center, center, wrap);
    width: 100%;
    height: 100%;

    .paginationItem {
        button {
            background: none;
            border: none;
            padding: var(--gap-10-px);
            margin: var(--gap-10-px);
            cursor: pointer;
            color: var(--header-background-color);

            @media (max-width: $tablet-width) {
                padding: var(--gap-5-px);
                margin: var(--gap-5-px);
            }

            @media (max-width: $mobile-width) {
                padding: var(--gap-5-px);
                margin: var(--gap-5-px);
            }
        }

        &.disabled>button {
            color: map-get($colour-palette, open-grey);
            cursor: not-allowed;
        }

        .paginationItemNumber {
            color: map-get($colour-palette, open-grey);

            &.active {
                color: var(--header-background-color);
                border-bottom: 2px solid var(--header-background-color);
                padding-bottom: var(--gap-5-px);

                @media (max-width: $mobile-width) {
                    padding-bottom: 0.15rem;
                }
            }
        }

        .paginationItemEllipsis {
            color: map-get($colour-palette, open-grey);
            cursor: text;
        }
    }
}