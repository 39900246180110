#hpMainContent {
    width: 100%;
    flex: 1;
    margin-top: var(--gap-100-px);

    & #hpWelcomeContainer #welcomeMsgContainer p.welcome-open {
        text-transform: uppercase;
        font-size: var(--global-body-20-font-size);
        margin-bottom: var(--gap-25-px);
    }

    @media (max-width: $tablet-width) {
        margin-top: var(--gap-80-px);
    }

    #hpWelcomeContainer {
        @include flex(row, center, flex-start, wrap);
        width: 100%;
        height: 600px;
        color: map-get($colour-palette, open-white);
        background-color: var(--header-background-color);
        background-image: var(--header-background-image);

        @media (max-width: $tablet-width) {
            height: auto;
        }

        @media (max-width: $mobile-width) {
            height: 150vh;
        }

        #welcomeMsgContainer {
            @include flex(column, center, flex-start, nowrap);
            width: 45%;
            min-width: 150px;
            height: 100%;
            padding: var(--gap-40-px) var(--gap-50-px) var(--gap-40-px) var(--gap-120-px);
            @include boxSizing(border-box);

            p {
                font-family: NotoSans-Light;
                font-size: var(--global-body-28-font-size);
                margin: var(--gap-20-px) 0 var(--gap-50-px) 0;
                line-height: var(--line-height);
                white-space: pre-wrap;

                .bold {
                    font-family: NotoSans-Bold;
                }
            }

            h1 {
                font-family: NotoSans-Regular;
                font-size: var(--global-body-28-font-size);
                margin: var(--gap-20-px) 0 var(--gap-50-px) 0;
                line-height: var(--line-height);
                white-space: pre-wrap;

                & .eprocurement-needs {
                    white-space: nowrap;
                }

                & strong {
                    font-family: NotoSans-Bold;
                    white-space: nowrap;
                }
            }

            @media (max-width: $laptop-width) {
                padding-left: var(--gap-40-px);
            }

            #getStartedBtn {
                width: 240px;
                height: 60px;
                border-radius: 50px;
                border: none;
                font-family: NotoSans-Bold;
                font-size: var(--global-body-28-font-size);
                background: none;
                border: 3px solid #d3b04a;
                color: #fff;
            }

            #getStartedBtn:hover,
            #getStartedBtn:focus-visible {
                border: 3px solid map-get($colour-palette, open-skyblue);
                outline: none;
            }

            @media (max-width: $tablet-width) {
                padding: var(--gap-40-px);
                width: 100%;

                p {
                    font-size: var(--global-body-22-font-size);
                    margin: var(--gap-10-px) 0 var(--gap-30-px) 0;
                }

                #getStartedBtn {
                    @media (max-width: $tablet-width) {
                        width: 200px;
                        height: 50px;
                        font-size: var(--global-body-24-font-size);
                        z-index: 2;
                    }
                }

                #getStartedBtn:hover,
                #getStartedBtn:focus-visible {
                    border: 3px solid map-get($colour-palette, open-skyblue);
                    outline: none;
                }
            }

            @media (max-width: $mobile-width) {
                @include flex(column, center, center, nowrap);
                width: 100%;
                height: 50%;
                padding: var(--gap-10-px) var(--gap-40-px);
                text-align: center;

                p {
                    font-size: var(--global-body-18-font-size);
                    margin: var(--gap-10-px) 0 var(--gap-30-px) 0;
                }

                #getStartedBtn {
                    @media (max-width: $tablet-width) {
                        width: 160px;
                        height: 40px;
                        font-size: var(--global-body-18-font-size);
                        z-index: 2;
                    }
                }

                #getStartedBtn:hover,
                #getStartedBtn:focus-visible {
                    border: 3px solid map-get($colour-palette, open-skyblue);
                    outline: none;
                }
            }
        }

        #welcomeImgContainer {
            @include flex(column, center, center, nowrap);
            width: 55%;
            min-width: 250px;
            height: 100%;
            padding: var(--gap-40-px) var(--gap-120-px) var(--gap-40-px) var(--gap-50-px);
            @include boxSizing(border-box);

            img {
                width: 80%;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }

            @media (max-width: $laptop-width) {
                padding-right: var(--gap-40-px);
            }

            @media (max-width: $tablet-width) {
                padding: var(--gap-20-px);
                display: none;
            }

            @media (max-width: $mobile-width) {
                @include flex(column, center, center, nowrap);
                width: 100%;
                height: 50%;
                padding: 0;
                display: none;

                img {
                    height: 120%;
                    width: auto;
                    margin-top: -90px;
                }
            }
        }
    }

    #hpInfoContainer {
        width: 100%;
        min-height: 820px;
        background-color: map-get($colour-palette, open-white);
        @include boxSizing(border-box);
        @include flex(column, flex-start, center, nowrap);

        #portalUseQuestionText,
        #describeQuestionText {
            color: var(--header-background-color);
            margin: var(--gap-80-px) 0 var(--gap-60-px) 0;
            font-weight: 800;
            font-size: var(--global-body-50-font-size);

            @media (max-width: $tablet-width) {
                margin: var(--gap-40-px) 0 var(--gap-30-px) 0;
            }
        }

        #hpInfoCards {
            margin-top: var(--gap-30-px);
            @include flex(row, space-evenly, space-evenly, wrap);
            width: 100%;

            .card {
                width: 100%;
                height: fit-content;
                height: -moz-fit-content;
                border: none;
                border-radius: 300px;
                margin: var(--gap-20-px);
                background-image: linear-gradient(to left, #c9d8e4 -144%, #fff 50%);

                section {
                    border: none;
                    width: 45%;
                    height: auto;

                    @media (max-width: $tablet-width) {
                        width: 90%;
                        align-self: center;
                    }

                    @media (max-width: $mobile-width) {
                        width: 90%;
                        align-self: center;
                    }
                }

                .card-content {
                    flex: 1;
                    width: 100%;
                    height: 77.5%;
                    display: flex;
                    flex-direction: row-reverse;

                    @media (max-width: $mobile-width) {
                        flex-direction: column;
                    }
                }

                .card-content-image-container img {
                    width: 80%;
                    height: auto;

                    @media (max-width: $tablet-width) {
                        width: 90%;
                        margin-top: 0;
                    }

                    @media (max-width: $mobile-width) {
                        width: 85%;
                        margin-top: 0;
                    }
                }


                @media (max-width: $tablet-width) {
                    width: 90%;
                }

                @media (max-width: $mobile-width) {
                    width: 90%;
                }

                &-content-container {
                    h2 {
                        color: var(--header-background-color);
                        margin-top: var(--gap-40-px);
                        font-size: var(--global-body-28-font-size);
                    }

                    p {
                        text-align: left;
                        width: 100%;
                        margin-top: var(--gap-20-px);
                        line-height: var(--line-height);
                        color: #1f3765;
                    }
                }
            }

            .card:nth-child(odd) {
                background-image: linear-gradient(to right, #c9d8e4 -144%, #fff 50%);

                .card-content {
                    flex-direction: row;

                    @media (max-width: $mobile-width) {
                        flex-direction: column;
                    }
                }
            }

        }

        @media (max-width: $tablet-width) {

            h2,
            p {
                margin: var(--gap-40-px) 0 var(--gap-30-px) 0;
            }
        }

        @media (max-width: $mobile-width) {

            h2,
            p {
                margin: var(--gap-30-px) 0 var(--gap-20-px) 0;
            }
        }
    }
}

#hpMainContent.new-hp-main-content {
    margin-top: 6.9rem;

    @media (max-width: $tablet-width) {
        margin-top: 4.4rem;
    }

    @media (max-width: $mobile-width) {
        margin-top: 5.2rem;
    }

    & .homepageBannerContainer {
        padding: var(--gap-10-px);

        & .button#learnMoreBtn {
            border-radius: 5px;
            font-weight: 600;
        }
    }

    & .hpBannerText1 {
        color: #000;

        & .hpBannerTextBold {
            font-weight: 600;
        }
    }

    & #hpWelcomeContainer {
        height: auto;
        align-items: center;

        @media (max-width: $mobile-width) {
            height: calc(100vh - 230px);
        }

        & #welcomeImgContainer {
            padding-top: 0rem;
            padding-bottom: 0rem;
            padding-right: var(--gap-100-px);
            align-items: flex-end;
            width: 45%;
        }

        & #welcomeMsgContainer {
            height: auto;
            justify-content: center;
            gap: var(--gap-25-px);
            width: 50%;

            @media (max-width: $tablet-width) {
                width: 100%;
            }

            & h1 {
                font-family: var(--font-family-h);
                margin: 0;
                font-size: var(--global-body-38-font-size);
                font-weight: 400;
                line-height: 3.5rem;

                & .eprocurement-needs {
                    white-space: nowrap;
                }

                & strong {
                    font-weight: 600;
                    white-space: nowrap;
                    font-family: var(--font-family-h);
                }
            }

            & .welcome-open {
                margin: 0;
                font-size: var(--global-body-20-font-size);
                font-family: var(--font-family);
            }

            & #getStartedBtn {
                background-color: map-get($colour-palette, n-open-yellow-strong);
                border: none;
                border-radius: 5px;
                font-family: var(--font-family);
                font-weight: 600;
                color: map-get($colour-palette, n-open-blue);
                font-size: var(--global-body-20-font-size);
                width: auto;
                padding-right: var(--gap-30-px);
                padding-left: var(--gap-20-px);

                &::before {
                    content: url("../../styles/icons/blue/chevron-right-ellipse.svg");
                    width: auto;
                    height: auto;
                    margin-top: 5px;
                    margin-right: 10px;
                }

                &:hover {
                    background-color: map-get($colour-palette, n-open-dark-yellow);
                }

            }
        }
    }

    & #hpInfoContainer #hpInfoCards {
        & .card-content-container h2 {
            font-weight: 600;
        }

    }
}

[data-theme="default-style"] {
    #hpMainContent #hpWelcomeContainer #welcomeMsgContainer h1 .eprocurement-needs {
        font-size: var(--global-body-28-font-size);
    }
}