.documentSection {
    width: 100%;
    padding: 0 var(--gap-20-px);
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    margin: var(--gap-15-px) 0;

    @media (max-width: $tablet-width) {
        padding: 0 var(--gap-30-px);
        margin: var(--gap-10-px) 0;
    }

    @media (max-width: $mobile-width) {
        padding: 0 var(--gap-20-px);
    }

    &.view {
        color: var(--header-background-color);
    }
}

.versions-selector {
    display: flex;
    gap: 10px;
    font-size: var(--global-body-14-font-size);
    margin-left: 10px;

    & input {
        min-width: 245px;
        height: 35px;
    }
}

#toolbarSection {
    @include flex(row, flex-end, center, nowrap, var(--gap-20-px));
    margin-top: 0;
    top: 0; // enables the animation to actually happen
    padding: var(--gap-20-px);

    #primaryToolbarBtn {}

    &.view {
        @include flex(column, flex-start, flex-start, wrap);
        height: 170px;
    }

    #disableSection {
        width: 100%;

        #disableToolbarBtn {
            width: 100px;
        }
    }

    #draftToolbarBtn {
        width: 200px;
    }

    #draftedQuestionnaire-statuslabel {}

    @media (max-width: $mobile-width) {
        @include flex(row, center, center, wrap, var(--gap-20-px));

        & .button {
            flex: 1;
        }
    }
}

#toolbarSection.sticky {
    position: fixed;
    top: 100px;
    z-index: 2;
    background: white;
    transition: top 0.5s ease-in-out;
    border-bottom: 2px solid #eee;
    width: calc(100% - 60px);
    max-width: 1220px;

    @media (max-width: $tablet-width) {
        top: 80px;
        width: calc(100% - 40px);
    }

    @media (max-width: $mobile-width) {
        top: 80px;
        width: calc(100% - 20px);
    }
}

#toolbarSectionSQ {
    @include flex(row, space-between, center, nowrap);
    margin-top: 0;
    top: 0; // enables the animation to actually happen

    .buttonsSQ {
        height: 80px;
        width: 60%;
        @include flex(row, flex-end, center, nowrap);

        #saveBtn {
            margin-left: 0;
        }


        #submitBtn {
            margin-left: 0;
        }

        .button {
            margin: var(--gap-10-px) var(--gap-10-px) 0 var(--gap-10-px);

            #cancelAnswerBtn {
                margin-left: var(--gap-20-px);
            }

            #underEvaluationStatus-statuslabel {
                margin-right: 0;
            }

            #viewSubmissionBtn {
                margin-left: var(--gap-20-px);
            }

            @media (max-width: 660px) {
                height: auto;
                width: 100%;
                margin-top: var(--gap-10-px);

                #saveBtn {
                    margin-left: 0;
                }

                #submitBtn {
                    margin-left: 0;
                }

                .button {
                    margin: var(--gap-10-px) var(--gap-10-px) 0 var(--gap-10-px);
                }

                #underEvaluationStatus-statuslabel {
                    margin-right: 0;
                }

                @include flex(row, center, center, wrap);
            }

            @include flex(row, center, center, wrap);
        }
    }

    .bigHeight {
        height: 130px;
        width: 100%;
        display: flex;

        .questionnaireChangesBtns {
            width: 40%;
            height: 100%;
            @include flex(column, space-evenly, flex-start, nowrap);

            #viewSubmissionBtn {
                width: 35%;
                margin-left: 10px;
            }
        }

        .buttonsSQ {
            width: 60%;
            margin-top: 50px;
            height: 100%;
            @include flex(row, flex-end, flex-start, nowrap);

            #saveBtn {
                margin-left: var(--gap-20-px);
            }

            #submitBtn {
                margin-left: var(--gap-20-px);
            }

            #cancelAnswerBtn {
                margin-left: var(--gap-20-px);
            }

            #viewSubmissionBtn {
                margin-left: var(--gap-20-px);
            }

            @media (max-width: 660px) {
                height: auto;
                width: 100%;
                margin-top: var(--gap-10-px);

                #saveBtn {
                    margin-left: 0;
                }

                #submitBtn {
                    margin-left: 0;
                }

                .button {
                    margin: var(--gap-10-px) var(--gap-10-px) 0 var(--gap-10-px);
                }

                #underEvaluationStatus-statuslabel {
                    margin-right: 0;
                }

                @include flex(row, center, center, wrap);
            }
        }
    }

    .smallHeight {
        height: 80px;
        width: 100%;
        display: flex;

        .questionnaireChangesBtns {
            width: 40%;
            @include flex(column, center, flex-start, nowrap);

            #viewSubmissionBtn {
                width: 35%;
            }
        }

        .buttonsSQ {
            width: 60%;
            @include flex(row, flex-end, center, nowrap);

            #saveBtn {
                margin-left: var(--gap-20-px);
            }

            #submitBtn {
                margin-left: var(--gap-20-px);
            }

            #cancelAnswerBtn {
                margin-left: var(--gap-20-px);
            }

            #viewSubmissionBtn {
                margin-left: var(--gap-20-px);
            }

            @media (max-width: 660px) {
                height: auto;
                width: 100%;
                margin-top: var(--gap-10-px);

                #saveBtn {
                    margin-left: 0;
                }

                #submitBtn {
                    margin-left: 0;
                }

                .button {
                    margin: var(--gap-10-px) var(--gap-10-px) 0 var(--gap-10-px);
                }

                #underEvaluationStatus-statuslabel {
                    margin-right: 0;
                }

                @include flex(row, center, center, wrap);
            }
        }
    }

    #evaluationStatusLabel {
        min-width: 172px;

        #underEvaluationStatus-statuslabel {
            min-width: 172px;
        }
    }

    @media (max-width: $tablet-width) {
        height: auto;
        padding: var(--gap-10-px);
    }

    @media (max-width: 660px) {
        height: auto;
        width: 100%;

        @include flex(column, center, center, wrap);
    }
}

.answerInputWrapper {
    @include flex(row, flex-start, flex-start, wrap);
    padding-bottom: 20px;

    #scoring-input-label,
    #comments-input-label {
        color: var(--header-background-color);
    }

    .answerInput {
        @include flex(column, flex-start, flex-start, wrap);
        margin-top: var(--gap-20-px);

        @media print {
            width: 100%;
        }

        .form-label:not(.radio-label):not(.checkBox-label) {
            color: map-get($colour-palette, open-green);
            font-size: var(--global-body-18-font-size);
        }

        .checkBox-label {
            margin: var(--gap-10-px) 0;
        }

        .upFormInputContainer {
            padding-top: var(--gap-20-px);
            width: 100%;
            max-width: 100%;

            .upDocumentButtonContainer {
                width: 230px;
                max-width: 350px;
            }
        }
    }

    .flexGrow {
        flex: 1;
    }

    #description-textarea,
    #comments-textarea {
        width: 600px;

        @media (max-width: $mobile-width) {
            width: auto;
        }

        @media print {
            width: auto;
            height: auto;
            white-space: pre-wrap;
        }
    }
}

#detailsSection {
    #documentDetailsAccordion {
        #dbAccordionSection-0 {
            .accordion-section-header {
                &-data {
                    padding: var(--gap-20-px) 0;
                    pointer-events: none;

                    p {
                        color: var(--header-background-color);
                    }
                }

                &-icon {
                    height: 25px;
                }
            }

            .accordion-section-content {
                &.open {
                    max-height: none !important;
                    overflow: visible;
                }

                .accordion-section-content-data {
                    border-top: 1px solid map-get($colour-palette, open-content-container-border);
                    padding: var(--gap-20-px) 0;

                    #char-count {
                        width: 100%;
                        max-width: 350px;
                    }
                }

                .formElementsDivider {
                    width: 100%;
                    height: 20px;

                    button {
                        width: auto;
                    }
                }
            }
        }
    }
}

.formElementsDivider {
    width: 100%;
    height: 20px;

    button {
        width: auto;
    }
}

#viewHeaderSectionSQ {

    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
    width: 100%;
    padding: var(--gap-20-px);
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    margin: var(--gap-15-px) 0;

    @media (max-width: $tablet-width) {
        padding: var(--gap-20-px);
        margin: var(--gap-10-px) 0;
    }

    @media (max-width: $mobile-width) {
        padding: var(--gap-20-px);
    }

    .viewHeading {
        color: var(--header-background-color);
    }

    #viewHeaderSectionDetailsSQ {

        #backBtn {
            margin-left: var(--gap-20-px);
        }

        .viewHeading {
            color: var(--header-background-color);
        }

        p {
            margin-bottom: var(--gap-10-px);

            &.selection-questionnaire-desc {
                max-width: 850px;
            }
        }

        width: 100%;
        @include flex(row, space-between, flex-start, nowrap);
    }

    .buttonsSQ {
        @include flex(row, space-between, flex-start, nowrap);

        @media (max-width: $mobile-width) {
            @include flex(column, space-between, flex-start, nowrap);
        }
    }

    .button {
        margin-right: 2%;

        @media (max-width: $mobile-width) {
            margin-bottom: 2%;
        }
    }

    .caption {
        margin-bottom: 2%;
    }
}



#viewHeaderSection {
    @include flex(row, flex-start, center, nowrap);
    width: 100%;
    width: 100%;
    padding: var(--gap-20-px);
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    margin: var(--gap-15-px) 0;

    @media (max-width: $tablet-width) {
        padding: 0 var(--gap-30-px);
        margin: var(--gap-10-px) 0;
    }

    @media (max-width: $mobile-width) {
        padding: 0 var(--gap-20-px);
    }



    #viewHeaderSectionDetails {
        .viewHeading {
            color: var(--header-background-color);
        }

        p {
            margin-bottom: var(--gap-10-px);

            &.selection-questionnaire-desc {
                max-width: 850px;
            }
        }

        @include flex(column, flex-start, flex-start, nowrap);
    }

    #editDocumentBtn {
        // align-self: flex-start;
        min-width: fit-content;
    }

    .button {
        margin-left: var(--gap-10-px);
    }

    #changeHistoryBtn {
        min-width: fit-content;
        margin: 0;
    }

    #underEvaluationButton {
        height: 35px;
        font-size: var(--global-body-12-font-size);
        color: map-get($colour-palette, open-amber);
        border: 1px solid map-get($colour-palette, open-amber);
    }

    .flexGrow {
        flex: 1;
    }

    #viewButtonContainer {
        @include flex(row, flex-start, center, nowrap);
    }
}


#toolbarSectionSQ.sticky {
    position: fixed;
    top: 100px;
    z-index: 2;
    background: white;
    transition: top 0.5s ease-in-out;
    border-bottom: 2px solid #eee;
    width: calc(100% - 60px);
    max-width: 1220px;

    @media (max-width: $tablet-width) {
        top: 80px;
        width: calc(100% - 40px);
    }

    @media (max-width: $mobile-width) {
        top: 80px;
        width: calc(100% - 20px);
    }
}

#overviewSection {
    @include flex(row, flex-start, center, nowrap);
    // height: 80px;
    padding: var(--gap-15-px);

    #evaluationOverviewSectionWrapper {
        @include flex(row, flex-start, center, wrap);
        width: 100%;

        .overviewLabelValuePair,
        .overviewLabelScoringPair {
            @include flex(row, flex-start, center, wrap);

            .label {
                margin-right: var(--gap-10-px);
            }
        }
    }

    #overviewSectionWrapper,
    #answerOverviewSectionWrapper {
        @include flex(row, flex-start, center, wrap, var(--gap-20-px));
        width: 100%;

        .overviewButtonWrapper {
            @include flex(row, flex-start, center, wrap, var(--gap-20-px));
        }

        .import-stages-btn {
            width: auto;
        }

        .overviewLabelValuePair {
            @include flex(row, flex-start, center, wrap);

            &:nth-child(odd) {
                margin-right: var(--gap-20-px);
            }

            #valid {
                color: map-get($colour-palette, open-text-success);
            }

            #invalid {
                color: map-get($colour-palette, open-text-warning);
            }

            .label {
                margin-right: var(--gap-10-px);
            }
        }

        .flexGrow {
            flex: 1;
        }

        #addSectionBtn {
            cursor: pointer;

            img {
                height: 70% !important;
            }
        }
    }
}

#evaluationOverviewSectionWrapper {
    #evaluationStats {
        @include flex(row, flex-start, flex-start, wrap);
    }

    .overviewLabelValuePair {
        // width: 270px;
        margin-right: var(--gap-30-px);
    }

    span {
        color: map-get($colour-palette, open-green);
    }
}

#answerOverviewSectionWrapper {
    #answerStats {
        @include flex(row, flex-start, flex-end, wrap);
        width: 100%;

        #leftSideStat {
            margin-right: var(--gap-20-px);
        }

        .overviewLabelValuePair {
            padding: var(--gap-10-px);
            margin: 0;
        }
    }

    span {

        &.red {
            color: map-get($colour-palette, open-red);
        }
    }
}

#contentSections {
    background-color: transparent;
    padding: 0;

    .contentSectionRow {
        @include flex(row, flex-start, center, nowrap);
        width: 100%;
        margin-bottom: 30px;

        .contentSection {
            background-color: map-get($colour-palette, open-white);
            flex: 1;
            padding: var(--gap-20-px);
            // margin: var(--gap-20-px) 0;
            @include boxSizing(border-box);
            border-radius: 10px;

            &:first-of-type {
                margin-top: 0;
            }

            .contentSectionHeader {
                border-bottom: 1px solid map-get($colour-palette, open-divider);
                padding-bottom: var(--gap-30-px);
                @include flex(row-reverse, space-between, flex-start, nowrap, var(--gap-20-px));

                #valid,
                .title-large {
                    color: map-get($colour-palette, open-green);
                    float: right;
                    font-weight: bold;
                }

                .headerToolbar {
                    @include flex(column, flex-end, flex-end, nowrap, var(--gap-20-px));
                    min-width: 30%;

                    & .headerToolbar-row {
                        @include flex(row, flex-end, center, nowrap, var(--gap-20-px));

                        & .button {
                            width: fit-content;
                        }
                    }
                }


                .headerTextRowMain {
                    @include flex(row, space-between, flex-start, nowrap);

                    .awTextRow {
                        @include flex(row, space-between, flex-end, nowrap);

                        label {
                            color: map-get($colour-palette, open-green);
                        }
                    }

                    .headerTextRowMainInner {
                        width: 100%
                    }
                }

                // .awTextRow {
                //     @include flex(row, space-between, flex-end, nowrap);
                //     margin: var(--gap-10-px) 0;

                //     p,
                //     label {
                //         color: map-get($colour-palette, open-green);
                //     }
                // }

                .headerTextRow {
                    @include flex(row, space-between, center, nowrap);
                    margin: var(--gap-10-px) 0;

                    .showSectionWeightage {
                        @include flex(row, flex-start, center, nowrap);
                    }

                    .red {
                        color: map-get($colour-palette, open-red);
                    }

                    .label {
                        margin-right: var(--gap-10-px);

                        &.heading {
                            color: var(--header-background-color);
                        }
                    }

                    .value {
                        word-break: break-word;

                        &.heading {
                            color: var(--header-background-color);
                        }
                    }

                    .headerTextRowWarpper {
                        @include flex(row, flex-start, center, nowrap);
                    }
                }

                & .evaluators-dropdown {
                    display: flex;
                    margin-right: auto;

                    &>div {
                        display: flex;
                        align-items: center;
                        gap: var(--gap-10-px);
                    }
                }

            }

            .contentSectionMain {
                @include flex(column, flex-start, flex-start, wrap);
                width: 100%;
                min-height: 250px;
                padding-top: var(--gap-20-px);

                .setionItemMainToolbar {
                    @include flex(row, flex-end, center, nowrap);
                    width: 100%;
                    margin-bottom: var(--gap-10-px);

                    .setionItemMainToolbarValuePair {
                        @include flex(row, flex-start, center, nowrap);

                        .label {
                            margin-right: var(--gap-10-px);
                        }
                    }

                    button {
                        width: auto;
                        cursor: pointer;
                    }
                }

                .sectionItemMainContent {
                    @include flex(column, flex-start, flex-start, wrap);
                    flex: 1;
                    width: 100%;
                    height: 100%;

                    .saveSectionBtn {
                        align-self: flex-end;
                    }

                    .addQuestionEnd {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        & .button {
                            margin-top: 10px;
                        }
                    }

                    .sectionItemMainContentRow {
                        @include flex(row, space-between, center, wrap, var(--gap-20-px));
                        width: 100%;
                        padding: var(--gap-15-px) 0;
                        border-bottom: 1px solid map-get($colour-palette, open-divider);

                        &.answerContent:last-of-type {
                            border-bottom: none;
                        }

                        &:first-of-type {
                            padding-top: var(--gap-10-px);
                        }

                        .sectionItemReorderBtnsCol {
                            height: 100%;


                            @media (max-width: $tablet-width) {
                                height: auto;
                            }

                            button {
                                width: 25px;
                                height: 25px;

                                img {
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                        }

                        .sectionItemTextCol {
                            flex: 1;
                            // min-width: 300px;
                            height: 100%;
                            position: relative;
                            word-break: break-word;

                            @media (max-width: $tablet-width) {
                                height: auto;
                            }

                            .sectionItemHeading {
                                color: map-get($colour-palette, open-green);
                                width: 75%;
                                display: flex;

                                .question-updated-info {
                                    display: flex;
                                    margin-left: 15px;
                                    align-items: center;
                                    flex: none;
                                    place-items: flex-start;
                                }

                                .info-tooltip {
                                    @include tooltip($data-content: 'There has been an update - Please review and update accordingly',
                                        $width: 220px,
                                        $padding: 5px 12px,
                                        $offset-y: -18px,
                                        $offset-x: 158px,
                                        $arrow-transform: translateY(68%) rotate(90deg),
                                    );
                                    margin-left: 5px;
                                    width: 20px;
                                    display: flex;
                                    top: 3px;

                                    img {
                                        width: 20px;
                                    }
                                }
                            }

                            .sectionItemDescription {
                                margin: var(--gap-5-px) 0 var(--gap-10-px) 0;
                            }

                            .question-description {
                                margin-bottom: var(--gap-20-px);
                            }

                            .answerSectionWeightage {
                                position: absolute;
                                right: 0;
                                top: 0;
                            }

                            .viewEvalContainer {
                                @include flex(row, space-between, flex-start, wrap);
                                width: 100%;

                                #viewEvalTitle {
                                    color: var(--header-background-color);
                                }

                                .weightedScore {
                                    @include flex(row, flex-start, flex-start, wrap);
                                }

                                p {
                                    margin-bottom: var(--gap-10-px);
                                }

                                .evalComments {
                                    @include flex(row, flex-start, flex-start, wrap);
                                }

                                .evalComment {
                                    margin-left: var(--gap-10-px);
                                }

                            }

                            .awLabelContainer {
                                @include flex(row, flex-start, baseline, wrap);
                                min-width: 250px;
                                margin: var(--gap-10-px) 0;

                                p {
                                    color: map-get($colour-palette, open-green);
                                }

                                label {
                                    color: map-get($colour-palette, open-green);
                                }
                            }

                            .sectionItemTextRow {
                                @include flex(row, flex-start, flex-start, wrap);

                                .labelValueContainer {
                                    @include flex(row, flex-start, baseline, wrap);
                                    min-width: 250px;
                                    margin: var(--gap-5-px) 0;

                                    &:first-child,
                                    &:nth-child(even) {
                                        margin-right: var(--gap-40-px);
                                    }

                                    .label {
                                        margin-right: var(--gap-10-px);
                                    }

                                    .value {
                                        word-break: break-word;
                                    }
                                }
                            }
                        }

                        .sectionItemBtnCol {
                            @include flex(row, flex-start, center, wrap, var(--gap-20-px));
                            height: 100%;
                            margin-left: auto;

                            & .button {
                                min-width: none;
                                width: fit-content;
                            }

                            @media (max-width: $tablet-width) {
                                height: auto;
                            }


                        }
                    }
                }
            }
        }

        .sectionOrderBtnsContainer {
            @include flex(column, center, center, nowrap);
            min-width: 50px;

            @media (max-width: $mobile-width) {
                min-width: auto;
            }
        }

        .orderBtn {
            @include flex(column, center, center, nowrap);
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background-color: var(--header-background-color);
            color: map-get($colour-palette, open-white);
            margin: var(--gap-5-px) 0;
            cursor: pointer;
            border: none;

            img {
                width: 20px;
                pointer-events: none;
            }

            &.disabled {
                background-color: map-get($colour-palette, open-grey);
            }
        }
    }
}

#roleAssignment {
    #assigned {
        margin-top: var(--gap-10-px);
    }
}

#questionnaireDetailsForm,
#roleAssignment {
    #assigned {
        width: 30%;
    }
}

#expand-collipse-button {
    cursor: pointer;
}

.individual-evaluators-scoring-table {
    min-width: 100%;

    tr:nth-child(even) {
        background-color: #F2F5FA;

        th {
            background-color: #F2F5FA;
        }
    }

    .table-header-row {
        th {
            background-color: #2c4874;
            color: #fefefe;
            padding: var(--gap-15-px);
            text-align: start;
        }

        th:nth-child(1) {
            width: 25%;
        }

        th:nth-child(2) {
            width: 17%;
        }

        th:nth-child(3) {
            width: 10%;
        }
    }

    .table-body-row {

        td:nth-child(2),
        td:nth-child(3) {
            text-transform: capitalize;
        }
    }

    .table-body-row-cell {
        text-align: start;
        padding: var(--gap-15-px);
    }

    .table {
        min-width: 100%;
        border: 1px solid #F2F5FA;
        border-radius: 10px;

        td,
        th {
            min-width: 0;
            width: 0;
            border: none;
            font-size: var(--global-body-16-font-size);
        }
    }
}

.individual-evaluators-scoring-table-button {
    background: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: end;
    font-size: var(--global-body-14-font-size);
    margin-bottom: 10px;
}

p.empty-content {
    font-size: var(--global-body-16-font-size);
    padding: var(--gap-20-px);
    background-color: #fefefe;
    border-radius: 10px;
    font-family: var(--font-family);
}

.view-attachments-popup {
    margin: var(--gap-20-px) 0rem var(--gap-20-px) 0rem;

    .uploadContainer {
        width: auto;
        border-top: 1px solid map-get($colour-palette, open-divider);
        border-radius: 0;
        padding-top: var(--gap-10-px);

        & .formLabel .title {
            font-weight: bold;
            color: var(--header-background-color);
            font-size: var(--global-body-18-font-size);
        }

        & .flex-break {
            display: none;
        }
    }

    & .additional-note {
        font-size: var(--global-body-12-font-size);
        margin: var(--gap-15-px) 0 var(--gap-10-px) 0;
        color: var(--header-background-color);
        font-style: oblique;
    }
}

.multi-column-form {
    @include flex(row, flex-start, flex-start, nowrap, var(--gap-40-px));

    @media (max-width: $tablet-width) {
        flex-wrap: wrap;
    }

    & .column-form {
        @include flex(column, flex-start, flex-start, nowrap, var(--gap-20-px));
        flex: 1;
        max-width: 50%;

        @media (max-width: $tablet-width) {
            flex: auto;
        }

        & .labelled-item {
            @include flex(column, flex-start, flex-start, nowrap, var(--gap-20-px));
            width: 100%;

            & .form-label {
                margin: 0;
            }

            & input,
            select,
            textarea,
            #route-select {
                width: 100%;
                max-width: 100%;
            }

            & textarea {
                height: 210px;
            }

            &#roleAssignment #assigned {
                width: 100%;
                margin-top: 0;
            }

        }

        & .labelled-textarea #char-count {
            margin-top: calc(var(--gap-10-px) * -1);
        }
    }
}

.mainContent.new-mainContent {

    & #detailsSection {
        &.documentSection {
            padding: 0;
            margin: 0;
        }

        & .material-icons {
            width: 2rem;
        }
    }

    & .overviewLabelValuePair {
        & .value {
            @include flex(column, center, center, nowrap, );
            background: var(--header-background-color);
            color: #fff;
            padding: 3px;
            border-radius: 50%;
            height: 20px;
            width: 20px;

            & span {
                color: #fff !important;
                font-size: var(--global-body-12-font-size);
            }
        }

        & .shared_value {
            width: fit-content;
            border-radius: 5px;
        }
    }

    & #overviewSection {
        background-color: map-get($colour-palette, open-base-blue-20);

        #addStageBtn {
            width: fit-content;
        }
    }

    & #contentSections .contentSectionRow {
        background-color: map-get($colour-palette, open-white);
        border-radius: 10px;
        border: 1px solid map-get($colour-palette, n-card-border);
        align-items: stretch;

        & .contentSection {
            padding: 0;
            display: block;

            & .contentSectionHeader {
                background-color: map-get($colour-palette, open-base-blue-20);
                width: calc(100% - var(--gap-40-px));
                padding: var(--gap-20-px);
                @include flex(row-reverse, space-between, flex-start, wrap, var(--gap-20-px));

                & .button {
                    width: fit-content;
                    margin: 0;
                }

                & .header-text {
                    width: 100%;

                }
            }

            & .contentSectionMain {
                padding: var(--gap-20-px);
                width: calc(100% - var(--gap-40-px));
            }
        }

        & .sectionOrderBtnsContainer {
            border-right: 2px solid var(--bg-color);
        }
    }

    .import-stages-btn {
        border: 1px solid var(--header-background-color)
    }

}