.notificationEventRow {
    @include flex(row, flex-start, center, nowrap);
    width: 100%;
    padding: var(--gap-10-px) var(--gap-10-px) 0 var(--gap-10-px);
    margin: var(--gap-10-px) 0 0 0;
    @include boxSizing(border-box);

    .notificationEventRowContentCol {
        .notificationEventHeaderRow {
            @include flex(row, space-between, center, nowrap);
            width: 100%;

            .notificationTimestamp {
                color: map-get($colour-palette, open-grey);
                text-align: right;
            }
        }

        .notificationEventContentRow {
            p {
                line-height: var(--line-height);
            }
        }
    }

    .notificationEventRowStatusCol {
        @include flex(column, center, flex-end, nowrap);
        width: 20px;
        margin-left: var(--gap-5-px);

        .unreadIcon {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--header-background-color);
        }
    }
}



.notification-space {
    margin-left: 3px;

}

.notification-time {
    margin-left: 3px;
    font-size: var(--global-body-10-font-size);
}