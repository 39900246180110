.leftContainer {
  &.small {
    flex: 1 1 0;
  }
}

.dashboardCard {
  @include flex(column, flex-start, flex-start, wrap);
  background-color: map-get($colour-palette, open-white);
  border: 1px solid map-get($colour-palette, open-content-container-border);
  border-radius: 10px;
  padding: var(--gap-20-px);
  margin-bottom: var(--gap-20-px);
  @include boxSizing(border-box);
  min-width: 300px;

  &.small {
    flex: 1 1 0;
    height: 520px;

    @media (max-width: $tablet-width) {
      flex: auto;
      width: 100%;

    }

  }

  &.medium {
    flex: 2 1 0;
    height: 520px;


    @media (max-width: $tablet-width) {
      flex: auto;
      width: 100%;

    }


  }

  &.large {
    width: 100%;
    height: auto;
  }

  .dashboardCardHeader {
    @include flex(row, space-between, center, nowrap, var(--gap-20-px));
    width: 100%;
    min-height: 40px;
    padding-bottom: var(--gap-10-px);
    margin-bottom: var(--gap-20-px);
    border-bottom: 1px solid map-get($colour-palette, open-divider-grey);

    .guidanceText {
      margin-top: var(--gap-5-px);
      color: map-get($colour-palette, open-text-primary);
    }

    .dashboardHeaderBtn {
      @include flex(row, flex-end, center, nowrap);

      button {
        width: auto;
      }
    }
  }

  .dashboardCardHeader.new-dashboardCardHeader {
    border-bottom: none;
    padding-bottom: 0;

    & .guidanceText {
      margin-top: var(--gap-10-px);
      @include flex(row, flex-start, center, nowrap, var(--gap-10-px));

      &::before {
        content: url(../../styles/icons/blue/new-info.svg);
        transform: scale(0.7);
        display: inline-block;
      }
    }
  }

  .dashboardCardMain {
    width: 100%;
    flex: 1;
    overflow: auto;

    .dashboardCardMainRow,
    .skeletonRow {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      height: 93%;
      gap: var(--gap-20-px);
      column-gap: var(--gap-20-px);

      @media (max-width: $mobile-width) {
        flex-wrap: wrap;
        grid: none;

      }
    }

    .skeletonRow {
      align-content: flex-end;

      & span {
        -webkit-transform: scale(1, 1);
      }

    }
  }



  .dashboardCardFooter {
    @include flex(row, center, flex-end, nowrap);
    width: 100%;
    min-height: 45px;
  }

  .dashboardCardFooter.new-dashboardCardFooter {
    .button {
      font-weight: 600;
    }
  }
}



.opportunities-container .dashboardCardMain .dashboardCardMainRow {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 93%;
  gap: var(--gap-20-px);
  column-gap: var(--gap-10-px);

  @media (max-width: $tablet-width) {
    grid-template-columns: 1fr 1fr 1fr;

  }

  @media (max-width: $mobile-width) {
    flex-wrap: wrap;
    grid: none;

  }
}