#cPPSection {
  @include flex(column, flex-start, stretch, nowrap);
  width: 100%;
  flex: 1;
  background-color: map-get($colour-palette, open-white);
  border-radius: 10px;
  border: 1px solid map-get($colour-palette, open-content-container-border);
  padding: var(--gap-40-px);
  @include boxSizing(border-box);
  overflow-y: auto;
}