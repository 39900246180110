.form {

    input,
    select,
    textarea {
        @include boxSizing(border-box);
        font-family: var(--font-family)
    }

    .sectionHeader {
        font-weight: bold;
        color: var(--header-background-color);
    }
}

#submit-input {
    width: 150px;
    height: 40px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--header-background-color);
    color: map-get($colour-palette, open-white);
    cursor: pointer;
    border-radius: 5px;

    @media (max-width: $mobile-width) {
        width: 120px;
    }
}

.flex-break {
    flex-basis: 100%;
    height: 10px;
    margin: var(--gap-15-px) 0;
}