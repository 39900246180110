.sqNoAnswerContainer {
  @include flex(column, center, center, wrap);
  // border-radius: 10px;
  width: 100%;
  height: 100%;
  // background-color: map-get($colour-palette, open-white);
  // min-height: 200px;

  .sqNoAnswerHeader {
    @include flex(row, flex-end, center, wrap);
    width: 100%;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    margin-bottom: var(--gap-10-px);

    button {
      margin: var(--gap-15-px) var(--gap-10-px)m;
    }
  }

  .sqNoAnswerBody {
    @include flex(row, center, center, wrap);
    width: 100%;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    min-height: 100px;
  }
}