.mscDetailsContainer {
  @include flex(row, flex-start, center, wrap);

  @media (max-width: $mobile-width) {
    @include flex(row, center, center, wrap);
  }

  width: 100%;

  #supplierCard {
    .dashboardCardMain {
      overflow: unset;
    }
  }

  #viewSupplier {
    width: 'fit-content';
  }

  #supplierCard {
    // min-width: 380px;
    // max-width: 393px;

    margin-right: var(--gap-20-px);

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $mobile-width) {
      margin-right: 0;
    }

    .dashboardCardHeader {

      // This is here so the dashboard card header heights match.
      #manageSuppliersSearch {
        margin-right: var(--gap-20-px);
        width: 550px;

        @media (max-width: $tablet-width) {
          margin-right: var(--gap-10-px);
          width: 350px;
        }

        @media (max-width: $mobile-width) {
          margin-right: 0rem;
          width: 250px;
        }
      }

      min-height: 47.6px;
    }
  }
}

.mscLotContainer {
  @include flex(column, center, center, nowrap);
  width: 100%;
  border-bottom: 1px solid map-get($colour-palette, open-divider);
  margin-bottom: var(--gap-10-px);

  .lotTitleContainer {
    color: var(--header-background-color);
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
    margin: var(--gap-20-px) 0rem var(--gap-20-px) 0rem;
    font-weight: 600;
    font-size: var(--global-body-18-font-size);
  }

  .lotName {
    font-size: var(--global-body-18-font-size);
  }

  &:last-child {
    border-bottom: none;
  }
}

.mscSupplierRow {
  @include flex(row, space-between, flex-start, nowrap);
  border: 1px solid var(--header-background-color);
  width: 98%;
  margin-bottom: var(--gap-20-px);
  border-radius: var(--gap-10-px);
  padding: var(--gap-10-px);

  &.red {
    border: 1px solid red;
  }

  &.green {
    border: 1px solid green;
  }

  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);

  @media (max-width: $tablet-width) {
    width: auto;
  }

  .mscSupplierDetails {
    @include flex(row, space-between, flex-start, nowrap);
    width: 100%;
    padding: 0rem var(--gap-10-px);

    .companyDetails {
      @include flex(row, flex-start, flex-start, nowrap);

      .comapanyTitle {
        font-size: var(--global-body-16-font-size);
        line-height: 1.9;
        font-weight: 600;
        color: black;
      }
    }

  }
}

.mscButtonContainer {
  @include flex(row, flex-end, center, nowrap);
  width: 100%;

  button {
    margin-left: var(--gap-10-px);
    font-weight: bold;
  }
}