.bottom-navigation {
    @include flex(row, space-evenly, center, nowrap);
    background-color: map-get($colour-palette, open-white);
    width: 100%;
    height: 120px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    border-top: 1px solid map-get($colour-palette, open-bottom-navigation-border);

    &.hidden {
        transform: translateY(100%);
    }

    &.visible {
        transform: translateY(0);
    }

    @media (min-width: $tablet-width) {
        display: none;
    }

    @media (max-width: $mobile-width) {
        height: 80px;
    }

    #notifications-bottom-nav-item {
        .bottom-navigation-item-icon {
            margin-bottom: var(--gap-5-px);
        }
    }
}

#notificationsModal {
    .modal-content {
        height: 60%;
    }

    #notificationsContainer {
        border: none;
        padding-top: 0;
    }
}

.myAccountLink {
    @include flex(row, space-between, center, nowrap);
    height: 60px;
    border-bottom: 1px solid map-get($colour-palette, open-divider);
    cursor: pointer;
    text-decoration: none;
    color: map-get($colour-palette, open-text-grey);
}