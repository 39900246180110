#cpMainContainer {
    @include flex(row, flex-start, initial, nowrap);
    border: 1px solid map-get($colour-palette, open-content-container-border);
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;

    @media (max-width: $mobile-width) {
        @include flex(column, initial, initial, nowrap);
    }

    #cpFormContainer {
        padding: var(--gap-30-px) var(--gap-30-px) var(--gap-20-px);
        @include boxSizing(border-box);
        background: map-get($colour-palette, open-white);
        border-radius: 0 10px 10px 0;

        & h2 {
            color: var(--header-background-color);
            font-size: var(--global-body-20-font-size);
        }

        @media (max-width: $tablet-width) {
            width: 100%;
        }

        @media (max-width: $mobile-width) {
            border-radius: 0 0 10px 10px;
        }

        p {
            color: var(--header-background-color);
        }

        @media (max-width: $tablet-width) {
            padding: var(--gap-20-px) var(--gap-30-px);
        }

        @media (max-width: $mobile-width) {
            padding: var(--gap-20-px) var(--gap-10-px);
        }

        #cpForm {
            @include flex(row, flex-start, flex-end, wrap);
            margin-top: var(--gap-20-px);
            gap: var(--gap-30-px);

            & .labelled-input {
                width: 100%;

                & .form-input {
                    width: 100%
                }
            }

            .formInputContainer {
                @include flex(column, space-between, flex-start, nowrap);
                width: calc(50% - 1.5rem);

                @media (max-width: $tablet-width) {
                    width: 100%;

                    .labelled-input,
                    .labelled-textarea {
                        width: 100%;
                    }
                }

                &>#char-count {
                    align-self: flex-end;
                }

                &.largeBox {
                    width: 100%;


                    &>.labelled-textarea:has(#enquiry-textarea) {
                        width: 100%;
                    }

                    & #enquiry-textarea {
                        width: 100%;
                    }
                }

            }

            .formButtonContainer {
                @include flex(column, flex-start, flex-end, nowrap);
                width: 100%;
            }
        }
    }
}

#cpMainContainer.new-contact-form {
    border: none;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    & #cpFormDrawer {
        padding: var(--gap-30-px) var(--gap-20-px);
        border-radius: 10px 0px 0 10px;

        @media (max-width: $mobile-width) {

            max-width: 100%;
            border-radius: 10px;

        }

        & h2 {
            font-size: var(--global-body-24-font-size);
            margin-bottom: var(--gap-20-px);
        }

        & .cpDescription.caption {
            font-size: var(--global-body-14-font-size);
        }

        & .links,
        & .body {
            font-size: var(--global-body-14-font-size);
        }

        #cpList .cpListItems img {
            border: 2px solid map-get($colour-palette, n-open-blue);
        }

    }

    & #cpFormContainer {
        & h2 {
            font-size: var(--global-body-24-font-size);
            margin-bottom: var(--gap-30-px);
        }

        & .formInputContainer {
            width: calc(50% - .9375rem);
        }
    }


}