#selectSteeringGroup {
    flex: 1;
    width: 100%;
    @include boxSizing(border-box);
}

#selectSteeringGroupContainer {
    padding: var(--gap-30-px);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    min-height: 650px;
    height: 100%;

    .ssgField {
        padding: var(--gap-20-px) var(--gap-10-px);
        text-align: left;
        font-weight: var(--input-label-font-weight);
        color: var(--input-label-color);

        .labelled-checkBox .form-checkBox-container input[type="checkbox"]+.form-label:before {
            position: relative;
        }

        .labelled-checkBox .form-checkBox-container input[type="checkbox"]:checked+.form-label:after {
            left: auto;
            right: var(--gap-15-px);
        }
    }

    .labelled-checkBox .form-checkBox-container input[type="checkbox"]+.form-label:before {
        position: relative;
    }

    .labelled-checkBox .form-checkBox-container input[type="checkbox"]:checked+.form-label:after {
        left: auto;
        right: var(--gap-15-px);
    }

    .table-container {
        height: auto;
        margin-bottom: var(--gap-10-px);
    }

    .labelled-checkBox .form-checkBox-container input[type="checkbox"]+.form-label {
        // display: block;
        // position: relative;
        // padding: 0 0 0 var(--gap-40-px);
        z-index: 0;
    }

    tfoot tr td {
        position: sticky;
        bottom: 0;
        z-index: 0;
        width: 150px;
        border-bottom: none;
        background: map-get($colour-palette, open-white);
    }

    tfoot tr th {
        position: sticky;
        bottom: 0;
        z-index: 2;
        background: map-get($colour-palette, open-white);
        border: none;
    }

    /**
     * react-select-search styles
     */
    .select-search {
        position: relative;
        box-sizing: border-box;
        margin: 0 60px;
    }

    .select-search *,
    .select-search *::after,
    .select-search *::before {
        box-sizing: inherit;
    }

    /**
     * Value wrapper
     */
    .select-search__value {
        position: relative;
    }

    .select-search__value::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
    }

    /**
     * Input
     */
    .select-search__input {
        min-width: 100%;
        height: 40px;
        border-radius: 5px;
        border: 1px solid var(--header-background-color);
        padding: 0 var(--gap-50-px) 0 var(--gap-10-px);
        background-image: url("../../styles/icons/grey/dropdown.svg");
        background-repeat: no-repeat;
        background-position: right var(--gap-10-px) center;
        display: inline-block;
    }

    .select-search__input:not([readonly]):focus {
        cursor: initial;
    }

    /**
     * Options wrapper
     */
    .select-search__select {
        background: map-get($colour-palette, open-white);
        box-shadow: 0 0.0625rem 0.125rem map-get($colour-palette, open-screen-overlay);
        border: solid 1px map-get($colour-palette, open-grey);
        font-family: var(--font-family);
    }

    /**
     * Options
     */
    .select-search__options {
        list-style: none;
        color: map-get($colour-palette, open-text-primary);
        opacity: 1;
    }

    /**
     * Option row
     */
    .select-search__row:not(:first-child) {
        border-top: 1px solid map-get($colour-palette, open-white);
        color: map-get($colour-palette, open-text-primary);
    }

    /**
     * Option
     */
    .select-search__option,
    .select-search__not-found {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 var(--gap-15-px);
        border: none;
        outline: map-get($colour-palette, open-screen-overlay);
        font-family: var(--font-family) !important;
        font-size: var(--global-body-14-font-size) !important;
        text-align: left;
        cursor: pointer;
        background: map-get($colour-palette, open-white);
        color: map-get($colour-palette, open-text-primary);
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option.is-selected {
        background: map-get($colour-palette, open-green);
        color: map-get($colour-palette, open-white);
    }

    .select-search__option.is-highlighted,
    .select-search__option:not(.is-selected):hover {
        background: map-get($colour-palette, open-green-light);
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
        background: var(--header-background-color);
        color: map-get($colour-palette, open-white);
    }

    /**
     * Group
     */
    .select-search__group-header {
        font-size: var(--global-body-14-font-size);
        text-transform: uppercase;
        background: #eee;
        padding: 8px 16px;
    }

    /**
     * States
     */
    .select-search.is-disabled {
        opacity: 0.5;
    }

    .select-search.is-loading .select-search__value::after {
        background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
        cursor: pointer;
        font-size: var(--global-body-14-font-size);
        font-family: "NotoSans-Regular";
    }

    /**
     * Modifiers
     */
    .select-search--multiple {
        border-radius: 3px;
        overflow: hidden;
    }

    .select-search--multiple .select-search__input {
        cursor: initial;
    }

    .select-search--multiple .select-search__input {
        border-radius: 3px 3px 0 0;
    }

    .select-search--multiple:not(.select-search--search) .select-search__input {
        cursor: default;
    }

    .select-search:not(.select-search--multiple) .select-search__input:hover {
        border-color: map-get($colour-palette, open-text-success);
    }

    .select-search:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 1;
        top: 44px;
        right: 0;
        left: 0;
        border-radius: 3px;
        overflow: auto;
        max-height: 250px;
    }

    .select-search--multiple .select-search__select {
        position: relative;
        overflow: auto;
        max-height: 260px;
        border-top: 1px solid map-get($colour-palette, open-white);
        border-radius: 0 0 3px 3px;
    }

    .select-search__not-found {
        height: auto;
        padding: var(--gap-15-px);
        text-align: center;
        color: map-get($colour-palette, open-grey);
    }
}

.sgTagContainer {
    @include flex(row, flex-start, center, wrap);
    max-width: 76rem;
    margin: var(--gap-20-px) 0;
}

.sgTagLabelContainer {
    @include flex(row, flex-start, center, wrap);
    width: 100%;
}

.sgTagLabel {
    padding: 20px 10px;
    font-weight: bold;
}

.sgSectionTags {
    @include flex(row, flex-start, center, wrap);
    padding: var(--gap-10-px) var(--gap-5-px);
    width: 100%;
}

.sgSubmitContainer {
    @include flex(row, flex-end, center, wrap);
    max-width: 76rem;
}

#selectSteeringGroup-cancel {
    margin-right: var(--gap-10-px);
}

.new-mainContent #selectSteeringGroupContainer {
    & .table-container .table {
        width: 100%;
        border: 1px solid map-get($colour-palette, open-content-container-border);

        & .table-header-row-cell {
            background: map-get($colour-palette, open-content-container-border);
            font-weight: 500;
            border: none;
            border-radius: 0;
            color: map-get($colour-palette, open-text-primary);

            &:first-of-type {
                width: 25%;
            }
        }

        & td,
        & th {
            padding: var(--gap-20-px)
        }

        & .select-search {
            margin: 0;
        }

        & .ssgField {
            padding: 0;
        }
    }

    & .select-search__input {
        border: var(--select-border);
    }
}