#password-input-label {
    margin-top: var(--gap-30-px);
}

#email,
#password {
    width: 100%;

    input {
        padding: var(--gap-10-px);
    }
}

#forgot-password-btn {
    width: auto;
    color: map-get($colour-palette, open-browser-link-blue);
    background: none;
    margin-top: var(--gap-10-px);
    padding: 0rem;
    @include flex(column, flex-start, flex-start, nowrap);
}

.inval-login {
    margin: 15px 0;
}

.py-10 {
    padding: 10px 0;
}

.redirect-btn-label {
    background: none;
    background: none;
    border: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
    color: map-get($colour-palette, open-browser-link-blue);
    text-decoration: underline;
}

.success-container {
    @include flex(row, flex-start, center, nowrap);
    line-height: var(--line-height);
    width: 100%;
    margin-bottom: var(--gap-20-px);

    &.top-icon {
        @include flex(row, flex-start, flex-start, nowrap);
    }
}

.verification-success-container {
    @include flex(column, flex-start, flex-start, nowrap);
    line-height: var(--line-height);
    width: 100%;
    margin-bottom: var(--gap-20-px);
    align-items: center;

    .title-padding {
        padding-bottom: 10px;
    }

    img {
        display: flex;
        align-self: center;
        margin-top: 20px;
        width: 50%;
    }
}

.success-img {
    max-width: 20px;
    margin-right: var(--gap-10-px);
}

.success-txt {
    line-height: var(--line-height);
    width: 100%;
}

.change-number-btn {
    float: right;
    margin-top: var(--gap-5-px);
    color: map-get($colour-palette, open-browser-link-blue);
    background: none;
}

.password-hint {
    margin-bottom: var(--gap-20-px);
}

#newPassword-input-label {
    margin-top: var(--gap-30-px);
}

#verifyPassword-input-label {
    margin-top: var(--gap-30-px);
}

#forgot-password-form,
#login-form {
    height: 100%;
    @include flex(column, center, center, nowrap);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}

#clickHere {
    &:focus-visible {
        outline: 2.5px solid black;
    }
}

.passwords-match {
    width: 100%;
    font-size: var(--global-body-12-font-size);
    color: map-get($colour-palette, open-browser-link-blue);
    margin-top: var(--gap-5-px);

    &.error {
        color: map-get($colour-palette, open-text-warning);
    }

    &.success {
        color: map-get($colour-palette, open-text-success);
    }
}

#mobileNumberContainer {
    width: 100%;
    @include flex(row, space-between, flex-start, wrap);

    .mobileNumberInputLabel {
        margin-bottom: var(--gap-10-px);
    }

    #callingCode-select {
        width: 30%;
        min-width: 75px;
    }

    #mobileNumber-input {
        width: 65%;
    }
}

#password-length-hint {
    margin-top: var(--gap-10-px);
    white-space: pre-line;
}

#change-password-btn {
    width: auto;
}